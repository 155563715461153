'use strict';

angular.module('core').controller('HeaderController', ['$scope', 'Menus', 'translateService', 'Wmoapi', '$rootScope', '$state', 'SourceSiteService','AppService', 'ngDialog',
    function ($scope, Menus, translateService, Wmoapi, $rootScope, $state, SourceSiteService, AppService, ngDialog) {
        $scope.isCollapsed = false;
        $scope.menu = Menus.getMenu('topbar');

        $scope.getNextFormattedLanguage = getNextFormattedLanguage;
        $scope.logout = logout;
        $scope.goToLogin = goToLogin;
        $scope.setSourceSite = setSourceSite;
        $scope.getSourceSiteName = getSourceSiteName;
        $scope.getSourceSiteUrl = getSourceSiteUrl;
        $scope.isInWeblinkDesktop = AppService.isPramanthaObjectDetected();
        $scope.isInDev = AppService.isInDev();
        $rootScope.globalLangCurrent = translateService.getCurrentLanguage();

        window.addEventListener('offline', function(){
            $scope.networkError = ngDialog.open({
                template: 'networkErrorModal',
                className: 'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false
            });
        });

        window.addEventListener('online', function(){
            $scope.networkError.close();
        });

        $scope.toggleCollapsibleMenu = function () {
            $scope.isCollapsed = !$scope.isCollapsed;
        };

        // Collapsing the menu after navigation
        $scope.$on('$stateChangeSuccess', function () {
            $scope.isCollapsed = false;
        });

        $scope.goToWeblinkDesktopHome = function () {
            window.WD_go_home();
        };

        $scope.changeLanguage = function () {
            var lang = getNextLanguage();
            translateService.setCurrentLanguage(lang);
            $rootScope.globalLangCurrent = lang;
            if ($rootScope.globalUser) {
                Wmoapi.setLanguage(lang);
            }
        };

        function logout() {
            Wmoapi.logout().then(function (success) {
                $rootScope.globalUser = null;
                goToLogin();
            }, function (error) {
                // todo handle logout
            });
        }

        function goToLogin() {
            $state.go('core.login');
        }

        function getNextLanguage() {
            var currentLang = translateService.getCurrentLanguage();
            if( currentLang && typeof currentLang !== 'undefined' ) {
                return currentLang.toLowerCase() === 'en' ? 'fr' : 'en';
            } else {
                return 'en';
            }
        }

        function getNextFormattedLanguage() {
            var nextLang = getNextLanguage();
            return nextLang === 'fr' ? 'Français' : 'English';
        }

        function setSourceSite(srcsite) {
            SourceSiteService.setSourceSite(srcsite);
        }

        function getSourceSiteName() {
            return SourceSiteService.getName();
        }

        function getSourceSiteUrl() {
            return SourceSiteService.getUrl();
        }
    }
]);
