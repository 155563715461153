/**
 * Created by rafikgharsalli on 2019-09-13
 */
'use strict';
angular.module('core').controller('PartialsInstallOptionsController',
    ['$scope', 'Wmoapi','$rootScope','AppService','alertBar', '_', 'DeviceService', 'deviceIdentifier', 'ngDialog',
        function ($scope, Wmoapi, $rootScope, AppService, alertBar, _, DeviceService, deviceIdentifier, ngDialog) {

            var mainDevice = DeviceService.getFlashableDevice();
            var rfidRequired = deviceIdentifier.doesDetectedDeviceUseAccRfid(mainDevice.productPartOptions);

            $scope.initOptions = function () {
                AppService.setAddOn(false);
                $scope.$parent.usedAsAddOn = AppService.usedAsAddOn();
                managePageNavigation();
                getOptions();
                initSelectedProtocol();
            };

            function managePageNavigation () {
                $scope.backSkipStep = 'vehicle.trims';
                $scope.hidePageNaviNextBtn = true;
                //$scope.demo_mode = AppService.isDemoMode();
            }

            function getOptions() {
                var options = $scope.$parent.responseData.classicInstallOptions;
                $scope.hasClassicOptions = !_.isEmpty(options);
                $scope.disableStdBtn = options.isRsStandaloneCompatible;
            }

            function initSelectedProtocol() {
                $scope.$parent.userSelection.selectedProtocol.usedAsAddOn = false;
                $scope.$parent.userSelection.selectedProtocol.isStandalone = false;
                $scope.$parent.userSelection.selectedProtocol.isStandaloneRf = false;
                $scope.$parent.userSelection.selectedProtocol.isStandaloneTelematic = false;
                $scope.$parent.userSelection.selectedProtocol.standaloneId = '';
                $scope.$parent.userSelection.selectedProtocol.id = '';
                $scope.$parent.userSelection.selectedProtocol.name = '';
            }

            $scope.getProtocols =  function () {
                AppService.setAddOn(true);
                $scope.$parent.usedAsAddOn = AppService.usedAsAddOn();
                initSelectedProtocol();
                $scope.$parent.userSelection.selectedProtocol.usedAsAddOn = true;
            };

            $scope.getStdOptions = function () {
                AppService.setAddOn(false);
                $scope.$parent.usedAsAddOn = AppService.usedAsAddOn();
                initSelectedProtocol();
                $scope.$parent.userSelection.selectedProtocol.isStandalone = true;
            };

            $scope.setProtocol =  function(protocolId, protocolName) {
                $rootScope.isGlobalLoading = true;
                var requestId = $scope.$parent.request.id;
                $scope.$parent.userSelection.selectedProtocol.id =  protocolId;
                $scope.$parent.userSelection.selectedProtocol.name =  protocolName;

                Wmoapi
                    .setProtocol(requestId, protocolId)
                    .success(function () {
                        getFirmwareFamilyWithProtocol();
                    })
                    .error(function (error) {
                        console.error('error in setProtocol:', error);
                        alertBar.show('error', 'INSTALLOPT_ERROR_SET_PROTOCOLS');
                    });
            };

            function getFirmwareFamilyWithProtocol() {

                Wmoapi
                    .getFirmwareFamilies($scope.$parent.request.id)
                    .success(function (response) {
                        $scope.$parent.responseData.vehicleFirmwareFamilies = response;
                        if (response.length > 0) {
                            if (response.length === 1) {
                                var firmwareFamily = response[0];
                                setFirmwareFamily(firmwareFamily);
                            }
                        } else {
                            alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                        }
                    })
                    .error(function (error) {
                        console.error('error in getFirmwareFamilyWithProtocol:', error);
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_VEHICLE_FIRMWARE');
                    })
                    .finally(function () {
                        $rootScope.isGlobalLoading = false;
                    });

            }

            $scope.setFirmwareFamilyUser =  function(ffId, ffName) {
                var fwFamilies = $scope.$parent.responseData.vehicleFirmwareFamilies;
                var fwFamily = null;
                angular.forEach(fwFamilies, function (value) {
                    if (value.id === ffId) {
                        fwFamily = value;
                    }
                });

                if(fwFamily) {
                    setFirmwareFamily(fwFamily);
                } else {
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                }
            };

            function setFirmwareFamily(firmwareFamily) {

                $rootScope.isGlobalLoading = true;

                $scope.$parent.selectedVehicle.firmwareFamilyId = firmwareFamily.id;
                $scope.$parent.selectedVehicle.firmwareFamilyName = firmwareFamily.name;

                Wmoapi
                    .setFirmwareFamily($scope.$parent.request.id, firmwareFamily.id)
                    .success(function () {
                        $rootScope.isGlobalLoading = false;

                        if (firmwareFamily.isKlonHubRequired) {
                            handlePopHubOrRfidRequired(firmwareFamily);
                        } else {
                            $scope.$parent.responseData.vehicleFirmwareFamilies = null;
                            loadFirmwarePartial();
                        }
                    })
                    .error(function (error) {
                        $rootScope.isGlobalLoading = false;
                        console.error('error in setFirmwareFamily:', error);
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                    });
            }

            function loadFirmwarePartial()
            {
                AppService.setFirmwareSkipped(false);
                $scope.$parent.loadFirmwarePartial(false);
            }

            function handlePopHubOrRfidRequired(fwFamily)
            {
                var hubReq = fwFamily.isKlonHubRequired && !AppService.getIsHub();
                var hubSerialMissing = fwFamily.isKlonHubRequired && !AppService.getHubSerial();

                var rfidReq = rfidRequired;
                var requiredList = [];

                if (rfidReq) {
                    $rootScope.isGlobalLoading = true;
                    Wmoapi
                        .getAccessoriesWithAccRfid($scope.$parent.request.id)
                        .success(function (accessories) {
                            angular.forEach(accessories, function (value) {
                                if (value.name) {
                                    requiredList.push(value);
                                }
                            });
                            popHubMessage(hubReq, hubSerialMissing, rfidReq, requiredList);
                        })
                        .error(function (error) {
                            console.log('error in getAccessoriesWithAccRfid');
                        })
                        .finally(function () {
                            $rootScope.isGlobalLoading = false;
                        });
                } else if (hubReq || hubSerialMissing) {
                    popHubMessage(hubReq, hubSerialMissing, rfidReq, requiredList);
                } else {
                    $scope.$parent.responseData.vehicleFirmwareFamilies = null;
                    $scope.$parent.loadFirmwarePartial(false);
                }
            }

            function popHubMessage(hubReq, hubSerialMissing, rfidReq, requiredList)
            {
                var doNotContinue = !!hubReq || !!hubSerialMissing;

                ngDialog.openConfirm({
                    template: 'modules/core/views/modals/hubrequired.modal.client.view.html',
                    className:'ngdialog-theme-plain',
                    closeByDocument: false,
                    showClose: false,
                    controller: ['$scope', function ($scope) {
                        $scope.hubReq = hubReq;
                        $scope.rfidReq = rfidReq;
                        $scope.hubSerialMissing = hubSerialMissing;
                        $scope.requiredList = requiredList;
                        $scope.doNotContinue = doNotContinue;
                    }]
                }).then(function(result){
                    if(!result) {
                        $scope.$parent.responseData.vehicleFirmwareFamilies = null;
                        loadFirmwarePartial();
                    } else {
                        $scope.$parent.selectedVehicle.firmwareFamilyId = '';
                        $scope.$parent.selectedVehicle.firmwareFamilyName = '';
                    }
                },function(error){
                    console.log('Error in popHubMessage...');
                });
            }

            $scope.setOption = function (id) {

                $scope.$parent.userSelection.selectedProtocol.standaloneId = id;
                $scope.$parent.userSelection.selectedProtocol.isStandaloneRf = false;
                $scope.$parent.userSelection.selectedProtocol.isStandaloneTelematic = false;

                switch (id) {
                    case 1: //Aftermarket RF kit (Add RF Remote)
                        AppService.setStandaloneFlags('isRf');
                        $scope.$parent.userSelection.selectedProtocol.isStandaloneRf = true;
                        break;
                    case 2: //Telematic device (Add Telematics)
                        AppService.setStandaloneFlags('isTelematic');
                        $scope.$parent.userSelection.selectedProtocol.isStandaloneTelematic = true;
                        break;
                    case 3: //OEM remotes only (OEM Key Only)
                        loadFwPartialOEM();
                        break;
                    default:
                       console.error('Option selected is not valid');
                }
            };

            function loadFwPartialOEM() {
                $rootScope.isGlobalLoading = true;
                var requestId = $scope.$parent.request.id;
                var oemProtocolId = $scope.$parent.responseData.classicInstallOptions.oemProtocol.id;
                Wmoapi
                    .setProtocol(requestId, oemProtocolId)
                    .success(function () {
                        getFirmwareFamilyWithProtocol();
                    })
                    .error(function (error) {
                        console.error('error in setProtocol:', error);
                        alertBar.show('error', 'INSTALLOPT_ERROR_SET_PROTOCOLS');
                        $rootScope.isGlobalLoading = false;
                    });
            }

        }
    ]);
