/**
 * Created by rafikgharsalli on 2016-05-13.
 */
(function() {
    'use strict';
    angular
        .module('core')
        .directive('errorNotificationBar', errorNotificationBar);

    function errorNotificationBar() {
        return {
            restrict:'AE',
            scope:{
                title: '@',
                message: '@',
                button: '@',
                event: '&',
                hmtext: '@'
            },
            templateUrl:'modules/core/views/directives-partial/error.notification.bar.html'
        };
    }
})();

