(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsSidebarController', PartialsSidebarController);

    PartialsSidebarController.$inject = ['$scope', '$rootScope', 'DeviceService', 'AppService','_', 'sidebarManager', 'constants'];

    var mobileView = 1289;

    function PartialsSidebarController($scope, $rootScope, DeviceService, AppService, _ , sidebarManager, constants) {

        var vm = this;
        vm.app = $scope.app;
        vm.disableHeader = $scope.disableHeader;
        vm.showWiringSection = $scope.showWiringSection;
        vm.sidebar = $scope.sidebar;
        vm.toggle = $scope.toggle;
        vm.appVersion = AppVersion;
        vm.currentYear = new Date().getFullYear();
        vm.loadConfigurationPartial = $scope.loadConfigurationPartial;
        vm.loadInternalRemotesPartial = $scope.loadInternalRemotesPartial;
        vm.loadControlsPartial = $scope.loadControlsPartial;
        vm.loadOemRemoteStartPartial = $scope.loadOemRemoteStartPartial;
        vm.loadFeaturePartial = $scope.loadFeaturePartial;
        vm.loadFirmwarePartial = $scope.loadFirmwarePartial;
        vm.loadFlashPartial = $scope.loadFlashPartial;
        vm.loadGuidesPartial = $scope.loadGuidesPartial;
        vm.loadTelematicsPartial = $scope.loadTelematicsPartial;
        vm.loadInstallOptionsPartial = $scope.loadInstallOptionsPartial;
        vm.isClassicDevice = !$rootScope.hasInvalidDevice ?  DeviceService.isClassicDevice() : false;
        vm.canModifyFeatures = DeviceService.canModifyFeatures();
        vm.isDeviceAccessory = DeviceService.isDeviceAccessory();
        vm.isDeviceRfid = DeviceService.isDeviceRfid();
        vm.globalUser =  $rootScope.globalUser;
        vm.skipControls = $scope.skipControls;
        vm.rebootHub = $scope.$parent.rebootHub;
        vm.manageSidebar = manageSidebar;
        vm.getSidebarImg = getSidebarImg;
        vm.toggleSidebarOnSmallScreen = toggleSidebarOnSmallScreen;
        vm.getConnectionTypeDisplayName = getConnectionTypeDisplayName;
        vm.getConnectionTypeIcon = getConnectionTypeIcon;
        vm.isWeblinkDesktop = $scope.$parent.isInWeblinkDesktop;
        vm.showFirmwareHubDownloadButton =  showFirmwareHubDownloadButton;
        vm.showUS = showUS;

        $scope.$watch('app', function (newValue) {
          vm.app = newValue;
        }.bind(this));

        $scope.$watch('disableHeader', function (newValue) {
          vm.disableHeader = newValue;
        }.bind(this));

        $scope.$watch('showWiringSection', function (newValue) {
          vm.showWiringSection = newValue;
          vm.sidebarTags = sidebarManager.getSidebarTags(vm);
        }.bind(this));

        $scope.$watch('sidebar', function (newValue) {
            if (typeof sidebar !== undefined) {
                vm.sidebar = newValue;
            }
        }.bind(this));

        $scope.$watch('skipControls', function (newValue) {
            vm.skipControls = newValue;
            vm.sidebarTags = sidebarManager.getSidebarTags(vm);
        }.bind(this));

        $scope.$watch('toggle', function (newValue) {
          vm.toggle = newValue;
        }.bind(this));

        $scope.$watch(getWidth, function(newValue) {
            vm.toggle = false;
            $scope.toggle = false;

            if (newValue >= mobileView) {
                vm.toggle = true;
                $scope.toggle = true;
            } else {
                vm.toggle = false;
                $scope.toggle = false;
            }

        }).bind(this);

        window.onresize = function() {
            if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                $scope.$apply();
            }
        };

        function showFirmwareHubDownloadButton() {
            return $rootScope.globalUser.profile ? $rootScope.globalUser.profile.userRoleId === constants.INTERNAL_USER_ROLE_ID && AppService.getIsHub() : false;
        }

        function showUS() {
            return $rootScope.globalUser.profile ? $rootScope.globalUser.profile.filterUsaOn : false;
        }

        function getWidth() {
            return window.innerWidth;
        }
        
        /**
         * Sidebar Toggle & Cookie Control
         */
        function manageSidebar( index, disabled ) {
            var sidebar = vm.sidebar;
            var isDisabled = true;
            var currentIndex = sidebar.currentIndex;
            var isActive = currentIndex === index;
            var guidesIndex = 7;
            var totalIndex = 8;

            if(vm.isClassicDevice) {
                guidesIndex = 8;
                totalIndex = 9;
            } else if (vm.canModifyFeatures) {
                guidesIndex = 4;
                totalIndex = 5;
            }

            if (sidebar.mode === 'flash') {
                isDisabled = currentIndex < index  || disabled;
            }  else {
                isDisabled = currentIndex !== index ;
                if (index === guidesIndex && currentIndex === totalIndex) {
                    isDisabled = false;
                }
            }
            return { active: isActive, disabled: isDisabled};
        }

        function getSidebarImg(index, file, disabled) {
            var sidebar = vm.sidebar;
            var disable = sidebarManager.guidesWiresSidebarStatus;
            var staticInactivePath = 'img/sidebar/';
            var staticActivePath = 'img/sidebar/hover/';
            var path = '';
            var currentIndex = sidebar.currentIndex;
            var guidesIndex = 7;
            var totalIndex = 8;

            if(vm.isClassicDevice) {
                guidesIndex = 8;
                totalIndex = 9;
            } else if (vm.canModifyFeatures) {
                guidesIndex = 4;
                totalIndex = 5;
            }

            if (currentIndex >= index && !disabled && !disable  && sidebar.mode === 'flash') path = staticActivePath + file + '.png';
            else {
                if(index === guidesIndex && currentIndex === totalIndex  || index === currentIndex) {
                    path = staticActivePath + file + '.png';
                } else {
                    path = staticInactivePath + file + '.png';
                }
            }

            return path;
        }

        function getConnectionTypeDisplayName() {
            if (AppService.getIsHub()) {
                return 'FLASH_CONNECTION_TYPE_HUB';
            }

            switch(AppService.getFlashConnectionType()) {
                case constants.WEBLINK_UPDATER:
                    return 'FLASH_CONNECTION_TYPE_CABLE';
                case constants.USB:
                    return 'FLASH_CONNECTION_TYPE_USB';
                default:
                    return 'FLASH_CONNECTION_TYPE_UNAVAILABLE';
            }
        }

        function getConnectionTypeIcon() {
            if (AppService.getIsHub()) {
                return 'hub';
            }

            switch(AppService.getFlashConnectionType()) {
                case constants.WEBLINK_UPDATER:
                    return 'weblink-cable';
                case constants.USB:
                    return 'usb';
                default:
                    return 'none';
            }
        }

        function toggleSidebarOnSmallScreen(isToggled) {
            var windowsWidth = getWidth();
            if (windowsWidth <= mobileView){
                vm.toggle = isToggled;
                $scope.toggle = isToggled;
            }
        }
    }

})();
