(function() {
    'use strict';

    angular
        .module('core')
        .directive('firmwareSlider', FirmwareSlider);

        function FirmwareSlider() {
            return {
                restrict: 'E',
                bindToController: {
                    request: '=',
                    responseData: '=',
                    userSelection: '='
                },
                templateUrl: 'modules/core/views/partials/firmware/directives/firmwareslider.client.directive.view.html',
                controller: 'PartialsFirmwareSliderController',
                controllerAs: 'slidercontroller'
            };
        }
})();
