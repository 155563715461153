/**
 * Created by rafikgharsalli on 2019-09-05
 */
'use strict';
angular.module('lodashFactory', []).factory('_', ['$window', function ($window) {
    if (!$window._) {
        console.error('lodash is not loaded!');
    }
    return $window._;
}]);
