'use strict';

angular.module('core').controller('PartialsConfigurationModelsController', ['$scope', 'Wmoapi', 'configuration','$rootScope', 'RSUData','constants', 'alertBar',
	function($scope, Wmoapi, configurationFactory, $rootScope, RSUData, constants, alertBar) {

        $scope.initVehicleModels = function(){
            $scope.otherModelId = constants.OTHER_VEHICLE_MODEL_ID;
            $scope.managePageNavigation();
            $scope.manageContent();
        };

        $scope.managePageNavigation = function() {
            var selectedModelId = $scope.$parent.selectedVehicle.modelId;
            var modelId = (selectedModelId) ? parseInt(selectedModelId) : 0;
            $scope.hidePageNaviNextBtn = (modelId === 0);
        };

        $scope.manageContent = function() {

            var selectedModelId = $scope.$parent.selectedVehicle.modelId;
            var modelId = (selectedModelId) ? parseInt(selectedModelId) : 0;

            if (modelId === 0) {
                $scope.getVehicleModels();
            } else {
                $scope.manageSubmenu();
            }
        };

        $scope.getVehicleModels = function (){

            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getVehicleModels($scope.$parent.request.id,
                    $scope.$parent.selectedVehicle.makeId,
                    $scope.$parent.selectedVehicle.year,
                    $scope.$parent.configurationParameters.showAllMakes)
                .success(function(response){

                    $scope.$parent.responseData.vehicleModels = response;

                    $scope.manageSubmenu();

                })
                .error(function(error){
                    alertBar.show( 'error', 'CONFIGURATION_MODELS_ERROR_GET_MODELS' );
                })
                .finally(function(){
                    $rootScope.isGlobalLoading = false;
                });

            $scope.responseData.vehicleModels = null;

        };

        $scope.manageSubmenu = function(){

            angular.element(document.querySelector('#body')).removeClass('standard');
            angular.element(document.querySelector('#body')).addClass('body-model');

            $scope.$parent.submenu.selectedItem = configurationFactory.getSubmenuModel();
        };

        $scope.loadVehicleTrims = function  (modelId , modelName , modelImage){

            var tmp = $scope.$parent.selectedVehicle.modelId;
            $scope.$parent.prevSelectedVehicle.modelId = tmp === '' ? modelId : tmp;

            if(tmp !== modelId) {
                clearPrevVehicleSelections();
								RSUData.clearRSUBrands();
            }

            $scope.$parent.selectedVehicle.modelId = modelId;
            $scope.$parent.selectedVehicle.modelName = modelName;
            $scope.$parent.selectedVehicle.modelImage =  modelImage;
            $scope.$parent.responseData.vehicleFirmwareFamilies = null;

            $scope.$parent.loadConfigurationPartial('Trims', true);

        };

        function clearPrevVehicleSelections() {
            var v = $scope.selectedVehicle;
            v.vehicleId = '';
            v.vehicleName = '';
            v.firmwareFamilyId = '';
            v.firmwareFamilyName = '';
            v.firmwareId = '';
            v.firmwareName = '';

			$scope.$parent.userSelection.telematicId = '';
			$scope.$parent.userSelection.telematicName = '';
			$scope.$parent.userSelection.telematicImagePhone = '';
			$scope.$parent.userSelection.selectedFirmware = {};

            var p = $scope.prevSelectedVehicle;
            p.vehicleId = '';
            p.firmwareFamilyId = '';
            p.firmwareId = '';
        }
	}
]);
