/**
 * Configure New Module > Remotes page
 */
'use strict';

angular.module('core').controller('PartialsRemotesController', ['$scope', 'Wmoapi', 'messageBox', 'alertBar', '$rootScope', 'wu',
    'Request', 'DeviceService', '$q', 'RSUData', 'InternalRemotes', 'ngDialog','deviceIdentifier','AppService', '_',
    function ($scope, Wmoapi, messageBox, alertBar, $rootScope,
              wu, Request, DeviceService, $q, RSUData, InternalRemotes, ngDialog, deviceIdentifier, AppService, _) {

        $scope.readRemotePagesResult = null;
        var vm = this;
        var requestId = $scope.request.id;

        vm.remoteBrands = null;
        vm.remotes = null;
        vm.brand = '';
        vm.selectedBrand = RSUData.getSelectedRsuBrand();

        vm.onChangeBrand = onChangeBrand;

        vm.selectExternalRemote = selectExternalRemote;
        vm.loadTelematicsPartial = $scope.loadTelematicsPartial;
        vm.loadFirmwarePartial = $scope.loadFirmwarePartial;

        vm.request = $scope.request;
        vm.responseData = $scope.responseData;
        vm.supportsInternalRemotes = $scope.supportsInternalRemotes;

        // this will depend on device
        vm.useIDataStart = vm.supportsInternalRemotes;

        vm.backSkipStep = 'vehicle.trims';
        vm.nextSkipStep = 'features-save-remotes';

        $scope.$watch('request', function (newValue) {
            vm.request = newValue;
        }.bind(this));

        $scope.$watch('supportsInternalRemotes', function (newValue) {
            vm.supportsInternalRemotes = newValue;
        }.bind(this));

        $scope.$watch('responseData', function (newValue) {
            vm.responseData = newValue;
        }.bind(this));

        init();

        function init() {
            alertBar.hide();
            ngDialog.closeAll();
            $rootScope.isGlobalLoading = true;

            createRequestForRemotes(requestId, vm.supportsInternalRemotes)
                .then(function (remoteRequestId) {
                    RSUData.getRsuBrands(requestId, vm.supportsInternalRemotes)
                        .then(function (success) {

                            if (success.length < 1) showModalBox();

                            if (!vm.selectedBrand && vm.supportsInternalRemotes) {
                                vm.selectedBrand = success[0];
                            }

                            if (vm.supportsInternalRemotes && vm.selectedBrand.isIDataStart) {
                                vm.useIDataStart = true;
                                readRemotePages();
                            } else {
                                vm.useIDataStart = false;
                                $rootScope.isGlobalLoading = false;
                                if (vm.selectedBrand && vm.selectedBrand.id) {
                                    onChangeBrand(vm.selectedBrand);
                                }
                            }
                            vm.remoteBrands = success;
                        }, function (error) {
                            $rootScope.isGlobalLoading = false;
                            alertBar.show('error', 'CONTROLS_ERROR_GET_REMOTES');
                            // show error box
                        });

                }, function (error) {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show('error', 'CONTROLS_ERROR_CREATE_REQUEST');
                    // show error
                });
            // need to do new API call
        }

        function showModalBox() {
            ngDialog.openConfirm({
                template: 'modules/core/views/directives-partial/rsuBrandsMessageBox.html',
                className: 'ngdialog-theme-plain',
                controller: 'PartialsRsuBrandsModalController as ctrl',
                closeByDocument: false,
                showClose: false
            }).then(function (result) {
                // user did somthing else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }

        function onChangeBrand(brand) {
            RSUData.setSelectedRsuBrand(brand);
            if (brand.isIDataStart) {
                vm.useIDataStart = true;
                // show internal remotes screen
                readRemotePages();
            } else {
                vm.useIDataStart = false;
                vm.remotes = null;
                $rootScope.isGlobalLoading = true;
                RSUData.getRemotes(requestId, brand.id).then(function (success) {
                    $rootScope.isGlobalLoading = false;
                    vm.brand = brand;
                    vm.remotes = success;
                }, function (error) {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show('error', 'CONTROLS_ERROR_GET_REMOTES');
                });
            }
        }

        // async functions

        function createRequestForRemotes(flashRequestId, supportsInternalRemotes) {
            var deferred = $q.defer();

            if (supportsInternalRemotes) {
                if ($scope.request.configRemotesRequestId) {
                    var remoteRequestId = $scope.request.configRemotesRequestId;
                    deferred.resolve(remoteRequestId);
                } else {

                    Wmoapi.createRequestConfigureRemotes(flashRequestId).then(function (success) {
                        var remoteRequestId = success.data.requestId;
                        $scope.request.configRemotesRequestId = remoteRequestId;
                        $scope.responseData.deviceControls = null;
                        deferred.resolve(remoteRequestId);
                    }, function (error) {
                        deferred.reject(error);
                    });
                }
            } else {
                deferred.resolve(null);
            }

            return deferred.promise;
        }

        function getSelectedRemotes(remoteRequestId) {
            var deferred = $q.defer();
            var deviceControls = $scope.responseData.deviceControls;

            if (deviceControls) {
                deferred.resolve(deviceControls);
            } else {
                var remotePages = $scope.$parent.responseData.remotePagesReadings;

                // Send FF's for remote pages if last flash is not iDatastart compatible
                var device = DeviceService.getFlashableDevice();
                var sendEmptyReading = false;
                if(device.firmwareID && device.firmwareID > 0 && !device.isIdatastartRemotePairingCompatible) {
                    sendEmptyReading = true;
                }

                Wmoapi.getRemotes(remoteRequestId, remotePages, sendEmptyReading)
                    .then(function (success) {
                        var selectedRemotes = success.data.remotes;
                        var countRemoteIncompatible = success.data.countRemoteIncompatible;

                        $scope.responseData.deviceControls = selectedRemotes;
                        $scope.responseData.deviceControlPages = success.data.pages;

                        if (countRemoteIncompatible > 0) {
                            alertBar.show('error', 'REMOTE_UNRECOGNIZED', {'COUNT': countRemoteIncompatible});
                        }

                        deferred.resolve(selectedRemotes);
                    }, function (error) {
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        }

         function readRemotePages () {
            $rootScope.isGlobalLoading = true;
            var remotePages = $scope.$parent.responseData.remotePages;
            if (remotePages.length > 0) {
                var device = DeviceService.getMainDevice();
                $scope.readRemotePagesResult = wu.getReadModuleResult(device, remotePages, onReadRemotePagesComplete, onReadRemotePagesError);
                $scope.readRemotePagesResult.start();
            } else {
                $rootScope.isGlobalLoading = false;
                alertBar.show('error', 'FLASH_KLON_ERROR_NO_REMOTE_PAGES');
            }
        }

        function onReadRemotePagesComplete() {

            var readings = wu.transformPageReadingsToArray($scope.readRemotePagesResult.configuration);
            $scope.$parent.responseData.remotePagesReadings = readings;
            var remoteRequestId = $scope.request.configRemotesRequestId;
            getSelectedRemotes(remoteRequestId).then(function (selectedRemotes) {
                $rootScope.isGlobalLoading = false;
                vm.selectedRemotes = transformSelectedRemotes(selectedRemotes);
            }, function (error) {
                $rootScope.isGlobalLoading = false;
                alertBar.show('error', 'CONTROLS_ERROR_GET_REMOTES');
            });
        }

        function onReadRemotePagesError (error) {
            console.log(error);
            console.log($rootScope.isGlobalLoading);
            $rootScope.isGlobalLoading = false;
            var error_msg = _.isEmpty(error) ? '' : ' [ ' + (error.error || error) + ' ]';
            alertBar.show('errorFull', 'DETECTION_ERROR_REMOTE_READ_EEPROM', {'ERROR_MSG' : error_msg});
            $scope.$apply();
        }

        function selectExternalRemote(remoteData, brandId) {

            var remoteId = remoteData.id;

            $rootScope.isGlobalLoading = true;

            $scope.responseData.telematics = [];
            $scope.userSelection.telematicId = '';
            $scope.userSelection.selectedRemote = {};

            Wmoapi.setRsuRemote(requestId, remoteId, brandId)
                .then(function () {

                    InternalRemotes.setRemoteId(remoteId);
                    $scope.$parent.responseData.deviceControls = [];
                    RSUData.setSelectedRsu(remoteData);
                    $scope.userSelection.selectedRemote.id = remoteId;
                    $scope.loadTelematicsPartial()(false);
                    $rootScope.isGlobalLoading = false;
                }, function (error) {
                    alertBar.show('error', 'Error setting remote');
                    $rootScope.isGlobalLoading = false;
                });
        }

        // helpers

        function transformSelectedRemotes(selectedRemotes) {
            var remotes = [{}, {}, {}, {}];

            angular.forEach(selectedRemotes, function (value, key) {
                remotes[key] = value;
            });

            return remotes;
        }

    }
]);
