'use strict';
angular.module('core').controller('PartialsFlashByFirmwareController', ['$scope', '$rootScope','$window', 'Wmoapi', 'Request','deviceDetect', 'DeviceService',
    function ($scope, $rootScope, $window, Wmoapi, Request, deviceDetect, DeviceService){

        $scope.request = {};
        $scope.protocols = [];
        $scope.firmwareFamilies = [];
        $scope.firmwares = [];
        $scope.selectedProtocol = {};
        $scope.selectedFirmwareFamily = {};
        $scope.selectedFirmware = {};
        $scope.firmwareSelectionConfirmed = false;
        $scope.loading = false;

        $scope.initFlashByFirmware = function () {
            var device = DeviceService.getDeviceBySerial($scope.$parent.serialToUpdate);
            var serial = device.serial;
            var bootloader = device.bootloader.version;
            $scope.loading = true;
            $scope.firmwareSelectionConfirmed = false;

            Wmoapi.createRequestFlashByFwNoParent(bootloader, serial)
                .success(function(request){
                    $scope.request = request;
                    Request.setRequestDevice(request.requestId, bootloader, serial);
                    Wmoapi.getProtocols(request.requestId)
                        .success(function (protocols){
                            $scope.protocols = protocols;
                            $scope.loading = false;
                        });
                });
        };

        $scope.selectProtocol = function (id) {
            $scope.firmwareFamilies = [];
            $scope.firmwares = [];
            $scope.loading = true;
            $scope.firmwareSelectionConfirmed = false;
            $scope.selectedProtocol = $scope.protocols.find(protocol => protocol.id === id);
            $scope.selectedFirmwareFamily = {};
            $scope.selectedFirmware = {};

            if ($scope.selectedProtocol.id) {
                Wmoapi.setProtocol($scope.request.requestId, $scope.selectedProtocol.id)
                    .success(function (result) {
                        Wmoapi.getFirmwareFamilies($scope.request.requestId).success(function (firmwareFamilies){
                            $scope.firmwareFamilies = firmwareFamilies;
                            $scope.loading = false;
                        });
                    });
            }
        };

        $scope.selectFirmwareFamily = function (id) {
            $scope.firmwares = [];
            $scope.loading = true;
            $scope.firmwareSelectionConfirmed = false;
            $scope.selectedFirmwareFamily = $scope.firmwareFamilies.find(firmwareFamily => firmwareFamily.id === id);
            $scope.selectedFirmware = {};

            if ($scope.selectedFirmwareFamily.id) {
                Wmoapi.setFirmwareFamily($scope.request.requestId, $scope.selectedFirmwareFamily.id)
                    .success(function (result) {
                        Wmoapi.getFirmwares($scope.request.requestId).success(function (response){
                            var firmwares = [];
                            $scope.firmwares = response.firmwares.reduce(function(firmwareList, firmwareType) {
                                return firmwareList.concat(firmwareType.firmwares);
                            }, firmwares);
                            $scope.loading = false;
                        });
                    });
            }
        };

        $scope.selectFirmware = function (id) {
            $scope.selectedFirmware = $scope.firmwares.find(firmwareFamily => firmwareFamily.id === id);
            if ($scope.selectedFirmware.id) {
                Wmoapi.setFirmware($scope.request.requestId, $scope.selectedFirmware.id)
                    .success(function (result) {
                        $scope.$parent.responseData.setFirmware = result;
                        $scope.firmwareSelectionConfirmed = true;
                    });
            }
        };

    }]);
