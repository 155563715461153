'use strict';

/**
 * Created by jeromesoulas on 15-07-06.
 */
angular.module('core').directive('featureOptionsRadio', [
    function(){

    function link($scope, $elem, attrs) {
        $scope.editoption = function() {
            $scope.reverse();
        };
    }

    return {
        link: link,
            scope: {
            options: '=featureOptionsRadio',
            featureId: '@featureId',
            isLocked: '@featureLocked',
            featureName: '@featureName',
            reverse: '&'
        },
        templateUrl: 'modules/core/views/directives-partial/feature.options.radio.html'
    };

    }
]);