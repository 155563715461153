'use strict';

angular.module('core').factory('Request', ['Wmoapi',
    function(Wmoapi) {

        var requestId_flash = '';
        var requestId_configRemotes = '';
        var requestId_configModule = '';
        var request_device = { id:'', bootloader:{version:0}, serial:'' };

        return {
            cancelRequest: function (requestId, onSuccess, onError) {
                Wmoapi.cancel(requestId)
                    .success(function(response){
                        onSuccess(response);
                    })
                    .error(function(error){
                        onError(error);
                    });
            },
            clearAllRequestIds: function () {
                requestId_flash = '';
                requestId_configRemotes = '';
                requestId_configModule = '';
            },
            getRequestIdFlash: function () {
                return requestId_flash;
            },
            getRequestIdConfigRemotes: function () {
                return requestId_configRemotes;
            },
            getRequestIdConfigModule: function () {
                return requestId_configModule;
            },
            setRequestIdFlash: function (requestId) {
                requestId_flash = requestId;
            },
            setRequestIdConfigRemotes: function (requestId) {
                requestId_configRemotes = requestId;
            },
            setRequestIdConfigModule: function (requestId) {
                requestId_configModule = requestId;
            },
            clearRequestDevice: function() {
                request_device = { id:'', bootloader:{version:0}, serial:'' };
            },
            getRequestDevice: function() {
                return request_device;
            },
            setRequestDevice: function(requestId, bootloader, serial) {
                request_device.id = requestId;
                request_device.bootloader = bootloader;
                request_device.serial = serial;
            }
        };

    }
]);
