/**
 * Created by Malcolm Laing on 2017-07-28.
 */
'use strict';


angular.module('core').controller('PartialsExternalRemotesController', ['$scope', '$rootScope',
    function($scope, $rootScope) {

    var vm = this;
    vm.isGlobalLoading = $rootScope.isGlobalLoading;

    $scope.$watch(function() {
      return $rootScope.isGlobalLoading;
    }, function() {
      vm.isGlobalLoading = $rootScope.isGlobalLoading;
    }, true);

    vm.selectExternalRemote = $scope.selectExternalRemote;
  }
]);
