(function () {
  'use strict';

  angular
    .module('core')

    .controller('LoginHomeController', LoginHomeController);

  LoginHomeController.$inject = [
    '$scope',
    'Wmoapi',
    '$rootScope',
    '$state',
    'checkStatus',
    'translateService',
    'AppService',
    'FacebookService'
  ];

  function LoginHomeController(
    $scope,
    Wmoapi,
    $rootScope,
    $state,
    checkStatus,
    translateService,
    AppService,
    FacebookService
    ) {
      var vm = this;
      vm.serverError = false;
      vm.demo_mode = checkStatus.data.demo_mode;
      vm.submitForm = submitForm;
      vm.facebookError =  null;
      $scope.isInWeblinkDesktop = AppService.isPramanthaObjectDetected();
      
      $rootScope.$on('$translateChangeSuccess', function () {
        
        vm.form[0].title = translateService.translate(
          'REGISTRATION_FORM_PLACEHOLDER_EMAIL'
          );
          vm.form[1].title = translateService.translate(
            'REGISTRATION_ENTER_PASSWORD'
            );
            vm.form[2].title = translateService.translate('LOGIN_BUTTON');
            $scope.$broadcast('schemaFormRedraw');
          });
          
          vm.schema = {
            type: 'object',
            properties: {
              email: {
                key: 'email',
                type: 'string',
                validationMessage: {
                  302: translateService.translate('REGISTRATION_FIELD_REQUIRED')
                }
              },
              password: {
                type: 'string',
                validationMessage: {
                  302: translateService.translate('REGISTRATION_FIELD_REQUIRED')
                }
              }
            },
            required: ['email', 'password']
    };
    
    vm.model = {
      email: '',
      password: ''
    };
    
    vm.form = [{
      key: 'email',
      type: 'material-input',
      title: translateService.translate(
        'REGISTRATION_FORM_PLACEHOLDER_EMAIL'
        ),
        htmlClass: 'input__group',
        onChange: clearServerError
      },
      {
        key: 'password',
        htmlClass: 'input__group',
        type: 'material-input',
        onChange: clearServerError,
        title: translateService.translate('REGISTRATION_ENTER_PASSWORD')
      },
      {
        type: 'submit',
        title: translateService.translate('LOGIN_BUTTON'),
        fieldHtmlClass: 'button'
      }
    ];
    
    //Prelogin
    $rootScope.globalUser = {};

    Wmoapi.preLogin()
        .success(function (userProfile) {
          if(userProfile && userProfile.id){
            $rootScope.globalUser.profile = userProfile;
            $state.go('core.home');
          }
        });

    $scope.facebookEnable = AppService.getFacebookEnable();
    
    $scope.openWeblinkDesktop = function openWeblinkDesktop() {
      window.open('https://maestro.idatalink.com/weblinkdesktop', '_blank');
    };
    
    function clearServerError() {
      if (vm.serverError) {
        vm.serverError = false;
        $scope.$broadcast(
          'schemaForm.error.password',
          'invalidUserOrPass',
          true
        );
        $scope.$broadcast('schemaForm.error.email', 'invalidUserOrPass', true);
      }
    }


    function submitForm(form) {
      $scope.$broadcast('schemaFormValidate');


      if (form.$valid) {
        $rootScope.isGlobalLoading = true;
        // submit to server.
        $rootScope.globalUser = {};
        var rootLanguage = translateService.getCurrentLanguage().toLowerCase();

        Wmoapi.login(vm.model.email, vm.model.password, rootLanguage)
          .success(function (userProfile) {
            $rootScope.globalUser.profile = userProfile;
            $scope.$broadcast(
              'schemaForm.error.password',
              'invalidUserOrPass',
              true
            );
            $state.go('core.home');
          })
          .error(function () {
            vm.serverError = true;
            $rootScope.isGlobalLoading = false;
            $scope.$broadcast(
              'schemaForm.error.password',
              'invalidUserOrPass',
              translateService.translate('LOGIN_MSG_INVALID')
            );
            $scope.$broadcast(
              'schemaForm.error.email',
              'invalidUserOrPass',
              translateService.translate('LOGIN_MSG_INVALID')
            );
          });
      }
    }

    $scope.fbLogin = function () {
      $rootScope.globalUser = {};
      vm.facebookError =  null;
      var rootLanguage = translateService.getCurrentLanguage().toLowerCase();
      clearServerError();
      $rootScope.isGlobalLoading = true;
      FacebookService.login(vm.serverError)
          .then(function(response){
            if (response.authResponse.accessToken) {
              Wmoapi.socialLogin(
                  'facebook',
                  response.authResponse.accessToken,
                  rootLanguage
                )
                .success(function () {
                  $state.go('core.home');
                })
                .error(function (error) {
                  console.error('facebook error:', error);
                  vm.facebookError =  error;
                });
            }
          })
          .catch(function(err){
            $rootScope.isGlobalLoading = false;
            vm.facebookError =  err;
            console.error('facebook error here...', err);
          });
    };
  }
})();
