/**
 * Created by rafikgharsalli on 2016-01-14.
 */
'use strict';

angular.module('core').filter('wirelocation',[
    function() {

        return function (input) {

            var location = [] ;

            if (input) {

                if (input.component) {
                    location.push(input.component);
                }

                if (input.connector_color || input.connector_pins > 0) {
                    var color_pin_plug =  input.connector_color ? input.connector_color : '' ;
                    color_pin_plug += input.connector_pins > 0 ? (color_pin_plug ? ' ' : '') + input.connector_pins + '-pin plug' : '';
                    color_pin_plug += input.connector.indexOf('UNNAMED') === 0 ? '' : ' ' + '(' + input.connector + ')' ;
                    location.push(color_pin_plug);
                }

                if (input.pin) {
                    location.push('pin ' + input.pin);
                }

            }

            return location.join(', ');

        };
    }
]);
