'use strict';

// Setting up route
angular.module('core').config(['$stateProvider', '$urlRouterProvider',
	function($stateProvider, $urlRouterProvider) {
		// Redirect to home view when route not found
		$urlRouterProvider.otherwise('/');

		// parent state routing.

		$stateProvider.
			state('core', {
				abstract: true,
				url: '',
				// every state needs a template.
				template: '<ui-view />',
				resolve: {
					checkStatus: ['AppService', '$q', function(AppService, $q) {


						var deferred = $q.defer();

						var baseUrl = AppService.getBaseUrl();

						AppService.getStatus(baseUrl)
							.then(function(response) {
								var status = response.data;
								AppService.setStatus(status);
								deferred.resolve(response);
							},
							function(error) {
                                deferred.reject(error);
							});

						return deferred.promise;
					}]
				}
			}
		);

		// Home state routing

		$stateProvider.
			state('core.home', {
				url: '/',
				data: {
					showLoading: true
				},
				resolve: {
					redirectIfUnauthorized: ['checkAuth', 'AppService', function(checkAuth, AppService) {
						try {
							var isInWeblinkDesktop = AppService.isPramanthaObjectDetected();
							/* globals DemoMode , isBrowserCompatible */
							var canAccess = DemoMode || isBrowserCompatible;

							if(!isInWeblinkDesktop) {
								return checkAuth.redirectIfUnauthorized(canAccess, 'unsupported-browser');
							}
						}

						catch(exception) {
							console.error('Exception', exception);
						}
					}],
					detectedDevice: ['$q', 'deviceDetect', 'DeviceService', 'checkStatus','$rootScope', 'AppService', 'checkAuth',
						function($q, deviceDetect, DeviceService, checkStatus, $rootScope, AppService, checkAuth) {
						var deferred = $q.defer();
						$rootScope.$broadcast('$stateChangeStart', this);
						var isInWeblinkDesktop = AppService.isPramanthaObjectDetected();

						if(!isInWeblinkDesktop) {
							return checkAuth.redirectIfUnauthorized(false, 'unsupported-browser');
						}
						try {
							var wu = weblinkupdater.weblinkupdater;
							wu.mockEnabled = checkStatus.data.demo_mode;
							wu.devMode = checkStatus.data.demo_mode;
							wu.virtualDevice = checkStatus.data.virtual_device;
							var defaultDevices = [{ deviceFunction:'...', productID: 'generic' }];
							$rootScope.hasInvalidDevice = false;

							deviceDetect.detect()
								.then(function (devices) {
									deviceDetect.manageUserPlugin()
										.then(function () {
											if (AppService.getRequiresReboot()) {
												DeviceService.setDevices(defaultDevices);
												deferred.resolve(defaultDevices);
											} else {
												DeviceService.setDevices(devices);
												deferred.resolve(devices);
											}

										}, function (error) {
											console.error('Error in deviceDetect.manageUserPlugin:', error);
											defaultDevices.error = error;
											DeviceService.setDevices(defaultDevices);
											deferred.resolve(defaultDevices);
										});

									}, function (error) {
										console.error('Error in device detection:', error);
										defaultDevices.error = error.errorMsg;
										defaultDevices.errorStatus = error.errorCode;
										DeviceService.setDevices(defaultDevices);
										deferred.resolve(defaultDevices);
									}
								);

						}

						catch(exception) {
							console.error('Exception:', exception);
						}

						return deferred.promise;
					}]
				},
				controller: 'HomeController',
				templateUrl: 'modules/core/views/home.client.view.html'
		});
        $stateProvider.
            state('core.consume-token', {
                url: '/consume-token?token&language&src&srcsite',
                templateUrl: 'modules/core/views/consume-token/home.client.view.html'
            });
		$stateProvider.
        	state('unsupported-browser', {
		    	url: '/unsupported-browser',
		    	controller: 'UnsupportedBrowserHomeController as unsupportedBrowserCtrl',
		     	templateUrl: 'modules/core/views/unsupported-browser/home.client.view.html'
            });
        $stateProvider.
            state('core.login', {
                url: '/login?src',
                templateUrl: 'modules/core/views/login/home.client.view.html',
				controller: 'LoginHomeController as loginCtrl',
                resolve: {
					checkIfLoggedIn: ['AppService', 'Wmoapi', '$state', '$q', '$stateParams', 'checkAuth', function(AppService, Wmoapi, $state, $q, $stateParams, checkAuth) {
						var isInWeblinkDesktop = AppService.isPramanthaObjectDetected();
						var hasAccess = DemoMode || isBrowserCompatible;
						if (!isInWeblinkDesktop) {
							return checkAuth.redirectIfUnauthorized(false, 'unsupported-browser');
						}
					}],
                },
            });
        $stateProvider.
            state('splash', {
                url: '/splash',
                templateUrl: 'modules/core/views/splash/home.client.view.html'
            });

	}

]);
