'use strict';
angular.module('core').directive('showDuringResolve', function($rootScope, $timeout) {

return {
      restrict: 'E',
      replace: true,
      templateUrl: 'modules/core/views/directives-partial/loader.client.view.html',
      link: function(scope, element) {

        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
            // temporary -> what we could do is pass a data param for show loader or something like this...
            if (toState.data && toState.data.showLoading) {
                $timeout(function() {
                  element.removeClass('ng-hide');
                });
            }
      });

        $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
            event.preventDefault();
            $timeout(function() {
              element.addClass('ng-hide');
            });

        });
        $rootScope.$on('$stateChangeSuccess', function() {
          element.addClass('ng-hide');
        });
      }
    };
});
