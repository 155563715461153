'use strict';

angular.module('core').controller('PartialsConfigurationTrimsController', ['$scope', '$window', 'Wmoapi', 'configuration', '$rootScope', 'AppService',
    'alertBar', 'RSUData', 'ngDialog', 'constants', 'DeviceService', 'deviceIdentifier',
    function ($scope, $window, Wmoapi, configurationFactory, $rootScope, AppService, alertBar, RSUData, ngDialog, constants, DeviceService, deviceIdentifier) {

        var skipControls = $scope.$parent.skipControls;
        var mainDevice = DeviceService.getFlashableDevice();
        var isDetectedDeviceIsClassic = deviceIdentifier.isDetectedDeviceIsClassic(mainDevice.productPartOptions);
        var isDetectedDeviceIsClassicRS = deviceIdentifier.isDetectedDeviceIsClassicRS(mainDevice.productPartOptions);
        var canModifyFeatures = deviceIdentifier.isConfigureModuleAllowed(mainDevice.allowedServices);
        var isDeviceAccessory = deviceIdentifier.isDeviceAccessory(mainDevice.deviceFunctionId);
        var isDeviceRfid = deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId);
        var rfidRequired = deviceIdentifier.doesDetectedDeviceUseAccRfid(mainDevice.productPartOptions);

        $scope.initVehicleTrims = function () {
            $scope.managePageNavigation();
            $scope.manageContent();
        };

        $scope.managePageNavigation = function () {
            var selectedvehicleId = $scope.$parent.selectedVehicle.vehicleId;
            var vehicleId = (selectedvehicleId) ? parseInt(selectedvehicleId) : 0;
            $scope.hidePageNaviNextBtn = (vehicleId === 0);

            $scope.backSkipStep = 'vehicle.model';
            var classicNextStep = skipControls ? 'telematics' : 'internal-remotes';
            $scope.nextSkipStep = isDetectedDeviceIsClassic ? 'protocols' : classicNextStep;
            $scope.nextSkipStep = isDetectedDeviceIsClassic ? 'protocols' : classicNextStep;

        };

        $scope.manageContent = function () {
            var otherObject = constants;
            var selectedVehicle = $scope.$parent.selectedVehicle;
            var selectedvehicleId = selectedVehicle.vehicleId;
            var vehicleId = (selectedvehicleId) ? parseInt(selectedvehicleId) : 0;
            handleMessageBox(selectedVehicle, otherObject);

            if (vehicleId === 0) {
                $scope.getVehicleTrims();
            } else {
                $scope.manageSubmenu();
            }
        };

        $scope.getVehicleTrims = function () {

            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getVehicles($scope.$parent.request.id,
                    $scope.$parent.selectedVehicle.makeId,
                    $scope.$parent.selectedVehicle.year,
                    $scope.$parent.selectedVehicle.modelId,
                    $scope.$parent.configurationParameters.showAllMakes)
                .success(function (response) {

                    $scope.$parent.responseData.vehicleTrims = response;
                    $scope.manageSubmenu();

                })
                .error(function (error) {
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_VEHICLE_TRIMS');
                })
                .finally(function () {
                    $rootScope.isGlobalLoading = false;
                });

            $scope.$parent.responseData.vehicleTrims = null;
        };

        $scope.manageSubmenu = function () {

            $scope.$parent.submenu.selectedItem = configurationFactory.getSubmenuTrim();
        };

        $scope.setVehicle = function (vehicleId, vehicleName, vehicleTransmission) {

            alertBar.hide();
            $rootScope.isGlobalLoading = true;
            var embeddedDevice = DeviceService.getEmbeddedDevice();

            var tmp = $scope.$parent.selectedVehicle.vehicleId;
            $scope.$parent.prevSelectedVehicle.vehicleId = tmp === '' ? vehicleId : tmp;

            if (tmp !== vehicleId) {
                clearPrevVehicleSelections();
                // also clear the brands!
                RSUData.clearRSUBrands();
            }

            $scope.$parent.selectedVehicle.vehicleId = vehicleId;
            $scope.$parent.selectedVehicle.vehicleName = vehicleName;
            var requestId = $scope.$parent.request.id;

            Wmoapi
                .setVehicle(requestId, vehicleId, $scope.$parent.configurationParameters.showAllMakes)
                .success(function () {
                    if(isDetectedDeviceIsClassic){
                        if(embeddedDevice && deviceIdentifier.isDetectedDeviceVehicleDependantOnMainDevice(embeddedDevice.productPartOptions)){
                            getFirmwareAvailableForEmbeddedDevice();
                        } else {
                            getClassicInstallOptions();
                        }
                    } else if(!canModifyFeatures && (isDeviceAccessory || isDeviceRfid)) {
                        getProtocol();
                    } else {
                        getFwFamNoProtocol();
                    }
                })
                .error(function (error) {
                    console.error('error in setVehicle:', error);
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_VEHICLE');
                });

        };

        function getFirmwareAvailableForEmbeddedDevice() {
            var embeddedDevice = DeviceService.getEmbeddedDevice();
            var vehicleId = $scope.$parent.selectedVehicle.vehicleId;
            Wmoapi.getFirmwareByVehicle(embeddedDevice.serial, vehicleId).then(
                function (response) {
                    if (response.data.id) {
                        getClassicInstallOptions();
                    } else {
                        ngDialog.openConfirm({
                            template: 'modules/core/views/modals/vehiclefirmware.modal.client.view.html',
                            className:'ngdialog-theme-plain',
                            controller: ['$scope', function ($scope) {
                                $scope.embeddedDeviceName = embeddedDevice.partName;
                            }]
                        }).then(function(){
                            getClassicInstallOptions();
                        }, function () {
                            $window.location.reload(true);
                        });
                    }
                },
                function (error) {
                    console.error('error in getFirmwareAvailableForEmbeddedDevice:', error);
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_VEHICLE');
                }
            );

        }


        function getClassicInstallOptions() {
            var requestId = $scope.$parent.request.id;

            $scope.$parent.userSelection.selectedProtocol.usedAsAddOn = false;
            $scope.$parent.userSelection.selectedProtocol.isStandalone = false;
            $scope.$parent.userSelection.selectedProtocol.isStandaloneRf = false;
            $scope.$parent.userSelection.selectedProtocol.isStandaloneTelematic = false;
            $scope.$parent.userSelection.selectedProtocol.standaloneId = '';
            $scope.$parent.userSelection.selectedProtocol.id = '';
            $scope.$parent.userSelection.selectedProtocol.name = '';
            $scope.$parent.responseData.classicInstallOptions = {};
            $scope.$parent.responseData.protocols = [];

            if (isDetectedDeviceIsClassicRS) {
                Wmoapi
                    .getProtocols(requestId)
                    .success(function (response) {
                        $scope.$parent.responseData.protocols = response;
                        $scope.$parent.loadInstallOptionsPartial();
                    })
                    .error(function (error) {
                        console.error('error in getProtocols:', error);
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_PROTOCOLS');
                    })
                    .finally(function () {
                        $rootScope.isGlobalLoading = false;
                    });
            } else {
                Wmoapi
                    .getClassicInstallOptions(requestId)
                    .success(function (response) {
                        $scope.$parent.responseData.classicInstallOptions = response;
                        $scope.$parent.loadInstallOptionsPartial();
                    })
                    .error(function (error) {
                        console.error('error in getClassicInstallOptions:', error);
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_INSTALL_OPTIONS');
                    })
                    .finally(function () {
                        $rootScope.isGlobalLoading = false;
                    });
            }
        }

        function getProtocol() {

            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getProtocols($scope.$parent.request.id)
                .success(function (response) {
                    if (response.length > 0) {
                        if (response.length === 1) {
                            setProtocol(response[0].id);
                        } else {
                            var protocolId = 0;
                            angular.forEach(response, function (value) {
                                if (value.isDefault) {
                                    protocolId = value.id;
                                }
                            });
                            setProtocol(protocolId);
                        }
                    } else {
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                        $rootScope.isGlobalLoading = false;
                    }
                })
                .error(function (error) {
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_VEHICLE_FIRMWARE');
                    $rootScope.isGlobalLoading = false;
                });
        }

        function setProtocol(protocolId) {

            Wmoapi
                .setProtocol($scope.$parent.request.id, protocolId)
                .success(function (response) {
                    getFwFam();
                })
                .error(function (error) {
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                    $rootScope.isGlobalLoading = false;
                });
        }

        function getFwFam() {

            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getFirmwareFamilies($scope.$parent.request.id)
                .success(function (response) {
                    $scope.$parent.responseData.vehicleFirmwareFamilies = response;
                    if (response.length > 0) {
                        if (response.length === 1) {
                            var firmwareFamily = response[0];
                            setFirmwareFamily(firmwareFamily);
                        }
                    } else {
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                        $rootScope.isGlobalLoading = false;
                    }
                })
                .error(function (error) {
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_VEHICLE_FIRMWARE');
                    $rootScope.isGlobalLoading = false;
                });
        }

        function getFwFamNoProtocol() {

            Wmoapi
                .getFirmwareFamiliesNoProtocol($scope.$parent.request.id)
                .success(function (response) {
                    $scope.$parent.responseData.vehicleFirmwareFamilies = response;
                    if (response.length > 0) {
                        if (response.length === 1) {
                            var firmwareFamily = response[0];
                            setFirmwareFamily(firmwareFamily);
                        } else {
                            $rootScope.isGlobalLoading = false;
                        }
                    } else {
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                    }
                })
                .error(function (error) {
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_VEHICLE_FIRMWARE');
                })
                .finally(function () {
                });
        }

        function handlePopHubOrRfidRequired(fwFamily)
        {
            var hubReq = fwFamily.isKlonHubRequired && !AppService.getIsHub();
            var hubSerialMissing = fwFamily.isKlonHubRequired && !AppService.getHubSerial();
            var rfidReq = rfidRequired;
            var requiredList = [];

            if (rfidReq) {
                $rootScope.isGlobalLoading = true;
                Wmoapi
                    .getAccessoriesWithAccRfid($scope.$parent.request.id)
                    .success(function (accessories) {
                        angular.forEach(accessories, function (value) {
                            if (value.name) {
                                requiredList.push(value);
                            }
                        });
                        popHubMessage(hubReq, hubSerialMissing, rfidReq, requiredList);
                    })
                    .error(function (error) {
                        console.log('error in getAccessoriesWithAccRfid');
                    })
                    .finally(function () {
                        $rootScope.isGlobalLoading = false;
                    });
            } else if (hubReq || hubSerialMissing) {
                popHubMessage(hubReq, hubSerialMissing, rfidReq, requiredList);
            } else {
                if (!canModifyFeatures && (isDeviceAccessory || isDeviceRfid)) {
                    $scope.$parent.loadFirmwarePartial(false);
                } else if (skipControls) {
                    $scope.$parent.loadTelematicsPartial();
                } else {
                    loadRemotePartial();
                }
            }
        }

        function popHubMessage(hubReq, hubSerialMissing, rfidReq, requiredList)
        {
            var doNotContinue = !!hubReq || !!hubSerialMissing;

            ngDialog.openConfirm({
                template: 'modules/core/views/modals/hubrequired.modal.client.view.html',
                className:'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false,
                controller: ['$scope', function ($scope) {
                    $scope.hubReq = hubReq;
                    $scope.hubSerialMissing = hubSerialMissing;
                    $scope.rfidReq = rfidReq;
                    $scope.requiredList = requiredList;
                    $scope.doNotContinue = doNotContinue;
                }]
            }).then(function(result){
                if(!result) {
                    if (!canModifyFeatures && (isDeviceAccessory || isDeviceRfid)) {
                        $scope.$parent.loadFirmwarePartial(false);
                    } else if (skipControls) {
                        $scope.$parent.loadTelematicsPartial();
                    } else {
                        loadRemotePartial();
                    }
                }
            },function(error){
                console.log('Error in popHubMessage...');
            });
        }

        function loadRemotePartial() {

            $rootScope.isGlobalLoading = true;

            Wmoapi.getRemoteEepromAddress().success(function (response) {
                $scope.$parent.responseData.remotePages = response;
                $scope.$parent.loadInternalRemotesPartial(true);
            }).error(function (error) {
                $rootScope.isGlobalLoading = false;
                console.log('--- getRemoteEepromAddress');
            });
        }

        $scope.setFirmwareFamilyUser =  function(ffId, ffName) {

            var fwFamilies = $scope.$parent.responseData.vehicleFirmwareFamilies;
            var fwFamily = null;
            angular.forEach(fwFamilies, function (value) {
                if (value.id === ffId) {
                    fwFamily = value;
                }
            });

            if(fwFamily) {
                setFirmwareFamily(fwFamily);
            } else {
                alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
            }
        };

        function setFirmwareFamily(firmwareFamily) {

            $scope.$parent.selectedVehicle.firmwareFamilyId = firmwareFamily.id;
            $scope.$parent.selectedVehicle.firmwareFamilyName = firmwareFamily.name;
            $scope.$parent.responseData.firmwareFamily = firmwareFamily;

            $rootScope.isGlobalLoading = true;

            Wmoapi
                .setFirmwareFamily($scope.$parent.request.id, firmwareFamily.id)
                .success(function (response) {

                    $rootScope.isGlobalLoading = false;
                    if (firmwareFamily.isKlonHubRequired) {
                        handlePopHubOrRfidRequired(firmwareFamily);
                    } else if (!canModifyFeatures && (isDeviceAccessory || isDeviceRfid)) {
                        $scope.$parent.loadFirmwarePartial(false);
                    } else if (skipControls) {
                        $scope.$parent.loadTelematicsPartial();
                    } else {
                        loadRemotePartial();
                    }

                    AppService.setFirmwareSkipped(false);

                })
                .error(function (error) {
                    alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                })
                .finally(function () {
                });
        }

        function clearPrevVehicleSelections() {
            var v = $scope.selectedVehicle;
            v.firmwareFamilyId = '';
            v.firmwareFamilyName = '';
            v.firmwareId = '';
            v.firmwareName = '';
            $scope.$parent.userSelection.telematicId = '';
            $scope.$parent.userSelection.telematicName = '';
            $scope.$parent.userSelection.telematicImagePhone = '';
            $scope.$parent.userSelection.selectedFirmware = {};

            var p = $scope.prevSelectedVehicle;
            p.firmwareFamilyId = '';
            p.firmwareId = '';
        }

        function handleMessageBox(selectedVehicle, otherObject) {
            if (parseInt(selectedVehicle.makeId) === parseInt(otherObject.OTHER_VEHICLE_MAKE_ID) || parseInt(selectedVehicle.year) === parseInt(otherObject.OTHER_VEHICLE_YEAR) || parseInt(selectedVehicle.modelId) === parseInt(otherObject.OTHER_VEHICLE_MODEL_ID)) showModalBox();
        }

        function showModalBox() {
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/configuration/directives/trimMessageBox.directive.view.html',
                className: 'ngdialog-theme-plain submenu-margin',
                closeByDocument: false,
                showClose: false,
                controller: ['$scope', function ($scope) {
                    $scope.title = 'TRIM_MESSAGE_MODAL_TITLE';
                    $scope.message = 'TRIM_MESSAGE_MODAL_TEXT';
                    $scope.buttonTitle = 'TRIM_MESSAGE_MODAL_BUTTON';
                    $scope.event = function () {
                        $scope.confirm('ok');
                    };
                }]
            }).then(function (result) {
                // user did somthing else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }
    }
]);
