/**
 * Created by mathieurenaud on 2015-07-22.
 */
'use strict';

angular.module('core').factory('deviceIdentifier', ['Wmoapi', '_',
    function (Wmoapi, _) {

        var identifier = {};

        identifier.identify = function (serial, bootloaderVersion, idComplete, idFailed) {
            var devices, result, device;

            devices = [];

            var isBlankSerial = serial === 'FFFFFFFFFF' || !serial;

            if(isBlankSerial && bootloaderVersion) {
                Wmoapi
                    .getBootloader(bootloaderVersion)
                    .success(function (response) {
                        if (_.isEmpty(response.error)){
                            var device = new weblinkupdater.Device(serial, response);
                            devices.push(device);
                            idComplete(devices);
                        } else {
                            idFailed(response.error);
                        }

                    })
                    .catch(function(error){
                        idFailed(error);
                    });
            } else if (!isBlankSerial) {
                Wmoapi
                    .getDeviceInfo(bootloaderVersion, serial)
                    .success(function (response) {
    
                        if( !response || response.length === 0 ){
                            idFailed();
                            return;
                        }
    
                        var resultDevice = response[0].device;
                        var resultBootloader = resultDevice.bootloader;
    
                        // TODO: Put this in some factory
                        var bootloaderOptions = {
                            'supportsHighSpeed' : resultBootloader.isHighspeed,
                            'customSpeed' : resultBootloader.customSpeed,
                            'isLocked' : resultBootloader.isLocked,
                            'unlockKey' : resultBootloader.unlockKey,
                            'frameMessages' : resultBootloader.framed,
                            'i2cAllowed' : resultBootloader.i2cAllowed
                        };
    
                        var Bootloader = weblinkupdater.Bootloader;
                        var detectedBootloader = new Bootloader(resultBootloader.version, resultBootloader.moduleType, bootloaderOptions );
    
    
                        // TODO: Put this in some factory
    
                        var Device = weblinkupdater.Device;
                        var detectedDevice = new Device(serial, detectedBootloader);
    
                        detectedDevice.deviceFunction = resultDevice.deviceFunction;
                        detectedDevice.deviceFunctionId = resultDevice.deviceFunctionId;
                        detectedDevice.productID = resultDevice.productID;
                        detectedDevice.hardwareNumOfPorts = resultDevice.hardwareNumOfPorts;
                        detectedDevice.productName = resultDevice.productName;
                        detectedDevice.firmwareName = resultDevice.firmwareName;
                        detectedDevice.firmwareVersion = resultDevice.firmwareVersion;
                        detectedDevice.firmwareFullVersion = resultDevice.firmwareFullVersion;
                        detectedDevice.firmwareHasUpdate = resultDevice.firmwareHasUpdate;
                        detectedDevice.serial = serial;
                        detectedDevice.isIdatastartRemotePairingCompatible = resultDevice.isIdatastartRemotePairingCompatible;
                        detectedDevice.smallImage = resultDevice.smallImage;
                        detectedDevice.mediumImage = resultDevice.mediumImage;
                        detectedDevice.partId = resultDevice.partId;
                        detectedDevice.partName = resultDevice.partName;
                        detectedDevice.allowedServices = resultDevice.allowedServices;
                        detectedDevice.productPartOptions = resultDevice.productPartOptions;
                        detectedDevice.firmwareID = resultDevice.firmwareID;
                        detectedDevice.latestId = resultDevice.latestId;
                        detectedDevice.hasProgram = resultDevice.hasProgram;
                        detectedDevice.isInternal = resultDevice.isInternal;
                        detectedDevice.needBootloaderUpdate = resultDevice.needBootloaderUpdate;
                        detectedDevice.expectedBootloaderVersion = resultDevice.expectedBootloaderVersion;
                        detectedDevice.guideDownloadUrl = resultDevice.guideDownloadUrl;
                        detectedDevice.productGuideDownloadUrl = resultDevice.productGuideDownloadUrl;
                        detectedDevice.bootloaderBlockSize = resultBootloader.blockSize;
                        detectedDevice.isKlonHubRequired = resultDevice.isKlonHubRequired;
                        detectedDevice.partMediumImage = resultDevice.partMediumImage;
                        detectedDevice.hasLimitedVehicleCoverage = resultDevice.hasLimitedVehicleCoverage;
                        detectedDevice.trackingM = resultDevice.trackingM;

                        devices.push(detectedDevice);

                        idComplete(devices);
                    })
                    .catch(function(error){
                        idFailed(error);
                    });
            } else {
                idFailed('DETECT_NO_DEVICE');
            }

            result = {
                get ready() {
                    return false;
                },
                get devices() {
                    return devices;
                }
            };

            return result;

        };

        /**
         * Determine if device has skip-remote-configuration in productPartOption
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.supportsInternalRemotes = function(productPartOptions){
            var found =  false ;
            angular.forEach(productPartOptions  ,function(value) {

              if (value.toLowerCase() === 'support-idatastart-remote-pairing') {
                  found = true ;
              }
            });

            return found;
        };

        /**
         * Determine if device has skip-remote-configuration in productPartOption
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isSkipRemoteConfiguration = function(productPartOptions){
            var found =  false ;
            angular.forEach(productPartOptions  ,function(value) {

              if ( value.toLowerCase()  === 'skip-remote-configuration') {
                  found = true ;
              }

            });

            return found;
        };

        /**
         * Determine if device has firmware update
         * @param detectedDevice device info
         * @returns {{hasUpdate: boolean, hasBlankFirmware: boolean}} firmwareDetails
         */
        identifier.hasFirmwareUpdate = function(detectedDevice) {

            var firmwareDetails = {};
            var firmwareID = parseInt(detectedDevice.firmwareID, 10);
            var hasBlankFirmwareId = (isNaN(firmwareID) || firmwareID === 0);

            firmwareDetails.hasUpdate = detectedDevice.firmwareHasUpdate;
            firmwareDetails.hasBlankFirmware = hasBlankFirmwareId;

            return firmwareDetails;
        };

        /**
         * Determine if device.allowedServices has klon-step-2
         * @param allowedServices device.allowedServices
         * @returns {boolean}
         */
        identifier.hasKlonStep2Service = function(allowedServices){

            var hasKlonStep2 =  false ;

            angular.forEach(allowedServices, function(value) {
                if(value.name.toLowerCase() === 'klon-step-2'){
                    hasKlonStep2 = true ;
                }
            });

            return hasKlonStep2;
        };

        /**
         * Determine if detected device is allowed on the site
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isDetectedDeviceAllowed = function(productPartOptions) {

            var isAllowed = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'use-weblink2015' || value.toLowerCase() === 'use-weblink2015-no-redirect') {
                    isAllowed = true;
                }

            });

            return isAllowed;
        };

        /**
         * Determine if detected device is allowed on weblink desktop
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isDetectedDeviceAllowedInWD = function(productPartOptions) {

            var isAllowed = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'use-weblink-desktop') {
                    isAllowed = true;
                }

            });

            return isAllowed;
        };

        /**
         * Determine if detected device's firmware is dependent on main device
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isDetectedDeviceFwDependant = function(productPartOptions) {

            var isAllowed = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'fw-dependent-on-main-device') {
                    isAllowed = true;
                }

            });

            return isAllowed;
        };

        /**
         * Determine if detected device has product part option: has-flex-program-no-bill
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isDetectedDeviceHasFlexProgramNoBill = function(productPartOptions) {

            var hasFlexProgramNoBill = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'has-flex-program-no-bill') {
                    hasFlexProgramNoBill = true;
                }

            });

            return hasFlexProgramNoBill;
        };

        /**
         * Determine if detected device has product part option: vehicle-dependent-on-main-device
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isDetectedDeviceVehicleDependantOnMainDevice = function(productPartOptions) {

            var vehicleDependantOnMainDevice = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'vehicle-dependent-on-main-device') {
                    vehicleDependantOnMainDevice = true;
                }

            });

            return vehicleDependantOnMainDevice;
        };

        /**
         * Determine if detected device has product part option: use-acc-rfid
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.doesDetectedDeviceUseAccRfid = function(productPartOptions) {

            var useAccRfid = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'use-acc-rfid') {
                    useAccRfid = true;
                }

            });

            return useAccRfid;
        };
        /**
         * Determine if detected device has an lcd screen
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.doesDetectedDeviceHaveLcdScreen = function(productPartOptions) {

            var hasLcdScreen = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'has-lcd-screen') {
                    hasLcdScreen = true;
                }

            });

            return hasLcdScreen;
        };

        /**
         * DeviceFunction ID
         * 1 = Accessories
         * 4 = Expansion Pack
         * 15 = 1WAY FM transmitter
         * 17 = 2WAY FM transmitter
         * 19 = 1WAY LORA transmitter
         * 21 = 2WAY LORA transmitter
         * 23 = FLEX LORA transmitter
         * @param functionId device function id
         * @returns {boolean}
         */
        identifier.isDeviceAccessory = function (functionId) {
            functionId = parseInt(functionId);
            var accessoryFunctionIds = [1, 4, 15, 17, 19, 21, 23];
            return accessoryFunctionIds.includes(functionId);
        };

        /**
         * Determine if detected device is RFID
         * DeviceFunction ID 37 = RFID interface
         * @param functionId device function id
         * @returns {boolean}
         */
        identifier.isDeviceRfid = function (functionId) {
            functionId = parseInt(functionId);
            return functionId === 37;
        };

        /**
         * Determine if detected device is antenna on the site
         * DeviceFunction ID 8=RF Decoder (Antenna) | 1=Accessories
         * id's 8 & 1 will have the same flow and actions
         * @param functionId device function id
         * @returns {boolean}
         */
        identifier.isDeviceAntenna = function(functionId){

            var isAntenna =  false;
            functionId = parseInt(functionId);

            if(functionId === 8 || functionId === 1) {
                isAntenna = true;
            }

            return isAntenna;
        };
        /**
         * Determine if detected device is a Hub
         *
         * @param partId device part id (Product Part ID: 963 - WL-HUB)
         * @returns {boolean}
         */
        identifier.isDeviceHub = function(partId){

            return parseInt(partId) === 963;

        };
        /**
         * Determine if detected device is capable to flash firmware with KLON
         * DeviceFunction ID = Name
         * 3 = Remote Starter
         * 5 = Interface Cartridge
         * 6 = Interface Module
         * 9 = Remote Starter
         * 10 = Telematics
         * @param functionId device function id
         * @returns {boolean}
         */
        identifier.isDeviceKlonnable = function(functionId){

            var klonnable = [3, 5, 6, 9, 10];
            var found = false;

            functionId = parseInt(functionId);
            angular.forEach(klonnable, function (value) {
                if (value === functionId) {
                    found = true;
                }
            });
            return found;
        };

        identifier.isIdatastartRemotePairingCompatible = function(value) {

            if (angular.isDefined(value) && value) {
                return true;
            }

            return false;
        };

        /**
         * Determine if flex detected device is activated
         * @param packageObject device package details
         * @returns {boolean}
         */
        identifier.isFlexActivated = function(packageObject){

            var isActivated =  false;

            if (!packageObject || !packageObject.package) {
                isActivated = false;
            }
            else {
                var packageId = packageObject.package.packageId;
                isActivated = packageId ? !(packageId === '' || packageId === 0) : false;
            }

            return isActivated;
        };

        /**
         * Determine if detected device has product part option: is-classic-device / is-classic-rs-device
         * Flashing flow will shows classic install options (protocols) instead of remotes & telematics
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isDetectedDeviceIsClassic = function (productPartOptions) {

            var isClassic = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'is-classic-device' || value.toLowerCase() === 'is-classic-rs-device') {
                    isClassic = true;
                }

            });

            return isClassic;
        };

        /**
         * Determine if detected device has product part option:  is-classic-rs-device
         * Classic install options page will show all compatible protocols instead
         * @param productPartOptions device.productPartOptions
         * @returns {boolean}
         */
        identifier.isDetectedDeviceIsClassicRS = function (productPartOptions) {

            var isClassic = false;
            angular.forEach(productPartOptions, function (value) {

                if (value.toLowerCase() === 'is-classic-rs-device') {
                    isClassic = true;
                }

            });

            return isClassic;
        };

        identifier.isFlashByVehicleAllowed = function (allowedServices) {
            var found = false;
            angular.forEach(allowedServices, function (value) {
                if (value.name.toLowerCase() === 'flash-module-by-vehicle') {
                    found = true;
                }
            });
            return found;
        };

        identifier.isConfigureModuleAllowed = function (allowedServices) {
            var found = false;
            angular.forEach(allowedServices, function (value) {
                if (value.name.toLowerCase() === 'configure-module') {
                    found = true;
                }
            });
            return found;
        };

        identifier.hasRsuGuides = function (rsuDevice) {
            return rsuDevice.Product.rsuBrandName === 'Compustar';
        };

        return identifier;
    }]
);
