(function() {
    'use strict';

    angular
        .module('core')
        .directive('internalRemotes', InternalRemotes);

        function InternalRemotes() {
            return {
                restrict: 'E',
                scope: {
                  // stuff in scope
                  selectedRemotes: '=',
                  responseData: '=',
                  userSelection: '=',
                  request: '=',
                  loadTelematicsPartial: '&',
                    loadFirmwarePartial: '&'
                },
                templateUrl: 'modules/core/views/directives-partial/internalRemotes.client.directive.view.html',
                controller: 'PartialsInternalRemotesController',
                controllerAs: 'ctrl'
            };
        }
})();
