/**
 * Created by rafikgharsalli on 2016-01-25.
 */

'use strict';
angular.module('core').directive('imageSection', ['dataService',
    function(dataService){

        function link ($scope, elem, attr) {
            $scope.data = dataService;
        }
        return {
            scope:{
                wire:'='
            },
            link: link,
            restrict: 'E',
            templateUrl:'modules/core/views/directives-partial/image.section.html'
        };
    }
]);
