'use strict';

angular.module('core').controller('PartialsFeaturesSubmenuController', ['$scope',
	function($scope) {

        $scope.loadDeviceFeature = function(index, item){
            $scope.submenu.selectedItem = index;
        };

	}
]);