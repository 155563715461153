/**
 * Created by rafikgharsalli on 2016-05-02.
 */
'use strict';

angular.module('core').filter('connectorlocation',[
    function() {

        return function (input) {
            var connector = '' ;

            if (input) {

                if (input.connector_color || input.connector_pins > 0) {
                    connector = input.connector_color ? input.connector_color : '';
                    connector += input.connector_pins > 0 ? (connector ? ' ' : '') + input.connector_pins + '-pin plug' : '';
                    connector += input.connector_name.indexOf('UNNAMED') === 0 || input.connector_name === '' ? '' : (connector ? ' ' : '') + '(' + input.connector_name + ')';
                }
            }

            return connector;

        };
    }
]);
