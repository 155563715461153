/**
 * Created by rafikgharsalli on 2016-04-25.
 */

'use strict';

angular.module('core').controller('PartialsConfigurationPackagesController', ['$scope', 'Wmoapi', 'Request', 'configuration', 'DeviceService', '$rootScope', 'alertBar',
    function($scope, Wmoapi, Request, configurationFactory, DeviceService, $rootScope, alertBar) {

        $scope.initPackages = function () {
            $scope.managePageNavigation();
            $scope.manageContent();
        };

        $scope.managePageNavigation = function () {
            var selectedPackage = $scope.$parent.userSelection.packages.packageId;
            var pId = (selectedPackage) ? parseInt(selectedPackage) : 0;
            $scope.hidePageNaviNextBtn = (pId === 0);
        };

        $scope.manageContent = function () {

            var selectedPackage = $scope.$parent.userSelection.packages.packageId;
            var pId = (selectedPackage) ? parseInt(selectedPackage) : 0;

            if (pId === 0) {
                $scope.getPackages();
            } else {
                $scope.manageSubmenu();
            }
        };

        $scope.manageSubmenu = function () {
            $scope.$parent.submenu.items = configurationFactory.getSubmenuItems();
            $scope.$parent.submenu.selectedItem = configurationFactory.getSubmenuPackage();
        };

        $scope.getPackages = function () {
            var requestId = $scope.$parent.request.id;
            var detectedDevice = DeviceService.getFlashableDevice();
            var request = Request.getRequestDevice();

            $rootScope.isGlobalLoading = true;

            if (requestId === request.id &&
                detectedDevice.bootloader.version === request.bootloader.version &&
                detectedDevice.serial === request.serial) {
                    loadPackages();
            } else {

                Wmoapi
                    .createRequest(detectedDevice.bootloader.version, detectedDevice.serial)
                    .success(function (response) {
                        requestId = response.requestId;

                        //TODO: create reset function
                        Request.clearRequestDevice();
                        $scope.$parent.request.configModuleRequestId = '';
                        $scope.$parent.request.configRemotesRequestId = '';
                        $scope.$parent.responseData.currentFirmware = '';

                        $scope.$parent.request.id = requestId;
                        Request.setRequestDevice(requestId, detectedDevice.bootloader, detectedDevice.serial);
                        loadPackages();
                    })
                    .error(function (error) {
                        $scope.$parent.showErrorMessageFromServer('CONFIGURATION_PACKAGES_ERROR_CREATE_REQUEST', error);
                        $scope.$parent.loadDevicePartial(false);
                    });
            }
        };

        function loadPackages() {

            var requestId = $scope.$parent.request.id;
            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getPackages(requestId)
                .success(function(response) {
                    $scope.packagesList = response;
                    $scope.$parent.responseData.packages.packageClassGrid = response.length ;
                    $scope.$parent.responseData.packages.packagesList = response;
                    $scope.manageSubmenu();
                })
                .error(function(error) {
                    alertBar.show( 'error', 'CONFIGURATION_PACKAGES_ERROR_GET_PACKAGES' );
                })
                .finally(function() {
                    $rootScope.isGlobalLoading = false;
                });
        }

        $scope.setPackage = function (requestId, packageId){
            Wmoapi
                .setPackage(requestId, packageId)
                .success(function () {
                    alertBar.hide();
                    $scope.$parent.loadConfigurationPartial('Makes', true);
                })
                .error(function (error) {
                    alertBar.show( 'error', 'CONFIGURATION_ERROR_SET_PACKAGE' );
                });
        };

        $scope.loadVehicleMakePage = function (id) {
            $scope.$parent.userSelection.packages.packageId = id;
            $scope.setPackage($scope.$parent.request.id, id);
        };

    }
]);
