/**
 * Created by rafikgharsalli on 2017-08-29.
 */
(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsOemRemoteStartModalController', PartialsOemRemoteStartModalController);

    PartialsOemRemoteStartModalController.$inject = ['$scope', '$rootScope'];

    function PartialsOemRemoteStartModalController($scope, $rootScope) {
        var vm = this;

        $scope.redirectToRemotes = function() {
            $rootScope.globalSkipStep('internal-remotes');

        };
    }

})();
