'use strict';

angular.module('core').factory('translateService', ['$translate',
    function ($translate) {

        return {

            getCurrentLanguage: function () {
                return $translate.use();
            },
            setCurrentLanguage: function (newCurrentLanguage) {
                $translate.use(newCurrentLanguage);
            },
            translate: function (key) {
                return $translate.instant(key);
            }
        };

    }

]);
