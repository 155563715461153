/**
 * Created by rafikgharsalli on 2016-01-19.
 */
'use strict';


angular.module('core').directive('imageThumbnail', ['dataService','Wiringapi','$rootScope',
    function(dataService,$rootScope){
        function link ($scope) {
            $scope.data = dataService;
        }
        return {
            scope:{
                images:'=',
                wire:'='
            },
            link: link,
            restrict: 'E',
            templateUrl:'modules/core/views/directives-partial/image.thumbnail.html',
            controller: ['$scope', function($scope) {
                $scope.getImageSection = function(imageWire, $index, wire) {
                    if (dataService.activeTab !== $index) {
                        var imageId = imageWire.image_id;
                        var fileName = imageWire.file_name;
                        var vehicleId = dataService.vehicleId;
                        var activeTab = $index;
                        var wireId = dataService.isGalleryMode ? 0 : wire.wire_id;
                        var connectorId = dataService.isGalleryMode ? 0: wire.connector_id;
                        var componentId = dataService.isGalleryMode ? 0: wire.component_id;
                        dataService.activeTab = $index;
                        $rootScope.isGlobalLoading = true;
                        dataService.wireImage = imageWire;
                        dataService.validateImage(vehicleId,dataService.isResized,fileName,imageId,wireId,connectorId,componentId,activeTab);
                    }else {
                        dataService.activeTab = -1;
                    }

                };

            }]
        };
    }
]);
