/**
 * Created by rafikgharsalli on 2016-01-28.
 */
'use strict';
angular.module('core').directive('imageInfo', ['dataService',
    function(dataService){
        function link ($scope) {
            $scope.data = dataService;
        }
        return {
            scope:{
                wire:'='
            },
            link: link,
            restrict: 'AE',
            templateUrl:'modules/core/views/directives-partial/image.info.html',
            controller: ['$scope', function($scope) {
                $scope.hideDuplicatedConnector = function(tags,connectorId) {
                    var found = false;
                    if(tags && connectorId) {
                        angular.forEach(tags, function (value) {
                            if (!found) {
                                if( value.tag_type === 'CONNECTOR' && value.connector_id === connectorId){
                                    found =  true;
                                }
                            }

                        });
                    }
                    return found;
                };
            }]
        };
    }
]);