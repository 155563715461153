(function() {
    'use strict';

    angular
        .module('core')
        .directive('firmwareSelection', FirmwareSelection);

        function FirmwareSelection() {
            return {
                restrict: 'E',
                scope: {
                    loadControlsPartial: '&',
                    loadDevicePartial: '&',
                    loadFeaturePartial: '&',
                    loadOemRemoteStartPartial: '&',
                },
                bindToController: {
                    request: '=',
                    responseData: '=',
                    skipControls: '=',
                    userSelection: '=',
                    vehicleSelection: '=',
                    prevVehicleSelection: '='
                },
                templateUrl: 'modules/core/views/partials/firmware/directives/firmwareselection.client.directive.view.html',
                controller: 'PartialsFirmwareController',
                controllerAs: 'ctrl'
            };
        }
})();
