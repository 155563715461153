'use strict';


angular.module('core').factory('FacebookService', ['$rootScope',
    function($rootScope){
        return {
            getLoginStatus:function () {
                FB.getLoginStatus(function (response) {
                    $rootScope.$broadcast('fb_statusChange', {'status':response.status});
                }, true);
            },
            login: function (forceLogin) {
                return new Promise(function(resolve, reject) {
                    FB.getLoginStatus(function (response) {
                        if(response.authResponse && !forceLogin) {
                            resolve(response);
                        } else {
                            FB.login(function (response) {
                                if (response.authResponse) {
                                    resolve(response);
                                }
                            },{scope: 'email', auth_type: 'rerequest'});
                        }
                    }, true);
                });
            },
            logout:function () {
                FB.logout(function (response) {
                    if (response) {
                        $rootScope.$broadcast('fb_logout_succeded');
                    } else {
                        $rootScope.$broadcast('fb_logout_failed');
                    }
                });
            },
            unsubscribe:function () {
                FB.api('/me/permissions', 'DELETE', function (response) {
                    $rootScope.$broadcast('fb_get_login_status');
                });
            }
        };
    }
]);
