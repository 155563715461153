/**
 * Created by rafikgharsalli on 2018-01-24.
 */
(function() {
    'use strict';
    angular
        .module('core')
        .controller('PartialsAccessoriesContainerController', PartialsAccessoriesContainerController);

    PartialsAccessoriesContainerController.$inject = ['$scope', 'ngDialog'];

    function PartialsAccessoriesContainerController($scope, ngDialog) {
        var vm = this;
        vm.openModalBox = openModalBox;

        function openModalBox(){

            ngDialog.openConfirm({
                template: 'modules/core/views/directives-partial/sirenModalBox.html',
                className: 'ngdialog-theme-plain',
                controller: 'PartialsSirenModalController as ctrl',
                closeByDocument: false,
                showClose: true,
                scope: $scope
            }).then(function (result) {
                console.log('result', result);
                // user did somthing else
            }, function (error) {
                // user closed dialog - do nothing
                console.log('error', error);
            });

        }




    }

})();
