(function() {
    'use strict';

    angular
        .module('core')
        .directive('remotesHelp', RemotesHelp);

        function RemotesHelp() {
            return {
                restrict: 'E',
                scope: {
                },
                templateUrl: 'modules/core/views/directives-partial/remoteshelp.client.directive.view.html',
            };
        }
})();
