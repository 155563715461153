'use strict';
angular.module('core').service('keyreading', ['wu', 'Wmoapi', '$q', '$timeout', '$rootScope', '_',
    function(wu, Wmoapi, $q, $timeout, $rootScope, _){

        var keyreader = this;
        keyreader.requestId = '';
        keyreader.hubSerial = '';
        keyreader.subSteps = [];
        keyreader.totalLoops = 0;
        keyreader.currentLoop = 0;
        keyreader.currentSubStepCount = 0;
        keyreader.currentSubStep = 0;
        keyreader.totalSubStepCount = 0;
        keyreader.progressPrivate = 0;

        Object.defineProperty(keyreader, 'progress', {
            get () {
                return keyreader.progressPrivate;
            },
            set (newValue) {
                keyreader.progressPrivate = Math.floor(newValue);
                $rootScope.$broadcast('keyreadingProgress', keyreader.progressPrivate);
            }
        });

        var stopSecureSession = function () {
            return Wmoapi.stopSecureSession(keyreader.requestId).then(
                function(result) {
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponse).then(function(hubResponse){
                return Wmoapi.stopSecureSessionResponse(keyreader.requestId, hubResponse);
            }).then(function (result){
                switch(result.data.status){
                    case 0:
                        keyreader.progress = 100;
                        return result;
                    case 10:
                        console.error('busy');
                        return $q.reject('KEYREADING_ERROR_SETMODE_BUSY');
                    default:
                        console.error('secure session has not stopped');
                        return $q.reject();
                }
            });
        };

        var postValidateKey = function () {
            return Wmoapi.getWeblinkHubPostValidateKeyFrame(keyreader.requestId).then(
                function(result) {
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(hubResponse){
                return Wmoapi.getWeblinkHubPostValidateKeyResponse(keyreader.requestId, hubResponse);
            }).then(function(result){
                switch(result.data.status){
                    case 1:
                        keyreader.progress = 100;
                        return true;
                    case 2:
                        console.error('key not detected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_MISSING');
                    case 3:
                        console.error('invalid key detected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_INVALID');
                    case 4:
                        console.error('invalid sequence');
                        return $q.reject();
                    case 6:
                        console.error('Power supply not connected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_POWER');
                    case 256:
                        console.error('Key bricked');
                        return $q.reject('KEYREADING_ERROR_KEY_BRICKED');
                    default:
                        console.error('postValidateKey failed');
                        return $q.reject();
                }
            });
        };
        var preValidateKey = function () {
            return Wmoapi.getWeblinkHubPreValidateKeyFrame(keyreader.requestId).then(
                function(result) {
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(hubResponse){
                return Wmoapi.getWeblinkHubPreValidateKeyResponse(keyreader.requestId, hubResponse);
            }).then(function(result){
                switch(result.data.status){
                    case 1:
                        keyreader.progress = 100;
                        return true;
                    case 2:
                        console.error('key not detected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_MISSING');
                    case 3:
                        console.error('invalid key detected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_INVALID');
                    case 4:
                        console.error('invalid sequence');
                        return $q.reject();
                    case 6:
                        console.error('Power supply not connected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_POWER');
                    case 32:
                        console.error('key requires reading again');
                        return getSubStepCount();
                    default:
                        console.error('preValidateKey failed');
                        return $q.reject();
                }
            });
        };

        var prepareReadingsAfterKeyReading = function () {

            var promiseWhile = function () {
                var whilst = function (result) {

                    $rootScope.$broadcast('keyreadingWaitTime', result.data.waitTime || 0);

                    if (result.data.ready) {
                        keyreader.progress += 10;
                        return $q.when(result);
                    }

                    return $timeout(function (){}, result.data.waitTime > 0 ? Math.min(result.data.waitTime, 60) * 1000: 0).then(
                        function () {
                            return Wmoapi.getKlonHubStep2(keyreader.requestId).then(whilst);
                        }
                    );
                };
                return whilst({data: {waitTime: 0}}).then(function (){
                    if (keyreader.totalLoops === keyreader.currentLoop){
                        return postValidateKey();
                    } else {
                        keyreader.currentLoop += 1;
                        keyreader.currentSubStepCount = parseInt(keyreader.subSteps[keyreader.currentLoop - 1].end, 10);
                        keyreader.currentSubStep = parseInt(keyreader.subSteps[keyreader.currentLoop - 1].start, 10);
                        return startProcedure();

                    }
                });
            };
            return promiseWhile();
        };

        var checkIfKeyBricked = function () {
            return Wmoapi.getWeblinkHubIsKeyBricked(keyreader.requestId).then(
                function (result){
                    return result.data.isKeyBricked;
                }
            ).then(function(isKeyBricked){
                return isKeyBricked ? $q.reject('KEYREADING_ERROR_KEY_BRICKED') : (keyreader.currentSubStepCount === (keyreader.currentSubStep - 1) ? prepareReadingsAfterKeyReading()  : startProcedure());
            });
        };

        var readMultiplePages = function (procedureInfo) {

            return Wmoapi.getListReadResultFrame(keyreader.requestId).then(
                function(result){
                    return result.data.frames;
                }
            ).then(function(frames) {
                return wu.sendFramesAndGetResponses(frames, keyreader.progress, 30 / keyreader.totalSubStepCount);
            }).then(function (hubResponses){
                return Wmoapi.getListResultData(keyreader.requestId, hubResponses);
            }).then(function(result){

                for (var i = 0; i <= result.data.result.length; ++i){
                    if(result.data.result[i] !== 1){
                        console.error('Key reading failed, try again');
                        return $q.reject();
                    }
                }

                keyreader.progress += (30 / keyreader.totalSubStepCount);

            }).then(function() {
                keyreader.currentSubStep += 1;
                return keyreader.currentLoop === 1 && (keyreader.currentSubStep - 1) === 1 ? checkIfKeyBricked() : (keyreader.currentSubStepCount === (keyreader.currentSubStep - 1) ? prepareReadingsAfterKeyReading()  : startProcedure());
            });
        };

        var pollProcedure = function(response) {
            var targetTime = Date.now() + response.data.duration;
            var hubResponse = '';
            return Wmoapi.getWeblinkHubPollProcedureStatusFrame(keyreader.requestId).then(
                function(result){
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(response){
                hubResponse = response;
                return Wmoapi.getWeblinkHubPollProcedureStatusResponse(keyreader.requestId, hubResponse);
            }).then(function(result){
                var promiseWhile = function () {
                    var whilst = function (result) {
                        return $timeout(function (){}, 1000).then(function(){
                            switch (result.data.status) {
                                case 1:
                                    keyreader.progress += (10 / keyreader.totalSubStepCount);
                                    return $q.when(result);
                                case 6:
                                    console.error('Power supply not connected');
                                    return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_POWER');
                                case 32:
                                    var promise;
                                    if ( targetTime > Date.now() ) {
                                        promise =  Wmoapi.getWeblinkHubPollProcedureStatusFrame(keyreader.requestId).then(
                                            function (result) {
                                                return result.data.frame;
                                            }
                                        ).then(wu.sendFrameAndGetResponseKeepConnected).then(function (response) {
                                            hubResponse = response;
                                            return Wmoapi.getWeblinkHubPollProcedureStatusResponse(keyreader.requestId, hubResponse);
                                        }).then(whilst);
                                    } else {
                                        promise = $q.reject();
                                    }
                                    return promise;
                                case 34:
                                    console.error('Invalid authentication data');
                                    return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_INVALID_DATA');
                                default:
                                    return $q.reject();
                            }
                        });
                    };
                    return whilst(result);
                };
                return promiseWhile();
            }).then(readMultiplePages);
        };

        function startProcedure () {
            var hubResponse = '';
            return Wmoapi.getWeblinkHubStartProcedureFrame(keyreader.requestId, keyreader.currentSubStep).then(
                function(result){
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(response){
                hubResponse = response;
                return Wmoapi.getWeblinkHubStartProcedureFrameResponse(keyreader.requestId, hubResponse);
            }).then(function(result){
                var promiseWhile = function () {
                    var whilst = function (result) {
                        return $timeout(function () {}, 1000).then(function () {
                            if (result.data.status === 1) {
                                keyreader.progress += (10 / keyreader.totalSubStepCount);
                                return $q.when(result);
                            } else if (result.data.status === 32) {
                                return Wmoapi.getWeblinkHubStartProcedureFrame(keyreader.requestId).then(
                                    function (result) {
                                        return result.data.frame;
                                    }
                                ).then(wu.sendFrameAndGetResponseKeepConnected).then(function (response) {
                                    hubResponse = response;
                                    return Wmoapi.getWeblinkHubStartProcedureFrameResponse(keyreader.requestId, hubResponse);
                                }).then(whilst);
                            } else if (result.data.status === 6) {
                                console.error('Power supply not connected');
                                return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_POWER');
                            } else {
                                return $q.reject();
                            }
                        });
                    };
                    return whilst(result);
                };
                return promiseWhile();
            }).then(pollProcedure);
        }

       function prepareReadings () {
            var promiseWhile = function () {
                var whilst = function (result) {
                    return $timeout(function (){
                        $rootScope.$broadcast('keyreadingWaitTime', result.data.waitTime || 0);
                        }, result.data.waitTime > 0 ? result.data.waitTime * 1000: 0).then(function(){
                        if (result.data.ready) {
                            keyreader.progress += 10;
                            return $q.when(result);
                        }
                        return Wmoapi.getKlonHubStep1(keyreader.requestId).then(whilst);
                    });
                };
                return whilst({data: {waitTime:1, ready: false}}).then(startProcedure);
            };
            return promiseWhile();
        }

        function getSubStepCount () {
            return Wmoapi.getWeblinkHubSubStepCount(keyreader.requestId).then(
                function (result){
                    keyreader.totalSubStepCount = _.reduce(result.data.steps, function (sum, n){
                        return sum + n.end;
                    }, 0);
                    keyreader.subSteps = result.data.steps;
                    keyreader.totalLoops = result.data.steps.length;
                    keyreader.currentSubStepCount = parseInt(result.data.steps[0].end, 10);
                    keyreader.currentSubStep = 1;
                    keyreader.currentLoop = 1;
                    return 1;
                }
            ).then(prepareReadings);
        }

        var isKeyCracked = function () {
            return Wmoapi.getWeblinkHubIsKeyCracked(keyreader.requestId).then(
                function (result){
                    return result.data.isKeyAlreadyUsed;
                }
            ).then(function(isCracked){
                return isCracked ? preValidateKey() : getSubStepCount();
            });
        };

        var getKeyId = function (valid){
            return Wmoapi.getWeblinkHubReadKeyIdFrame(keyreader.requestId).then(
              function(result){
                  return result.data.frame;
              }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(hubResponse){
                return Wmoapi.getWeblinkHubKeyId(keyreader.requestId, hubResponse);
            }).then(function(result){
                switch(result.data.status){
                    case 1:
                        keyreader.progress += 10;
                        return result.data.keyId;
                    case 2:
                        console.error('key not detected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_MISSING');
                    case 3:
                        console.error('key not inserted properly');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_INVALID');
                    case 4:
                        console.error('Wrong transponder detected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_INVALID');
                    case 6:
                        console.error('Power supply not connected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_POWER');
                    case 257:
                        console.error('Klon not available');
                        return $q.reject('KEYREADING_ERROR_KLON_UNAVAILABLE');
                    case 1025:
                        console.error('incorrect transponder id detected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_INVALID');
                    default:
                        console.error('geyKeyId not valid');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_KEY_MISSING');
                }
            }).then(isKeyCracked);
        };

        var setMode = function () {
            return Wmoapi.getWeblinkHubModeFrame(keyreader.requestId).then(
                function(result) {
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(hubResponse){
                return Wmoapi.validateWeblinkHubModeFrameResponse(keyreader.requestId, hubResponse);
            }).then(function(result){
                switch(result.data.status){
                    case 0:
                        keyreader.progress = 10;
                        return result.data.valid;
                    case 6:
                        console.error('Power supply not connected');
                        return $q.reject('KEYREADING_STEP2_ERROR_MESSAGE_POWER');
                    case 10:
                        console.error('busy');
                        return $q.reject('KEYREADING_ERROR_SETMODE_BUSY');
                    default:
                        console.error('setMode not valid');
                        return $q.reject();
                }
            }).then(getKeyId);
        };

        var authenticate = function () {
            return Wmoapi.authenticateStep1(keyreader.requestId).then(
                function(result) {
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(hubResponse){
                return Wmoapi.authenticateStep2(keyreader.requestId, hubResponse);
            }).then (function(result){
                if(result.data.status === 1){
                    keyreader.progress = 3;
                    return Wmoapi.authenticateStep3(keyreader.requestId);
                } else {
                    console.error('auth step2 not valid');
                    return $q.reject();
                }
            }).then(function(result) {
                return result.data.frame;
            }).then(wu.sendFrameAndGetResponseKeepConnected).then(function(hubResponse){
                return Wmoapi.authenticateStep4(keyreader.requestId, hubResponse);
            }).then(function (result) {
                if(result.data.status === 1){
                    keyreader.progress = 4;
                    return result;
                } else {
                    console.error('auth step4 not valid');
                    return $q.reject();
                }
            });
        };

        var startSecureSession = function () {
            return Wmoapi.startSecureSession(keyreader.requestId, keyreader.hubSerial).then(
                function(result) {
                    return result.data.frame;
                }
            ).then(wu.sendFrameAndGetResponseKeepConnected).then(function(hubResponse){
                return Wmoapi.startSecureSessionResponse(keyreader.requestId, hubResponse);
            }).then(function (result){
                keyreader.progress = 2;
            }).then(authenticate);
        };



        keyreader.readKey = function(request_id) {
            keyreader.requestId = request_id;
            keyreader.progress = 5;
            return setMode();
        };

        keyreader.readKeyEncrypted = function(request_id, hub_serial) {
            keyreader.requestId = request_id;
            keyreader.hubSerial = hub_serial;
            keyreader.progress = 0;
            return startSecureSession().then(setMode);
        };

        keyreader.stopSecureSession = stopSecureSession;

        return keyreader;
    }]
);
