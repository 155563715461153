/**
 * Created by rafikgharsalli on 2015-08-28.
 */
'use strict';

angular.module('core').filter('time',['timeService',
    function(timeService) {
        return function (value, unit, format, isPadded) {
            return timeService.time(value, unit, format, isPadded);
        };

    }
]);
