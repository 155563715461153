'use strict';

angular.module('core').factory('wu', ['$q','AppService', '$rootScope', '_',
    function ($q, AppService, $rootScope, _) {
        var wu = weblinkupdater.weblinkupdater;
        var activeXObj = wu.weblinkActiveXObject();
        var wu_deviceInteractionImpl = wu.deviceInteractionImpl(activeXObj);
        var devMode = AppService.isDemoMode();

        var wu_functions = {};

        wu_functions.getAuthKey = function(){
            return wu.getAuthKey(wu_deviceInteractionImpl);
        };

        wu_functions.setAuthKey = function (key) {
            return wu.setAuthKey(wu_deviceInteractionImpl, key);
        };

        wu_functions.restartHub = function (device, callback, errorHandler) {

            return wu.quitBootloader(wu, wu_deviceInteractionImpl, device,
                callback,
                function (error) {
                    console.error('writeWeblinkHubCommand error:', error);
                    errorHandler();
                }
            );
        };


        wu_functions.getRebootFunction = function (frame, callback) {
            var frameProvider = {
                provideFrame: function (callback) {
                    callback(frame, {consoleLog: devMode, keepOffAfter: 4000, readResponse: false, writeAfterWait: 50});
                }
            };
            var reboot = wu.writeWeblinkHubCommand(wu, wu_deviceInteractionImpl, frameProvider,
                callback,
                function (error) {
                    console.error('writeWeblinkHubCommand error:', error);
                }
            );

            return reboot;
        };

        wu_functions.sendFrameAndGetResponseKeepConnected = function (frame) {
            return wu_functions.sendFrameAndGetResponse(frame, true);
        };

        wu_functions.sendFrameAndGetResponse = function(frame, keepConnected){
            if (keepConnected === undefined) {
                keepConnected = false;
            }
            var deferred = $q.defer();
            var frameProvider = {
                provideFrame: function (callback) {
                    callback(frame, {consoleLog: devMode, updaterPollTimeout: 7500, 'keepConnected': keepConnected});
                }
            };
            var hubCommand = wu.writeWeblinkHubCommand(wu, wu_deviceInteractionImpl, frameProvider,
                function(success) {
                    deferred.resolve(hubCommand.response);
                },
                function(error) {
                    // error
                    console.error(error);
                    deferred.reject(error);
                }
            );
            hubCommand.start();
            return deferred.promise;
        };

        wu_functions.sendFramesAndGetResponses = function(pages, progress, increment){
            var totalPages = pages.length;
            var currentPage = 1;
            var deferred = $q.defer();
            var frameProvider = {
                provideFrame: function (callback) {

                    var options = {'keepConnected': true, 'consoleLog': devMode};

                    if (currentPage <= totalPages) {
                        callback(pages[currentPage - 1], options);
                    } else {
                        callback(null, options);
                    }

                }
            };

            var responseProcessing = {
                processResponse : function (response, onComplete, onError){
                    var processResponseResult = {
                        'ready': true,
                        'error': null
                    };

                    currentPage++;
                    progress += increment/totalPages;
                    $rootScope.$broadcast('keyreadingProgress', progress);
                    $rootScope.$apply();
                    return processResponseResult;
                }
            };
            var hubCommand = wu.chainWeblinkHubCommands(wu, wu_deviceInteractionImpl, frameProvider, responseProcessing,
                function(success) {
                    deferred.resolve(hubCommand.responses);
                },
                function(error) {
                    // error
                    deferred.reject(error);
                }
            );
            hubCommand.start();
            return deferred.promise;
        };

        /**
         *
         * @param device object detected device
         * @param firmwareDownload to be flashed
         * @returns {*} promise
         */
        wu_functions.flashDevice = function(device, firmwareDownload, isRsu) {
            var deferred = $q.defer();
            var blockSize = device.bootloaderBlockSize,
                encCode = firmwareDownload.body.code,
                codeSize = firmwareDownload.body.codeSize,
                expectedBootloaderVersion = null;

            
            if(firmwareDownload.expectedBootloaderVersion) {
                expectedBootloaderVersion = firmwareDownload.expectedBootloaderVersion;
            }

            var Code = weblinkupdater.Code;
            var code = new Code(encCode, codeSize, blockSize, expectedBootloaderVersion);

            //Set flashing delays for RSU
            if(isRsu) {
                if(firmwareDownload && firmwareDownload.body) {
                    var downloadBody = firmwareDownload.body;
                    if(downloadBody.delayAfterFlash) {
                        code.delayAfterFlash = downloadBody.delayAfterFlash;
                    }
                    if(downloadBody.cycleAfterFlash && downloadBody.cycleAfterFlash.length === 2) {
                        code.delayCycleOff = downloadBody.cycleAfterFlash[0];
                        code.delayCycleOn = downloadBody.cycleAfterFlash[1];
                    }
                }
            }

            wu.mockEnabled = devMode;
            var result;

            try {

                result = wu.flash(wu,
                    wu_deviceInteractionImpl,
                    {'provideCode': function (provideCode) { provideCode(code);}},
                    device,
                    function(success) {
                        // success
                        deferred.resolve(success);
                    },
                    function(error) {
                        // error
                        deferred.reject(error);
                    },
                    function(progress) {
                        // progress
                        deferred.notify(progress);
                    });
            }

            catch(ea) {
                deferred.reject(ea);
            }

            result.start();
            return deferred.promise;
        };

        /**
         *
         * @param device object detected device
         * @param firmwareDownload to be flashed
         * @returns {*} promise
         */
        wu_functions.flashImage = function(device, firmwareDownload) {
            var deferred = $q.defer();
            var blockSize = 2048;
            var Code = weblinkupdater.Code;
            var code = new Code(firmwareDownload.body.code, 0, blockSize);

            wu.mockEnabled = devMode;
            var result;

            try {

                result = wu.flashImage(wu,
                    wu_deviceInteractionImpl,
                    {'provideCode': function (callback) { callback(code);}},
                    device,
                    function(success) {
                        // success
                        deferred.resolve(success);
                    },
                    function(error) {
                        // error
                        deferred.reject(error);
                    },
                    function(progress) {
                        // progress
                        deferred.notify(progress);
                    });
            }

            catch(ea) {
                deferred.reject(ea);
            }

            result.start();
            return deferred.promise;
        };

        /**
         *
         * @param device object detected device
         * @param eepromPages array ['0F', '10']
         * @param onReadModuleComplete function on complete
         * @param onReadModuleError function on error
         * @returns {*} weblinkupdater readConfiguration
         */
        wu_functions.getReadModuleResult = function(device, eepromPages, onReadModuleComplete, onReadModuleError){
            var configProvider = {
                'provideConfig': function (callback) {
                    var config = weblinkupdater.Configuration.blankFromPageAddresses(eepromPages);
                    callback(config);
                }
            };
            wu.mockEnabled = devMode;
            return wu.readConfiguration(wu, wu_deviceInteractionImpl, configProvider,
                device, onReadModuleComplete, onReadModuleError);

        };

        /**
         *
         * @param device object detected device
         * @param eepromPages array ['0F', '10']
         * @param onReadModuleComplete function on complete
         * @param onReadModuleError function on error
         * @returns {*} weblinkupdater readConfiguration
         */
        wu_functions.getRsuConfig = function(device, eepromPages, onReadModuleComplete, onReadModuleError){
            var configProvider = {
                'provideConfig': function (callback) {
                    var config = weblinkupdater.Configuration.blankRSUFromPageAddresses(eepromPages);
                    callback(config);
                }
            };
            wu.mockEnabled = devMode;
            return wu.readConfiguration(wu, wu_deviceInteractionImpl, configProvider,
                device, onReadModuleComplete, onReadModuleError);

        };
        /**
         *
         * @param device object detected device
         * @param logName name of log to read
         * @param onReadHlsLogComplete function on complete
         * @param onReadHlsLogError function on error
         * @returns {*} weblinkupdater readHlsLog
         */
        wu_functions.getHlsKlonLog = function(device, logName, onReadHlsLogComplete, onReadHlsLogError){

            wu.mockEnabled = devMode;
            return wu.readHlsLog(wu, wu_deviceInteractionImpl, logName, device, onReadHlsLogComplete, onReadHlsLogError);

        };

        /**
         *
         * @param device object detected device
         * @param eepromMap array { "01":"FFFF...",  "02":"FFFF..." }
         * @param onWriteModuleComplete function on complete
         * @param onWriteModuleError function on error
         * @returns {*} weblinkupdater writeConfiguration
         */
        wu_functions.getWriteModuleResult = function (device, eepromMap, onWriteModuleComplete, onWriteModuleError, isRsu){

            var Configuration = weblinkupdater.Configuration;
            var config = new Configuration();
            var pageContentArray = [];
            if(isRsu) {
                config.isRsu = true;
                Object.keys(eepromMap).map(function(pageAddress) {
                    config.setPageContents(pageAddress, eepromMap[pageAddress].toLocaleUpperCase());
                });
            } else {
                pageContentArray.push(eepromMap);
                angular.forEach(pageContentArray[0], function(value, key) {
                    config.setPageContents(key,value);
                });
            }
            wu.mockEnabled = devMode;
            // Verify that the data has been written correctly
            var readConfirmation;

            if (isRsu) {
                readConfirmation = wu_functions.getRsuConfig(device, Object.keys(eepromMap), onVerifyComplete, onWriteModuleError);
            } else {
                readConfirmation = wu_functions.getReadModuleResult(device, Object.keys(eepromMap), onVerifyComplete, onWriteModuleError);
            }

            function onVerifyComplete () {

                if (_.isEqual(readConfirmation.configuration._pageContents, config._pageContents)){
                    onWriteModuleComplete();
                } else {
                    onWriteModuleError();
                }
            }

            var onWriteComplete = function (response) {
                readConfirmation.start();
            };

            return wu.writeConfiguration(wu, wu_deviceInteractionImpl,
                {'provideConfig': function (provideConfig) { provideConfig(config);}},
                device, onWriteComplete, onWriteModuleError);

        };


        wu_functions.transformPageReadingsToArray = function(readPageResultConfiguration){

            var readings = [];
            var page = {};

            readPageResultConfiguration.forEach(function(k,v){
                page = {};
                page[String(k)] = v;
                readings.push(page);
            });

            return readings;
        };

        return wu_functions;
    }
]);
