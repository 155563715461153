'use strict';

angular.module('core').controller('PartialsGuidesSubmenuController', ['$scope',
	function($scope) {

        /*
            To access parent function/var:
            $scope.$parent.showDetectFail({message:'test'});
            $scope.$parent.requestid = '';
        */

        $scope.loadPartial = function(item){
            //TODO: configuration > submenu > click
			$scope.$parent.submenu.selectedItem = item;
        };

	}
]);
