/**
 * Created by rafikgharsalli on 2017-12-07.
 */
(function() {
    'use strict';

    angular
        .module('core')
        .directive('accessoriesContainer', accessoriesContainer);

    function accessoriesContainer() {

        return {
            restrict: 'E',
            scope: {
                responseData: '=',
                request: '=',
                userSelection: '='
            },
            templateUrl: 'modules/core/views/partials/firmware/directives/accessories.container.client.directive.view.html',
            controller: 'PartialsAccessoriesContainerController',
            controllerAs: 'ctrl'
        };
    }
})();
