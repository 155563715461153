'use strict';
/**
 * Created by jeromesoulas on 15-08-13.
 */
angular.module('core').directive('cropMenuLabel', [
    function(){

    function link($scope, $elem, attrs) {
        var label = $scope.label;
        var pattern = /Menu\s*#\s*[0-9]+\s*:\s*/;
        label = label.replace( pattern, '' );
        $scope.labelCropped =  label;

    }

    return {
        link: link,
        restrict:'E',
        scope: {
            label: '@label'
        },
        templateUrl: 'modules/core/views/directives-partial/crop.menu.label.html'
    };

    }
]);