/**
 * Add/Remove Remotes, standalone process
 */
'use strict';


angular.module('core').controller('PartialsControlsControlsController', ['$scope', 'Wmoapi', 'messageBox', 'alertBar',
    '$rootScope', 'wu', 'Request', 'DeviceService', 'ngDialog', '_',
    function($scope, Wmoapi, messageBox, alertBar, $rootScope, wu, Request, DeviceService, ngDialog, _) {

        $scope.max_limit = 4;
        $scope.editControlsOnly = false;
        $scope.hasOemRemoteConfig = false;
        $scope.reservedRemotes = [];
        $scope.readRemotePagesResult = null;
        $scope.remoteEepromError = false;

        $scope.initControls = function () {
            $scope.getDeviceControls();
            $scope.managePageNavigation();
        };

        $scope.managePageNavigation = function () {

            var modifyControlsOnly = $scope.$parent.modifyControlsOnly;
            var backStep = 'firmware';

            $scope.hidePageNaviNextBtn = modifyControlsOnly;
            $scope.backSkipStep = modifyControlsOnly ? 'device' : backStep;
            $scope.nextSkipStep = 'features-save-remotes';

        };

        $scope.getDeviceControls = function () {

            $scope.editControlsOnly = true;
            $rootScope.isGlobalLoading = true;
            $scope.createRequestConfigureRemotesSolo();
        };

        $scope.isEsnValid = function (param) {
            var regExp = new RegExp(/^[0-9A-Fa-f]{6}$/i);
            return regExp.test(param);
        };

        function isSNValid(param) {
          var regExp = new RegExp(/^T[0-9]{9}$/i);
          return regExp.test(param);
        }

        $scope.isEsnAlreadyAdded = function (esn) {
            var myArray = $scope.$parent.responseData.deviceControls;
            var newEsn = esn.toUpperCase();
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i].esn.toUpperCase() === newEsn) {
                    return true;
                }
            }
            return false;
        };

        $scope.removeSelectedRemote = function(remote) {

            alertBar.hide();

            var index = $scope.$parent.responseData.deviceControls.indexOf(remote);
            $scope.$parent.responseData.deviceControls.splice(index, 1);
            index = $scope.reservedRemotes.indexOf(remote);
            $scope.reservedRemotes[index] = {};
        };

        $scope.isAtMaxLimit = function () {
          return $scope.$parent.responseData.deviceControls.length >= $scope.max_limit;
        };

        function openDialog() {
            ngDialog.open({
                template: '<remotes-help />',
                className: 'ngdialog-theme-plain remotes',
                plain: true
            });
          }

        $scope.openDialog = openDialog;

        $scope.getSelectedEsn = function () {
            var deviceControlsArray = $scope.$parent.responseData.deviceControls;
            var esns = [];
            for (var i = 0; i < deviceControlsArray.length; i++) {
                esns.push(deviceControlsArray[i].esn);
            }
            return esns;
        };

        $scope.createRequestConfigureRemotesSolo = function(){

            $rootScope.isGlobalLoading = true;

            var detectedDevice = DeviceService.getFlashableDevice();
            var bootloader = detectedDevice.bootloader.version;
            var serial = detectedDevice.serial;

            var configRemotesRequestId = '';

            Wmoapi
                .createRequestConfigureRemotesSolo(bootloader, serial)
                .success(function (response) {
                    //TODO: handle success
                    configRemotesRequestId = response.requestId;
                    $scope.$parent.request.configRemotesRequestId = configRemotesRequestId;
                    $scope.readRemotePages();
                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    $scope.$parent.showErrorMessageFromServer('GENERAL_ERROR_CREATE_REQUEST', error);
                    $scope.$parent.loadDevicePartial(false);
                });

        };

        $scope.getRemotes = function (configRemotesRequestId, remotePages) {
            Wmoapi.getRemotes(configRemotesRequestId, remotePages, false)
                .success(function (response) {
                    var remotes = response.remotes;
                    var countRemoteIncompatible = response.countRemoteIncompatible;

                    $scope.$parent.responseData.deviceControls = remotes;
                    $scope.$parent.responseData.deviceControlPages = response.pages;
                    $scope.loadReservedRemotes(remotes);

                    if (countRemoteIncompatible > 0) {
                        alertBar.show('error', 'REMOTE_UNRECOGNIZED', {'COUNT': countRemoteIncompatible});
                    }

                    if ($scope.editControlsOnly) {
                        $scope.getConfigModuleEepromAddress();
                    } else {
                        $scope.createRequestConfigureModule();
                        //$scope.createRequestConfigureModuleSolo();
                    }

                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show('error', 'CONTROLS_ERROR_GET_REMOTES');
                    $scope.remoteEepromError = true;
                });
        };

        $scope.readRemotePages = function () {
            var remotePages = $scope.$parent.responseData.remotePages;
            if (remotePages.length > 0) {
                var device = DeviceService.getMainDevice();
                $scope.readRemotePagesResult = wu.getReadModuleResult(device, remotePages, $scope.onReadRemotePagesComplete, onReadRemotePagesError);
                $scope.readRemotePagesResult.start();
            } else {
                $rootScope.isGlobalLoading = false;
                alertBar.show('error', 'DETECTION_ERROR_REMOTE_GET_INFO');
                $scope.remoteEepromError = true;
            }
        };

        $scope.onReadRemotePagesComplete = function () {
            var readings = wu.transformPageReadingsToArray($scope.readRemotePagesResult.configuration);
            $scope.$parent.responseData.remotePagesReadings = readings;
            $scope.getRemotes($scope.$parent.request.configRemotesRequestId, $scope.$parent.responseData.remotePagesReadings);
        };

        function onReadRemotePagesError (error) {
            $rootScope.isGlobalLoading = false;
            alertBar.show('error', 'DETECTION_ERROR_REMOTE_READ_EEPROM');
            $scope.remoteEepromError = true;
        }

        $scope.editControlsOnly_saveRemotes = function () {

            if ($scope.isAllEsnBoxEmpty()) {
                alertBar.hide();
                $rootScope.isGlobalLoading = true;
                $scope.readRemoteEeprom();
            } else {
                alertBar.show('error', 'CONTROLS_ERROR_ESNBOX_NOTEMPTY');
            }

        };

        $scope.readRemoteEeprom = function () {

            var device = DeviceService.getFlashableDevice();
            var requiredPages = $scope.$parent.responseData.deviceControlPages;

            if (requiredPages && requiredPages.length > 0) {

                //read remotes eeprom
                $scope.controlReadRequiredPagesResult = wu.getReadModuleResult(device, requiredPages,
                    function () {
                        //onReadRequiredPagesComplete

                        var readings = wu.transformPageReadingsToArray($scope.controlReadRequiredPagesResult.configuration);

                        $scope.$parent.responseData.controlRequiredPagesReadings = readings;

                        var esns = $scope.getSelectedEsn();
                        var requestId = $scope.$parent.request.configRemotesRequestId;

                        Wmoapi
                            .setRemotes(requestId, esns, readings)
                            .success(function (response) {
                                $scope.downloadConfigRemotes();
                            })
                            .error(function (error) {
                                alertBar.show('error', 'CONTROLS_ERROR_SET_REMOTES');
                            });

                    }, function () {
                        //onReadRequiredPagesError
                        alertBar.show('error', 'CONTROLS_ERROR_READ_REMOTE_EEPROM');
                        $scope.remoteEepromError = true;
                    });

                $scope.controlReadRequiredPagesResult.start();

            } else {
                alertBar.show('error', 'CONTROLS_ERROR_NO_EEPROM_PAGES');
                $scope.remoteEepromError = true;
            }

        };

        $scope.downloadConfigRemotes = function () {

            var requestId = $scope.$parent.request.configRemotesRequestId;

            Wmoapi
                .download(requestId)
                .success(function (response) {
                    $scope.$parent.responseData.configRemotesDownload = response;
                    $scope.writeConfigRemotes();
                })
                .error(function (error) {
                    alertBar.show('error', 'CONTROLS_ERROR_DOWNLOAD_REMOTES');
                    $scope.remoteEepromError = true;
                });
        };


        $scope.writeConfigRemotes = function () {

            var pageContent =  $scope.$parent.responseData.configRemotesDownload.body.eepromMap;
            var device = DeviceService.getFlashableDevice();
            $scope.configRemotesResult = wu.getWriteModuleResult(device, pageContent, $scope.onWriteConfigRemotesComplete, $scope.onWriteConfigRemotesError);
            $scope.configRemotesResult.start();
        };

        $scope.onWriteConfigRemotesComplete = function () {
            $scope.confirmConfigRemotes();
        };

        $scope.onWriteConfigRemotesError = function (e) {

            console.log('CONTROLS_ERROR_WRITE_REMOTE_EEPROM: ' + e);
            alertBar.show('error', 'CONTROLS_ERROR_WRITE_REMOTE_EEPROM');

        };

        $scope.confirmConfigRemotes = function () {

            var configRemotesRequestId = $scope.$parent.request.configRemotesRequestId;
            var downloadRemId = $scope.$parent.responseData.configRemotesDownload.downloadId;

            Wmoapi
                .confirm(configRemotesRequestId, downloadRemId)
                .success(function (response) {

                    if ($scope.hasOemRemoteConfig) {
                        $scope.editControlsOnly_saveOemRemote();
                    }
                    else {
                        $scope.editControlsOnly_saveComplete();
                    }

                })
                .error(function (error) {
                    alertBar.show('error', 'CONTROLS_ERROR_CONFIRM_REQUEST_REMOTES');
                });
        };

        $scope.editControlsOnly_saveOemRemote = function () {

            var configModuleRequestId = $scope.$parent.request.configModuleRequestId;
            var oemRemoteId = $scope.$parent.userSelection.oemRemote.id;
            var feature_config_id = $scope.$parent.responseData.deviceOemRemoteConfig.id;

            if (oemRemoteId !== '' && feature_config_id !== '') {

                Wmoapi
                    .setOemRemoteConfig(configModuleRequestId, feature_config_id, oemRemoteId)
                    .success(function (response) {

                        //download config module
                        Wmoapi
                            .download(configModuleRequestId)
                            .success(function (response) {

                                $scope.$parent.responseData.configModuleDownload = response;
                                $scope.writeConfigModule();

                            })
                            .error(function (error) {
                                alertBar.show('error', 'CONTROLS_ERROR_DOWNLOAD_OEM_REMOTE');
                            });

                    })
                    .error(function (error) {
                        alertBar.show('error', 'CONTROLS_ERROR_SET_OEM_REMOTE');
                    });
            }
        };

        $scope.writeConfigModule = function () {

            var pageContent =  $scope.$parent.responseData.configModuleDownload.body.eepromMap;
            var device = DeviceService.getFlashableDevice();


            $scope.configResult = wu.getWriteModuleResult(device, pageContent,
                function () { //onWriteComplete

                    var configModuleRequestId = $scope.$parent.request.configModuleRequestId;
                    var downloadModId = $scope.$parent.responseData.configModuleDownload.downloadId;

                    Wmoapi
                        .confirm(configModuleRequestId, downloadModId)
                        .success(function (response) {

                            resetOemRemoteSelection();
                            $scope.editControlsOnly_saveComplete();

                        })
                        .error(function (error) {
                            alertBar.show('error', 'CONTROLS_ERROR_CONFIRM_REQUEST_OEM_REMOTE');
                        });
                },
                function () { //onWriteError
                    alertBar.show('error', 'CONTROLS_ERROR_WRITE_CONFIG_MODULE_EEPROM');
                });
            $scope.configResult.start();
        };

        $scope.editControlsOnly_saveComplete = function () {

            $rootScope.isGlobalLoading = false;
            messageBox.show('REMOTE_CONFIGURATION_SAVED', 'POPUP_FEATURES_SAVED_DESC');
            $scope.$parent.loadDevicePartial();

        };

        $scope.getOemRemoteConfig = function (configModuleRequestId) {

            Wmoapi
                .getOemRemoteConfig(configModuleRequestId)
                .success(function (response) {
                    if (response.id) {

                        $scope.$parent.responseData.deviceOemRemoteConfig = response;
                        $scope.hasOemRemoteConfig = true;
                        var options = response.option;
                        var selectedOemRemote = _.find(options, 'isSelected') ? _.find(options, 'isSelected')  : _.find(options, 'isDefault');
                        selectedOemRemote.optionId = selectedOemRemote.id;
                        $scope.$parent.userSelection.oemRemote = selectedOemRemote;
                    } else {
                        if ($scope.editControlsOnly) {

                            resetOemRemoteSelection();

                            Request.cancelRequest(configModuleRequestId,
                                function () {
                                },
                                function () {
                                });
                        }
                    }
                })
                .error(function (error) {
                    alertBar.show('error', 'CONTROLS_ERROR_GET_OEM_REMOTE');

                }).finally(function () {
                $rootScope.isGlobalLoading = false;
            });

        };

        function resetOemRemoteSelection() {
            $scope.$parent.responseData.deviceOemRemoteConfig = {};
            $scope.$parent.userSelection.oemRemote = '';
        }


        $scope.getConfigModuleEepromAddress = function () {

            Wmoapi
                .getEepromAddress()
                .success(function (response) {
                    $scope.$parent.responseData.configModuleRequiredPages = response.address;
                    $scope.readConfigModulePages();
                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    console.error('error in getConfigModuleEepromAddress', error);
                    alertBar.show('error', 'CONTROLS_ERROR_GET_EEPROM_PAGES_CONFIG_MODULE');
                });
        };

        $scope.readConfigModulePages = function () {

            var requiredPages = $scope.$parent.responseData.configModuleRequiredPages;
            if (requiredPages.length > 0) {
                var device = DeviceService.getFlashableDevice();

                $scope.readRequiredPagesResult = wu.getReadModuleResult(device, requiredPages,
                    function () { //onReadComplete

                        var readings = wu.transformPageReadingsToArray($scope.readRequiredPagesResult.configuration);
                        $scope.$parent.responseData.configModulePagesReadings = readings;
                        $scope.createRequestConfigureModuleSolo();

                    }, function (error) { //onReadError
                        alertBar.show('error', 'CONTROLS_ERROR_READ_CONFIG_MODULE_EEPROM');
                    });
                $scope.readRequiredPagesResult.start();

            } else {
                $rootScope.isGlobalLoading = false;
                alertBar.show('error', 'CONTROLS_ERROR_NO_EEPROM_PAGES_CONFIG_MODULE');
            }

        };

        $scope.createRequestConfigureModuleSolo = function () {

            var device = DeviceService.getFlashableDevice();
            var bootloader = device.bootloader.version;
            var serial = device.serial;
            var readings = $scope.$parent.responseData.configModulePagesReadings;
            var requestid_configModule = '';

            Wmoapi
                .createRequestConfigureModuleSolo(bootloader, serial, readings)
                .success(function (response) {

                    requestid_configModule = response.requestId;
                    $scope.$parent.request.configModuleRequestId = requestid_configModule;
                    $scope.getOemRemoteConfig(requestid_configModule);

                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show('error', 'CONTROLS_ERROR_CREATE_REQUEST_CONFIG_MODULE');
                });
        };

        /**
         *
         * @param deviceControls $parent.responseData.deviceControls
         */
        $scope.loadReservedRemotes = function (deviceControls) {

            var remotes = [{}, {}, {}, {}];

            angular.forEach(deviceControls, function (value, key) {
                remotes[key] = value;
            });

            $scope.reservedRemotes = remotes;
        };

        $scope.addReservedRemote = function (item) {

            alertBar.hide();

            var esn = (item.esn) ? item.esn.toUpperCase() : '';
            var index = $scope.reservedRemotes.indexOf(item);

            $scope.reservedRemotes[index].invalid = false;

            if (esn !== '') {

                $rootScope.isGlobalLoading = true;
                $scope.reservedRemotes[index].invalid = true;

                if (!$scope.isEsnValid(esn) && !isSNValid(esn)) {
                    alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_INVALID');
                } else if ($scope.isEsnAlreadyAdded(esn)) {
                    alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_ALREADY_ENTERED');
                } else if ($scope.isAtMaxLimit()) {
                    alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_MAX_LIMIT', {'MAX_LIMIT': $scope.max_limit});
                } else {
                    Wmoapi
                        .getRemoteInfo(esn)
                        .success(function (response) {
                            if (response.length === 0) {
                                alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_INVALID');
                            } else {
                                $scope.$parent.responseData.deviceControls.push(response);
                                $scope.reservedRemotes[index] = response;
                            }
                            $rootScope.isGlobalLoading = false;
                        })
                        .error(function (error) {
                            alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_INVALID');
                        });
                }
            } else {
                $scope.reservedRemotes[index] = {};
            }
        };

        $scope.removeReservedRemote = function (item) {

            alertBar.hide();

            var index = $scope.$parent.responseData.deviceControls.indexOf(item);
            $scope.$parent.responseData.deviceControls.splice(index, 1);

            index = $scope.reservedRemotes.indexOf(item);
            $scope.reservedRemotes[index] = {};

        };

        $scope.clearReservedRemote = function (item) {

            alertBar.hide();

            var index = $scope.reservedRemotes.indexOf(item);
            $scope.reservedRemotes[index] = {};

        };

        $scope.isAllEsnBoxEmpty = function () {

            var remotes = $scope.reservedRemotes;
            var isEmpty = true;

            angular.forEach(remotes, function (value, key) {
                if (!value.id) {
                    if (value.esn && value.esn.trim() !== '') {
                        isEmpty = false;
                    }
                }
            });

            return isEmpty;

        };

        $scope.modifySelectedPackage = function (id) {
            $scope.$parent.userSelection.packages.packageId = id;
            var requestId = $scope.$parent.request.id;
            Wmoapi
                .setPackage(requestId, id)
                .success(function () {
                })
                .catch(function () {
                    alertBar.show('error', 'CONTROLS_ERROR_SET_PACKAGE');
                });
        };
    }
]);
