(function() {
    'use strict';
    angular
        .module('core')
        .factory('SourceSiteService', SourceSiteService);

    function SourceSiteService() {

        var sourceSiteService = {};

        var sites = {
            default: {
                'name': 'Idatalink',
                'url': 'http://www.idatalink.com'
            },
            aamp: {
                'name': 'AAMP',
                'url': 'http://aamp.idatalink.com'
            },
            arcticstart: {
                'name': 'Arcticstart',
                'url': 'http://arcticstart.idatalink.com'
            },
            idatalink: {
                'name': 'Idatalink',
                'url': 'http://www.idatalink.com'
            },
            autokinetix: {
                'name': 'Auto Kinetix',
                'url': 'http://www.autokinetix.com'
            },
            automobility: {
                'name': 'Automobility',
                'url': 'http://automobility.idatalink.com'
            },
            bestbuy: {
                'name': 'Bestbuy',
                'url': 'http://www.bbweblink.com'
            },
            compustar: {
                'name': 'Compustar',
                'url': 'http://compustar.idatalink.com'
            },
            compustarclone: {
                'name': 'Compustar',
                'url': 'http://compustar.idatalink.com'
            },
            crimestopper: {
                'name': '',
                'url': 'http://crimestopper.idatalink.com'
            },
            crutchfield: {
                'name': 'Crutchfield',
                'url': 'http://crutchfield.idatalink.com'
            },
            dse: {
                'name': 'DSE',
                'url': 'http://dse.idatalink.com'
            },
            flashlogic: {
                'name': 'Flashlogic',
                'url': 'http://www.flashlogic.com'
            },
            futureshop: {
                'name': 'Futureshop',
                'url': 'http://futureshop.idatalink.com'
            },
            kia: {
                'name': 'Kia',
                'url': 'http://kia.idatalink.com'
            },
            nissan: {
                'name': 'Nissan',
                'url': 'http://nissan.idatalink.com'
            },
            nordicstart: {
                'name': 'NordicStart',
                'url': 'http://nordicstart.idatalink.com'
            },
            omegaweblink: {
                'name': 'Omega',
                'url': 'http://www.omegaweblink.com'
            },
            orbit: {
                'name': 'Orbit',
                'url': 'http://orbitpro.idatalink.com'
            },
            orbitpro: {
                'name': 'Orbit Pro',
                'url': 'http://orbitpro.idatalink.com'
            },
            prostart: {
                'name': 'Prostart',
                'url': 'http://prostart.idatalink.com'
            },
            russian: {
                'name': 'Idatalink',
                'url': 'http://russia.idatalink.com'
            },
            staub: {
                'name': 'Staub',
                'url': 'http://staub.idatalink.com'
            },
            www0: {
                'name': 'Idatalink',
                'url': 'http://www0.idatalink.com'
            }
        };

        var keyDefault = 'default';
        var nameDefault = 'Idatalink';
        var urlDefault = 'http://www.idatalink.com';

        var key = '';
        var name = '';
        var url = 'http://www.idatalink.com';

        sourceSiteService.getName = getName;
        sourceSiteService.getUrl = getUrl;

        sourceSiteService.setSourceSite = setSourceSite;

        function getName() {
            return name;
        }

        function getUrl() {
            return url;
        }

        function setSourceSite(srcsite) {
            handleSourceSite(srcsite);
        }

        function handleSourceSite(srcsite) {
            key = (!srcsite || srcsite === '') ? keyDefault : srcsite.toLowerCase().trim();
            var site = sites[key];
            if (site) {
                name = site.name;
                url = site.url;
            } else {
                name = nameDefault;
                url = urlDefault;
            }
        }

        return sourceSiteService;
    }
})();
