'use strict';

angular.module('core').directive('errSrc', [
    function(){

    return {
        link: function(scope, element, attrs) {
            element.bind('error', function() {

                if (attrs.src !== attrs.errSrc) {
                    attrs.$set('src', attrs.errSrc);
                }

                if (attrs.errName) {
                    element.css({ display: 'none' });
                    var p = element.parent();
                    var text = p.find('err-name');
                    text.css({ display: 'block' });
                    text.text(attrs.errName);
                }

            });
        }
    };

    }
]);