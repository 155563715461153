/**
 * Created by rafikgharsalli on 2016-04-07.
 */

'use strict';

angular.module('core').filter('sanitizename', [
    function() {

        return function(input) {
            var sanitizedOutput = 'N/A';

            if (input) {

                input = String(input);
                sanitizedOutput = (input.toUpperCase().substr(0, 7) === 'UNNAMED') ? 'N/A' : input;

            }

            return sanitizedOutput;

        };

    }
]);
