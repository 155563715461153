(function() {
    'use strict';

    angular
        .module('core')
        .directive('remotes', Remotes);

        function Remotes() {
            return {
                restrict: 'E',
                scope: {
                  // stuff in scope
                  supportsInternalRemotes: '=',
                  loadTelematicsPartial: '&',
                  loadFirmwarePartial: '&',
                  request: '=',
                  userSelection: '=',
                  responseData: '='
                },
                templateUrl: 'modules/core/views/directives-partial/remotes.client.directive.view.html',
                controller: 'PartialsRemotesController',
                controllerAs: 'ctrl'
            };
        }
})();
