(function() {
    'use strict';
    angular
        .module('core')
        .directive('controlPreview', ['$rootScope',controlPreview]);

    function controlPreview($rootScope) {
        return {
            restrict:'E',
            scope: {
                control: '=',
                skip: '&'
            },
            templateUrl:'modules/core/views/directives-partial/controlPreview.device.html',
            link: function(scope) {
                scope.skipstep = function (skipTo) {
                    $rootScope.globalSkipStep(skipTo);
                };

            }
        };
    }
})();
