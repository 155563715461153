/**
 * Created by rafikgharsalli on 2016-03-10.
 */
'use strict';
var app = angular.module('core');


angular.module('core').factory('videoContainer', ['ngDialog',
    function(ngDialog){

        var videoContainer = {};
        /**
         * Show video container
         * @param link video link to show
         * @param videoThumbnail video poster 
         * @param width
         * @param height
         *
         */
        videoContainer.show = function(link,videoThumbnail,width,height) {
            ngDialog.openConfirm({ template:'modules/core/views/partials/videoContainer/video.container.client.view.html',
                                   className:'ngdialog-theme-plain custom-class', 
                                   overlay:true,
                                   data:{link:link,videoThumbnail:videoThumbnail, width:width, height:height} });
        };

        return videoContainer ;
    }
    ]);
