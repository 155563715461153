(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsFirmwareSliderModalController', PartialsFirmwareSliderModalController);

    PartialsFirmwareSliderModalController.$inject = ['alternativeFirmwares', 'currentFirmware'];

    function PartialsFirmwareSliderModalController(alternativeFirmwares, currentFirmware) {
        var vm = this;
        vm.alternativeFirmwares = alternativeFirmwares;
        vm.currentFirmware = currentFirmware;
    }

})();
