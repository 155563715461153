'use strict';

angular.module('core').controller('PartialsConfigurationSummaryController', ['$scope', 'Wmoapi', 'messageBox','$rootScope',
	function($scope, Wmoapi, messageBox, $rootScope) {

        $scope.loadSummary = function () {

            $rootScope.isGlobalLoading = true;

            getCurrentFirmwareAndSetFirmware();

            //set css properties
            angular.element(document.querySelector('#body')).removeClass('standard');
            angular.element(document.querySelector('#body')).addClass('body-model');

        };

        function getCurrentFirmwareAndSetFirmware(){

            //TOD: this function will be moved to telematics section once it's available

            var parentRequestId = $scope.$parent.request.id;
            var title = 'FIRMWARE_CONFIGURE_MODULE';
            var description = 'FIRMWARE_MUST_CONFIGURE';

            if( parentRequestId === '' || parentRequestId === undefined ){
                //TODO: decide what to do when master request is not valid
                messageBox.show(title,description);
                $scope.$parent.loadDevicePartial();

            }else {
                Wmoapi
                    .getCurrentFirmware(parentRequestId)
                    .success(function (response) {

                        $scope.$parent.responseData.currentFirmware = response;
                        var firmwareId = $scope.$parent.responseData.currentFirmware.id;

                        Wmoapi
                            .setFirmware(parentRequestId, firmwareId)
                            .success(function (response) {
                                $scope.$parent.responseData.setFirmware = response;
                            })
                            .error(function (error) {
                                //TODO: handle error
                            })
                            .finally(function(){
                                $rootScope.isGlobalLoading = false;
                            });
                    })
                    .error(function (error) {
                        //TODO: handle error
                    });
            }
        }

	}
]);