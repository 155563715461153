'use strict';

var app = angular.module('core');

angular.module('core').factory('alertBar', ['$rootScope', '$timeout',
    function($rootScope, $timeout){

        $rootScope.alertBar = {
            messageKey:'BLANK',
            showFull: '',
            mainContentClass: '',
            messageParams: null,
            showBar: false,
            hasUpdate:false,
            hasTitle: false,
            titleKey: 'BLANK'
        };

        var alertBar = {};

        alertBar.show = function( type, messageKey, messageParams, titleKey ){

            if( type === 'errorFull' ){
                type = 'error';
                $rootScope.alertBar.showFull = 'show-full';
            }
            else if( type === 'errorFullWhite' ){
                type = 'errorWhite';
                $rootScope.alertBar.showFull = 'show-full';
            }

            if (titleKey) {
                $rootScope.alertBar.titleKey = titleKey;
                $rootScope.alertBar.hasTitle = true;
            }

            var properties = alertBar.getClass(type);
            $rootScope.alertBar.typeClass = properties.typeClass;
            $rootScope.alertBar.iconClass = properties.iconClass;
            $rootScope.alertBar.textClass = properties.textClass;

            $rootScope.alertBar.messageKey = messageKey;
            $rootScope.alertBar.messageParams = messageParams ? messageParams : null;
            $rootScope.alertBar.hasUpdate = type === 'update';

            $rootScope.alertBar.showBar = true;
            $rootScope.isGlobalLoading = false;
            if ($rootScope.$root.$$phase !== '$apply' && $rootScope.$root.$$phase !== '$digest') {
                $rootScope.$apply();
            }
        };




        alertBar.hide = function() {
            $rootScope.alertBar.showBar = false;
        };

        alertBar.getClass = function(type) {

            var object = {};

            switch( type ){
                case 'error':
                    object.typeClass = 'alert-bar-error';
                    object.iconClass = 'fa-times-circle';
                    object.textClass = '';
                    break;
                case 'errorWhite':
                    object.typeClass = 'alert-bar-error-white';
                    object.iconClass = 'fa-times-circle';
                    object.textClass = 'red-text';
                    break;
                case 'message':
                    object.typeClass = 'alert-bar-info';
                    object.iconClass = 'fa-info-circle';
                    object.textClass = '';
                    break;
                case 'success':
                    object.typeClass = 'alert-bar-success';
                    object.iconClass = 'fa-check-circle';
                    object.textClass = '';
                    break;
                case 'warning':
                    object.typeClass = 'alert-bar-warning';
                    object.iconClass = 'fa-exclamation-triangle';
                    object.textClass = '';
                    break;
                case 'update':
                    object.typeClass = 'alert-bar-info update';
                    object.iconClass = 'fa-info-circle';
                    object.textClass = '';
                    break;
                default:
                    object.typeClass = 'alert-bar-info';
                    object.iconClass = 'fa-info-circle';
                    object.textClass = '';
                    break;
            }

            return object;

        };

        return alertBar;
    }
]);
