
'use strict';
angular
    .module('core')
    .factory('DeviceService', ['deviceIdentifier' , '_',
        function (deviceIdentifier, _) {
            var deviceService = {};
            var devices = {};
            var deviceToFlash = {};
            // getters
            deviceService.getFlashableDevice = getFlashableDevice;
            deviceService.getRsuDevice = getRsuDevice;
            deviceService.getEmbeddedDevice = getEmbeddedDevice;
            deviceService.getEmbeddedDevices = getEmbeddedDevices;
            deviceService.getDeviceBySerial = getDeviceBySerial;
            deviceService.getMainDevice = getMainDevice;
            deviceService.getDevices = getDevices;
            deviceService.hasEmbeddedDevice = hasEmbeddedDevice;
            deviceService.canModifyFeatures = canModifyFeatures;
            deviceService.isClassicDevice = isClassicDevice;
            deviceService.isDeviceAccessory = isDeviceAccessory;
            deviceService.isDeviceRfid = isDeviceRfid;
            deviceService.isRsuDevice = isRsuDevice;
            // setters
            deviceService.setDevices = setDevices;
            deviceService.setDeviceToFlash = setDeviceToFlash;


            function getFlashableDevice() {
                return deviceToFlash;
            }

            function getMainDevice() {
                var device = devices ? devices[0] : '';
                return device;
            }

            function getRsuDevice() {
                var device = _.find(devices, function(e){
                    return e.bootloader && e.bootloader.moduleType && e.bootloader.moduleType === '4';
                });
                return device ? device : null;
            }

            function getEmbeddedDevice() {
                var device = hasEmbeddedDevice() ? devices[1] : '';
                return device;
            }

            function getEmbeddedDevices() {
                var embeddedDevice =  hasEmbeddedDevice() ? devices.slice(1) : [] ;
                return embeddedDevice;
            }

            function getDeviceBySerial(serial) {
                var device = devices.filter(function(item){
                    return item.serial === serial;
                })[0];
                return device;
            }

            function getDevices() {
                return devices;
            }

            function hasEmbeddedDevice() {
                return getRsuDevice() ? null : devices.length > 1;
            }

            function canModifyFeatures() {
                return deviceIdentifier.isConfigureModuleAllowed(getFlashableDevice().allowedServices);
            }

            function isClassicDevice() {
                return deviceIdentifier.isDetectedDeviceIsClassic(getFlashableDevice().productPartOptions);
            }

            function isDeviceAccessory() {
                return deviceIdentifier.isDeviceAccessory(getFlashableDevice().deviceFunctionId);
            }
            
            function isDeviceRfid() {
                return deviceIdentifier.isDeviceRfid(getFlashableDevice().deviceFunctionId);
            }

            function isRsuDevice(device) {
                return device.bootloader && device.bootloader.moduleType && device.bootloader.moduleType === '4';
            }

            function setDevices(detectedDevices) {
                devices = detectedDevices;
                setDeviceToFlash(detectedDevices[0]);
            }

            function setDeviceToFlash(device) {
                deviceToFlash = device;
            }

            return deviceService;
        }
    ]);
