/**
 * Created by rafikgharsalli on 2016-04-20.
 */

'use strict';
angular.module('core').directive('connectorContainer', [
    function() {
        return {
            scope: {
                tags: '='
            },
            restrict: 'E',
            templateUrl: 'modules/core/views/directives-partial/connector.container.html'
        };
    }
]);
