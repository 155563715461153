'use strict';

angular.module('core').controller('PartialsConfigurationMakesController', ['$scope', 'Wmoapi', 'Request', 'configuration', 'DeviceService', '$rootScope', 'alertBar', 'RSUData','ngDialog','constants', '$timeout',
    function($scope, Wmoapi, Request, configurationFactory, DeviceService, $rootScope, alertBar, RSUData, ngDialog, constants, $timeout) {

        $scope.showMakesToggle = false;

        $scope.toggleVehicleMakes = function (){
            $scope.getVehicleMakes();
        };

        $scope.initVehicleMakes = function() {
            $scope.otherMakeId = constants.OTHER_VEHICLE_MAKE_ID;
            ngDialog.closeAll();
            $scope.managePageNavigation();
            $scope.manageContent();
        };

        $scope.managePageNavigation = function() {
            var selectedMakeId = $scope.$parent.selectedVehicle.makeId;
            var makeId = (selectedMakeId) ? parseInt(selectedMakeId) : 0;
            $scope.hidePageNaviNextBtn = (makeId === 0);

            $scope.backSkipStep = $scope.$parent.showPackageMode ? 'vehicle.packages' : 'device';

        };

        $scope.manageContent = function() {

            var selectedMakeId = $scope.$parent.selectedVehicle.makeId;
            var makeId = (selectedMakeId) ? parseInt(selectedMakeId) : 0;
            $scope.showMakesToggle = DeviceService.getFlashableDevice().hasLimitedVehicleCoverage;

            if (makeId === 0) {
                $scope.getVehicleMakes();
            } else {
                $scope.manageSubmenu();
            }
        };

        $scope.getVehicleMakes = function() {

            $rootScope.isGlobalLoading = true;

            //TODO: manage create/cancel request

            var requestId = $scope.$parent.request.id;
            var detectedDevice = DeviceService.getFlashableDevice();
            var request = Request.getRequestDevice();

            if (requestId === request.id &&
                detectedDevice.bootloader.version === request.bootloader.version &&
                detectedDevice.serial === request.serial) {

                loadMakes(requestId);
            } else {

                alertBar.hide();

                Wmoapi
                    .createRequest(detectedDevice.bootloader.version, detectedDevice.serial)
                    .success(function(response) {

                        requestId = response.requestId;

                        //TODO: create reset function
                        Request.clearRequestDevice();
                        $scope.$parent.request.configModuleRequestId = '';
                        $scope.$parent.request.configRemotesRequestId = '';
                        $scope.$parent.responseData.currentFirmware = '';

                        $scope.$parent.request.id = requestId;
                        Request.setRequestDevice(requestId, detectedDevice.bootloader, detectedDevice.serial);

                        loadMakes(requestId);
                    })
                    .error(function(error) {
                        alertBar.show( 'error', 'CONFIGURATION_MAKES_ERROR_CREATE_REQUEST' );
                    });
            }

            $scope.$parent.responseData.vehicleMakes = null;
            $scope.$parent.submenu.selectedItem = '';
        };

        function loadMakes(requestid) {

            Wmoapi
                .getVehicleMakes(requestid, $scope.$parent.configurationParameters.showAllMakes)
                .success(function(response) {
                    $scope.$parent.submenu.items = [];
                    $scope.$parent.responseData.vehicleMakes = response;

                    $scope.manageSubmenu();
                })
                .error(function(error) {
                    alertBar.show( 'error', 'CONFIGURATION_MAKES_ERROR_GET_MAKES' );
                })
                .finally(function() {
                    $rootScope.isGlobalLoading = false;
                });

            $scope.$parent.submenu.items = [];
        }

        $scope.manageSubmenu = function() {
            $scope.$parent.submenu.items = configurationFactory.getSubmenuItems();
            $scope.$parent.submenu.selectedItem = configurationFactory.getSubmenuMake();
        };

        $scope.loadVehicleYearsPage = function(makeId, makeName, isAnalogic) {

            var nextStep =  isAnalogic ? 'Trims' : 'Years';


            var tmp = $scope.$parent.selectedVehicle.makeId;
            $scope.$parent.prevSelectedVehicle.makeId = tmp === '' ? makeId : tmp;

            if (tmp !== makeId) {
                clearPrevVehicleSelections();
                RSUData.clearRSUBrands();
            }


            $scope.$parent.selectedVehicle.makeId = makeId;
            $scope.$parent.selectedVehicle.makeName = makeName;

            if (isAnalogic) {
                setSelectedVehicleIds();
            }

            $scope.$parent.loadConfigurationPartial(nextStep, true);

        };

        function setSelectedVehicleIds() {
            $scope.$parent.selectedVehicle.year = 0;
            $scope.$parent.selectedVehicle.modelId = 0;
            $scope.$parent.selectedVehicle.modelImage = 'img/generic/generic-vehicle.png';
        }

        function clearPrevVehicleSelections() {
            var v = $scope.selectedVehicle;
            v.year = '';
            v.yearName = '';
            v.modelId = '';
            v.modelName = '';
            v.modelImage = '';
            v.vehicleId = '';
            v.vehicleName = '';
            v.firmwareFamilyId = '';
            v.firmwareFamilyName = '';
            v.firmwareId = '';
            v.firmwareName = '';
            v.firmwareVersion = '';

            $scope.$parent.userSelection.telematicId = '';
            $scope.$parent.userSelection.telematicName = '';
            $scope.$parent.userSelection.telematicImagePhone = '';
            $scope.$parent.userSelection.selectedFirmware = {};

            var p = $scope.prevSelectedVehicle;
            p.year = '';
            p.modelId = '';
            p.vehicleId = '';
            p.firmwareFamilyId = '';
            p.firmwareId = '';
        }

    }
]);
