/**
 * Created by rafikgharsalli on 2016-02-18.
 */

'use strict';
angular.module('core').directive('imageSectionError', [
    function(){
        return {
            restrict: 'AE',
            templateUrl:'modules/core/views/directives-partial/image.section.error.html'
        };
    }
]);
