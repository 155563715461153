/**
 * Created by rafikgharsalli on 2016-11-01.
 */

'use strict';

angular.module('core').service('sidebarManager', ['_',

    function(_) {

        var sidebarManagerService = {};

        var sidebar = {
            currentIndex: 0,
            mode: 'flash'
        };

        sidebarManagerService.sidebar = sidebar;
        sidebarManagerService.guidesWiresSidebarStatus = false;

        sidebarManagerService.getGuidesWiresSidebarStatus = getGuidesWiresSidebarStatus;
        sidebarManagerService.setGuidesWiresSidebarStatus = setGuidesWiresSidebarStatus;

        sidebarManagerService.getSidebar = getSidebar;
        sidebarManagerService.setSidebarMode = setSidebarMode;
        sidebarManagerService.setSidebarIndex = setSidebarIndex;

        sidebarManagerService.getSidebarTags = getSidebarTags;


        function getGuidesWiresSidebarStatus() {
            return sidebarManagerService.guidesWiresSidebarStatus;
        }

        function setGuidesWiresSidebarStatus(status) {
            sidebarManagerService.guidesWiresSidebarStatus = status ? status : false;
        }

        function getSidebar() {
            return sidebar;
        }

        function setSidebarMode(mode) {
            sidebar.mode = mode;
        }

        function setSidebarIndex(index) {
            sidebar.currentIndex = index;
        }

        function getSidebarTags(vm) {
            var protocol = {
                name: 'protocols',
                title: 'MENU_SIDEBAR_PROTOCOLS',
                hide: !vm.isClassicDevice,
                onClick: vm.loadInstallOptionsPartial,
                param1: false,
                param2: '',
                disabled: false
            };
            var indexes = [
                {
                    name: 'vehicle',
                    title: 'MENU_SIDEBAR_VEHICLE',
                    hide: false,
                    onClick: vm.loadConfigurationPartial,
                    param1: 'Makes',
                    param2: true,
                    disabled: false

                },
                {
                    name: 'controls',
                    title: 'MENU_SIDEBAR_REMOTES',
                    hide: vm.skipControls || vm.isClassicDevice || (!vm.canModifyFeatures && (vm.isDeviceAccessory || vm.isDeviceRfid)),
                    onClick: vm.loadInternalRemotesPartial,
                    param1: vm.sidebar.mode === 'flash',
                    param2: '',
                    disabled: false
                },
                {
                    name: 'telematics',
                    title: 'MENU_SIDEBAR_TELEMATICS',
                    hide: vm.isClassicDevice || (!vm.canModifyFeatures && (vm.isDeviceAccessory || vm.isDeviceRfid)),
                    onClick: vm.loadTelematicsPartial,
                    param1: false,
                    param2: '',
                    disabled: false
                },
                {
                    name: 'firmware',
                    title: 'MENU_SIDEBAR_FIRMWARE',
                    hide: false,
                    onClick: vm.loadFirmwarePartial,
                    param1: false,
                    param2: '',
                    disabled: false
                },
                {
                    name: 'oem',
                    title: 'MENU_SIDEBAR_OEM_REMOTE_START',
                    hide: !vm.canModifyFeatures && (vm.isDeviceAccessory || vm.isDeviceRfid),
                    onClick: vm.loadOemRemoteStartPartial,
                    param1: false,
                    param2: '',
                    disabled: false
                },
                {
                    name: 'features',
                    title: 'MENU_SIDEBAR_FEATURES',
                    hide: !vm.canModifyFeatures && (vm.isDeviceAccessory || vm.isDeviceRfid),
                    onClick: vm.loadFeaturePartial,
                    param1: false,
                    param2: '',
                    disabled: false
                },
                {
                    name: 'guides',
                    title: vm.showWiringSection ? 'MENU_SIDEBAR_GUIDES_WIRES' : 'MENU_SIDEBAR_GUIDES',
                    hide: false,
                    onClick: vm.loadGuidesPartial,
                    param1: false,
                    param2: '',
                    disabled: false
                },
                {
                    name: 'flash',
                    title: 'MENU_SIDEBAR_FLASH',
                    hide: false,
                    onClick: vm.loadFlashPartial,
                    param1: '',
                    param2: '',
                    disabled: false
                },
            ];
            if (vm.isClassicDevice) indexes.splice(1, 0, protocol);
            return _.map(indexes, function (e, i) {
                return _.extend(e, {index: i + 1});
            });
        }

        return sidebarManagerService;
    }
]);
