(function() {
    'use strict';

    angular
        .module('core')
        .controller('UnsupportedBrowserHomeController', UnsupportedBrowserHomeController);

    // UnsupportedBrowserHomeController.$inject = [''];

    function UnsupportedBrowserHomeController() {

        var vm = this;
        vm.browserInformation = browserInformation;
    }
})();
