'use strict';

angular.module('core').service('guidesService', ['Wmoapi', 'ngDialog', '$rootScope', 'alertBar', '_',

    function(Wmoapi, ngDialog, $rootScope, alertBar, _) {

        var guidesService = {};
        guidesService.ownerGuidesHash = {
            '360': 'img/pdf/owner_guide_hcx_hcf.pdf',
            '418': 'img/pdf/owner_guide_hcx_hcf.pdf',
            '420': 'img/pdf/owner_guide_hc1_hc2.pdf',
            '422': 'img/pdf/owner_guide_hc1_hc2.pdf',
            '424': 'img/pdf/owner_guide_hc1_hc2.pdf',
            '468': 'img/pdf/owner_guide_vw2_e.pdf',
            '508': 'img/pdf/owner_guide_hc3_e.pdf',
            '528': 'img/pdf/owner_guide_vwx_e.pdf',
            '532': 'img/pdf/OM_FT-DC2_2016_10_03_e.pdf',
            '558': 'img/pdf/OM_RS1BDC2_2016_10_03_e.pdf',
            '586': 'img/pdf/OM_FT-DC2_2016_10_03_e.pdf'
        };
        guidesService.extractInstallGuideFromUrl = extractInstallGuideFromUrl;
        guidesService.extractOwnerGuideFromUrl = extractOwnerGuideFromUrl;
        guidesService.getRsuGuide = getRsuGuide;


        function extractInstallGuideFromUrl (guideUrl){
            var re, match, language, filename, version, guideObj;
            re = /.+\/(.+?)-(EN|FR)_(\d+)\.pdf/;
            match = guideUrl.match(re);
            language = '';
            filename = '';
            version = '';
            if(match !== null){
                filename = match[1];
                language = match[2];
                version = match[3];
            }

            guideObj = {
                language: language,
                filename : filename,
                version : version,
                url : guideUrl
            };

            return guideObj;
        }

        function extractOwnerGuideFromUrl (guideUrl) {
            var re, match, filename, guideObj;

            re = /.+\/(.+?)\.pdf/;
            match = guideUrl.match(re);

            filename = '';
            if(match !== null){
                filename = match[1];
            }

            guideObj = {
                filename : filename,
                url : guideUrl
            };

            return guideObj;
        }

        function getRsuGuide(requestId, mainDevice, rsuDevice){

            var serial = mainDevice.serial;
            var productId = rsuDevice.Product.id;

            $rootScope.isGlobalLoading = true;
            alertBar.hide();

            if (requestId) {
                Wmoapi.getRsuGuideByRequestId(requestId, productId, serial).then(handleRsuResponse, handleRsuError);
            } else {
                Wmoapi.getRsuGuide(productId, serial).then(handleRsuResponse, handleRsuError);
            }

        }

        function handleRsuResponse(response) {

            $rootScope.isGlobalLoading = false;

            if (response.data.vehicles.length === 1) {
                window.WD_open_webbrowser(response.data.vehicles[0].url);
            } else {
                ngDialog.openConfirm({
                    template: 'modules/core/views/partials/guides/directives/rsuGuide.modal.client.directive.view.html',
                    className: 'warning-modal ngdialog-theme-plain',
                    showClose: true,
                    controller: ['$scope', function ($scope) {
                        $scope.vehicles = response.data.vehicles || [];
                        $scope.websiteUrl = response.data.websiteUrl;

                        $scope.openUrl = function (url) {
                            window.WD_open_webbrowser(url);
                        };
                    }]
                });
            }
        }

        function handleRsuError(response) {
            var error = response.data;
            $rootScope.isGlobalLoading = false;
            console.error(error);
            var error_msg = _.isEmpty(error) ? '' : ' [ ' + (error.error || error) + ' ]';
            alertBar.show('error', 'RSU_GUIDE_ERROR', {ERROR_MSG: error_msg});
        }

        return guidesService;
    }
]);
