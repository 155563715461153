'use strict';

angular.module('core').factory('checkAuth', ['$q', '$timeout', '$state',
    function($q, $timeout, $state) {

        return {
            redirectIfUnauthorized: function(authorized, toState) {
                var deferred = $q.defer();
                if (!authorized) {
                    $timeout(function() {
                        deferred.reject();
                        return $state.go(toState, {});
                    });
                } else {
                    deferred.resolve();
                }

                return deferred.promise;
            }
        };

    }
]);
