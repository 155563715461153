/**
 * Created by rafikgharsalli on 2018-01-24.
 */
(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsSirenModalController', PartialsSirenModalController);

    PartialsSirenModalController.$inject = ['$scope', 'Wmoapi', 'ngDialog', '$q', 'DeviceService'];

    function PartialsSirenModalController($scope, Wmoapi, ngDialog, $q, DeviceService) {

        var inputRegex = /^[0-9]{16}$/;
        var vm = this;
        vm.validateSirenCode = validateSirenCode;
        vm.setActivationCode = setActivationCode;
        vm.showErrorMessage = false;
        vm.isCodeValid = true;
        vm.isSubmitting = false;
        vm.userSelection = $scope.userSelection;
        vm.request = $scope.request;
        vm.responseData = $scope.responseData;

        function validateSirenCode(input) {
            var regRes = inputRegex.test(input);
            vm.inputClass = regRes  ? '' : 'has-error';
            vm.isCodeValid = !regRes;
        }

        function setActivationCode(input) {
            var detectedDevice = DeviceService.getFlashableDevice();
            var serial = detectedDevice.serial;
            vm.isSubmitting = true;
            Wmoapi.setActivationCode(serial, input).then(
                function(success) {
                    vm.showErrorMessage = false;
                    getAccessories(vm.request.id, vm.userSelection.selectedFirmware.id);
                }, function(error) {
                    vm.showErrorMessage = true;
                    vm.isSubmitting = false;
                    console.log('error in setActivationCode:',error);

                }
            );

        }

        function getAccessories(requestId, selectedFirmwareId) {

            var deferred = $q.defer();
            Wmoapi.getAccessories(requestId, selectedFirmwareId)
                .then(function(success) {
                    vm.userSelection.selectedAccessories = success.data;
                    vm.responseData.currentAccessories = success.data;
                    vm.isSubmitting = false;
                    ngDialog.closeAll();
                    deferred.resolve(success);
                }, function(error) {
                    vm.isSubmitting = false;
                    console.log('error in getAccessories:',error);
                    deferred.reject(error);
                });

            return deferred.promise;
        }
    }

})();
