(function () {
    'use strict';
    angular
        .module('core')
        .factory('InternalRemotes', ['$q', InternalRemotes]);

    function InternalRemotes($q) {
        var InternalRemotesService = {};
        var selectedRemotes = null;
        var selectedIds = {
            remoteId: ''

        };
          
        InternalRemotes.selectedIds = selectedIds;
        // setters
        InternalRemotes.setRemoteId = setRemoteId;
        // getters
        InternalRemotes.getRemoteId = getRemoteId;

        function setRemoteId(data) {
            selectedIds.remoteId = data;
        }

        function getRemoteId() {
            return selectedIds.remoteId;
        }

        return InternalRemotes;
    }
})();
