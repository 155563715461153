/**
 * Created by rafikgharsalli on 2017-03-13.
 */
(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsFirmwareUpdateSliderModalController', PartialsFirmwareUpdateSliderModalController);

    PartialsFirmwareUpdateSliderModalController.$inject = ['mainDeviceFirmwareInfo', 'embeddedDevicesFirmwareInfo', '$scope', 'dataService', 'constants'];

    function PartialsFirmwareUpdateSliderModalController(mainDeviceFirmwareInfo, embeddedDevicesFirmwareInfo, $scope, dataService, constants) {
        var vm = this;
        vm.mainDeviceFirmwareInfo = mainDeviceFirmwareInfo;
        vm.embeddedDevicesFirmwareInfo = embeddedDevicesFirmwareInfo;
        
        $scope.inputs = {};
        $scope.disabledBtn = false;

        if(mainDeviceFirmwareInfo) {
            $scope.inputs[mainDeviceFirmwareInfo.serial] = true;
        }
        
        embeddedDevicesFirmwareInfo.map(function(embeddedDevice){
            $scope.inputs[embeddedDevice.serial] = true;
        });

        if($scope.rsuDevice && dataService.rsuDeviceHasFwUpdate) {
            $scope.inputs[$scope.rsuDevice.serial] = true;
        }

        $scope.toggleItem = function(serial) {
            $scope.inputs[serial] = !$scope.inputs[serial];
        };

        
        $scope.$watch('inputs', function(newVal, oldVal) {
            if(Object.keys($scope.inputs).filter(function(item){ return $scope.inputs[item] === true; }).length) {
                $scope.disabledBtn = false;
            } else {
                $scope.disabledBtn = true;
            }
        }, true);

    }

})();
