/**
 * Created by rafikgharsalli on 2016-03-10.
 */

'use strict';

angular.module('core').directive('videosSection', ['videoContainer','$sce',
    function(videoContainer,$sce){
        return {
            scope:{
                videos:'='
            },
            restrict: 'AE',
            templateUrl:'modules/core/views/directives-partial/videos.section.html',
            controller: ['$scope', function($scope) {
                $scope.playlistPosition = 0;
                $scope.currentPage = 0;
                $scope.nextPage = nextPage;
                $scope.previousPage = previousPage;
                $scope.perPage = 3;
                $scope.pagesRequired = 1;
                $scope.itemsList = [];

                $scope.$watch('videos', function(videos) {
                    if (videos && videos.length > 0) {
                        $scope.pagesRequired = Math.ceil(videos.length / $scope.perPage);
                        var itemsList = [];
                        for (var i = 0; i < $scope.pagesRequired; i ++) {
                            itemsList.push(i);
                        }
                        $scope.itemsList = itemsList;

                        angular.forEach(videos, function(video) {
                            video.htmlVideoPlayer = $sce.trustAsResourceUrl('https://www.youtube.com/embed/' + parseVideoId(video.videoPlayer) + '?autoplay=' + false);
                        });
                    }
                });

                function parseVideoId (url) {
                    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/;
                    var match = url.match(regExp);
                    return match && match[7].length === 11 ? match[7] : false;
                }

                $scope.playVideo = function(index) {
                    $scope.playlistPosition = index;
                };

                $scope.getThumbnail = function(videoType, videoThumbnail) {
                    return videoType === '360' ? 'img/guides/videos/thumbnail_360.jpg' : videoThumbnail;
                };

                function nextPage() {
                    if ($scope.currentPage < ($scope.pagesRequired - 1)) {
                        $scope.currentPage ++;
                    }
                }

                function previousPage() {
                    if ($scope.currentPage > 0) {
                        $scope.currentPage --;
                    }
                }

            }]

        };
    }
]);
