(function() {
    'use strict';

    angular
        .module('core')
        .directive('externalRemotes', ExternalRemotes);

        function ExternalRemotes() {
            return {
                restrict: 'E',
                scope: {
                  // stuff in scope
                  remotes: '=',
                  userSelection: '=',
                  brand: '=',
                  selectExternalRemote: '&'
                },
                templateUrl: 'modules/core/views/directives-partial/externalRemotes.client.directive.view.html',
                controller: 'PartialsExternalRemotesController',
                controllerAs: 'ctrl'
            };
        }
})();
