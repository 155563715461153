'use strict';


angular.module('core').controller('ConsumeTokenHomeController', ['$scope', 'Wmoapi', '$rootScope',
    '$interval', '$window', 'translateService', '$stateParams', 'AppService',
    function($scope, Wmoapi, $rootScope,
        $interval, $window, translateService, $stateParams, AppService) {

        var waitInSeconds = 5;
        var redirectUrl = 'http://www.idatalink.com/weblink/';
        $scope.theMessage = 'TOKEN_MSG_LOGIN';
        $scope.isInWeblinkDesktop = AppService.isPramanthaObjectDetected();

        $scope.initConsumeTokenHome = function () {

            $scope.validateToken();

        };
        $scope.validateToken = function () {
            var tokenParam = $stateParams.token;
            var languageParam = $stateParams.language;
            var srcParam = $stateParams.src;
            var srcsiteParam = $stateParams.srcsite;

            $rootScope.globalUser = {};

            $scope.theMessage = 'TOKEN_MSG_LOGIN';
            $scope.redirectTimeInSeconds = waitInSeconds;

            //validate token
            if(!$scope.isTokenValid(tokenParam)){
                $scope.theMessage = 'TOKEN_MSG_TOKEN_INVALID';
                $scope.startCountdown(waitInSeconds);
            }

            //validate language
            if(!$scope.isLanguageValid(languageParam)){
                languageParam = 'En';
            }

            translateService.setCurrentLanguage(languageParam);

            Wmoapi
                .consumeToken(tokenParam, languageParam, srcsiteParam)
                .success(function(userProfile){

                    $rootScope.globalUser.profile = userProfile;

                    if (!angular.isDefined(srcParam)) {
                      srcParam = '';
                    }

                    var path = window.location.pathname;

                    switch (srcParam) {
                      case 'dashboard':
                        $window.location.href = path + '/dashboard';
                        break;
                      case 'maestro':
                        $window.location.href = path + '/maestro';
                        break;
                      case '':
                        $window.location.href = path;
                        break;
                      default:
                        $window.location.href = path;
                        break;
                    }
                })
                .catch(function(error){
                    $scope.theMessage = 'TOKEN_MSG_TOKEN_ERROR';
                    $scope.startCountdown(waitInSeconds);
                });

        };

        $scope.isTokenValid = function (param){
            var regExp =  new RegExp( /^[0-9a-z-_]{10,}$/i );
            return regExp.test(param);
        };

        $scope.isLanguageValid = function (param){
            var regExp =  new RegExp( /^[a-zA-Z]{2}$/i );
            return regExp.test(param);
        };

        var stopCount;
        $scope.startCountdown = function(waitTimeInSeconds){

            if (angular.isDefined(stopCount)) return;

            stopCount = $interval(function() {
                if (waitTimeInSeconds>0) {
                    $scope.redirectTimeInSeconds = waitTimeInSeconds;
                    waitTimeInSeconds--;
                } else {
                    $scope.stopCountdown();
                    if($scope.isInWeblinkDesktop) {
                        window.WD_go_home();
                    } else {
                        $window.location.href = redirectUrl;
                    }
                }
            }, 1000);

        };

        $scope.stopCountdown = function(){
            if (angular.isDefined(stopCount)) {
                $interval.cancel(stopCount);
                stopCount = undefined;
            }
        };

    }
]);
