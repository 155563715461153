'use strict';

angular.module('core').directive('firmwarePosts', [
    function() {

        function link($scope, $elem, attrs) {

            var theme = $scope.theme;
            var cssColor = '';
            var imgLink = 'blue';

            switch(theme){
                case 'white':
                    cssColor = 'wht';
                    imgLink = 'white';
                break;
                case 'modal':
                  imgLink = 'blue';
                break;
                default:
                    imgLink = 'blue';
                break;
            }

            $scope.link_cssColor = cssColor;
            $scope.link_imgLink = imgLink;
        }

        return {
            link: link,
            restrict: 'E',
            scope: {
                posts: '=posts',
                theme: '@theme'
            },
            templateUrl: function (element, attrs) {
              if(attrs.theme === 'modal')
                return 'modules/core/views/directives-partial/firmware.posts.modal.html';
              else
                return 'modules/core/views/directives-partial/firmware.posts.html';
            }
        };

    }
]);
