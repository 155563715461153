/**
 * Created by rafikgharsalli on 2016-04-20.
 */

'use strict';
angular.module('core').directive('componentContainer', [
    function() {
        return {
            scope: {
                tags: '='
            },
            restrict: 'E',
            templateUrl: 'modules/core/views/directives-partial/component.container.html'
        };
    }
]);
