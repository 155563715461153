/**
 * Created by rafikgharsalli on 2016-06-29.
 */
'use strict';
var app = angular.module('core');

angular.module('core').factory('trialBox', ['ngDialog','dataService',
    function(ngDialog,dataService){

        var trialBox = {};
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        dataService.activateTrialMode = false;

        /**
         * Show video container
         * @param link video link to show
         * @param videoThumbnail video poster
         * @param width
         * @param height
         *
         */
        trialBox.show = function() {

            ngDialog.openConfirm({
                template: 'modules/core/views/modals/flash.modal.client.view.html',
                className:'ngdialog-theme-plain',
                closeByNavigation: true,
                showClose: false,
                controller: ['$scope','ngDialog', function($scope, ngDialog) {

                    $scope.validateEmailInput = function (emailInput) {

                        $scope.inputClass = emailInput.match(emailRegex) ? '' : 'has-error';
                        dataService.activateTrialModeEmail = emailInput;

                    };

                    $scope.submit = function (checkbox) {

                        $scope.checkboxClass = checkbox ? '' : 'checkbox-error';

                        if( $scope.checkboxClass === '' && $scope.inputClass === '' ){
                            dataService.activateTrialMode = true;
                            ngDialog.closeAll();
                        }

                    };

                }]
            });
        };

        return trialBox ;
    }
]);
