'use strict';

angular.module('core').controller('PartialsDeviceDeviceController', ['$scope', '$rootScope', 'dataService', 'DeviceService','alertBar','AppService','ngDialog', 'deviceIdentifier',
    function ($scope, $rootScope, dataService, DeviceService, alertBar, AppService, ngDialog, deviceIdentifier) {
        $scope.flexSliderOpen = false;
        $rootScope.hideItAll = false;
        $scope.packageSelection = {};
        $scope.showPackageError = false;
        $scope.getFlexPackageClass = getFlexPackageClass;
        $scope.toggleFlexSlider = toggleFlexSlider;
        $scope.applyFlexUpgrade = applyFlexUpgrade;
        $scope.detectedDevices = [];
        $scope.detectedDeviceDetails = [];
        $scope.isClassicDevice = false;
        $scope.classicDeviceInstallOption = 'DETECTION_DEVICE_NOT_AVAILABLE';

        var wdStatus = AppService.getWeblinkDesktopStatus();

        $scope.$watch(
            function () {
                if(wdStatus && wdStatus.hasUpdate ){
                    alertBar.show('update', 'HOME.ALERT_BAR_WEBLINK_DESKTOP_HAS_UPDATE');
                }
                $scope.detectedDevices = DeviceService.getDevices();
                $scope.isClassicDevice =  $scope.detectedDevices.length > 0 ? DeviceService.isClassicDevice() : false;
                //determine classic install option:  standalone/addon
                if($scope.latestRequest && $scope.latestRequest.action && $scope.latestRequest.action.protocol) {
                    var p = $scope.latestRequest.action.protocol;
                    $scope.classicDeviceInstallOption = p.isRs === 1 ? 'INSTALLOPT_CHOICE_STANDALONE_INSTALLED_AS' : 'INSTALLOPT_CHOICE_ADDON_INSTALLED_AS';
                }

                return $scope.detectedDevices;
            });

        $scope.$watch('wdStatus.isCritical', function (newValue) {
            if ( wdStatus && typeof wdStatus.isCritical !== undefined  && wdStatus.isCritical === true) {
                showModalBoxBootloaderUpgrade();
                wdStatus = newValue;
            }
        }.bind(this));

        $scope.canModifyFeaturesBySerial = function (serial) {
            return deviceIdentifier.isConfigureModuleAllowed(DeviceService.getDeviceBySerial(serial).allowedServices);
        };

        function showModalBoxBootloaderUpgrade() {
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/messageBox/bootloaderUpgradeMessageBox.client.view.html',
                className: 'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false,
                controller: ['$scope', function ($scope) {
                    $scope.title = 'HOME.ALERT_BAR_WEBLINK_DESKTOP_HAS_UPDATE_TITLE';
                    $scope.message = 'HOME.ALERT_BAR_WEBLINK_DESKTOP_HAS_CRITICAL_UPDATE';
                    $scope.buttonTitle = 'HOME.ALERT_BAR_WEBLINK_DESKTOP_HAS_CRITICAL_UPDATE_BTN';
                    $scope.event = function () {
                        var url = AppService.getWeblinkDesktopStatus().url;
                        window.WD_open_webbrowser(url);
                    };
                }]
            }).then(function (result) {
                // user did something else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }

        function toggleFlexSlider() {
            $scope.flexSliderOpen = !$scope.flexSliderOpen;
            $rootScope.disableHeader = !$rootScope.disableHeader;

            if ($scope.flexSliderOpen) {
                $scope.showPackageError = false;
            } else {
                $scope.packageSelection = {};
            }
        }

        function getFlexPackageClass(index, length) {

            if (length === 1) {
                return 'col-sm-offset-3';
            }

            if (index % 2 === 0 && length > 1) {
                return 'border-right';
            }

            return '';
        }

        function applyFlexUpgrade() {
            var selectedPackage = $scope.packageSelection;
            if (selectedPackage.item) {

                $scope.$parent.flashForFirmwareUpdateOnly = false;
                var packageChangeId = $scope.packageSelection.item.packageChangeId;
                var flashForFlexUpgradeOnly = true;
                dataService.showTrialPopUp = false;
                $scope.$parent.userSelection.packageChangeId = packageChangeId;
                $scope.showPackageError = false;
                $scope.$parent.flashForKlonOnly = false;
                $scope.$parent.modifyFeaturesOnly = false;
                $scope.$parent.modifyControlsOnly = false;
                $rootScope.flashInProgress = true;
                $scope.$parent.loadFlashPartial(true, flashForFlexUpgradeOnly);

            } else {

                $scope.showPackageError = true;
            }

        }
    }
]);
