(function() {
    'use strict';
    angular
        .module('core')
        .factory('RSUData', ['$q','Wmoapi', '$rootScope', RSUData]);

      function RSUData($q, Wmoapi, $rootScope) {
          var RSUDataService = {};
          var brands = null;
          var brand = null;
          var rsu = null;

          var remotes = {};

          RSUDataService.clearRSUBrands = clearRSUBrands;
          // setters
          RSUDataService.setSelectedRsuBrand = setSelectedRsuBrand;
          RSUDataService.setSelectedRsu = setSelectedRsu;

         // getters
          RSUDataService.getSelectedRsu = getSelectedRsu;
          RSUDataService.getSelectedRsuBrand = getSelectedRsuBrand;
          RSUDataService.getRsuBrands = getRsuBrands;
          RSUDataService.getRemotes = getRemotes;

          function clearRSUBrands() {
            brand = null;
            brands = null;
            remotes = {};
            rsu = null;
          }

          function getRsuBrands(requestId, supportsInternalRemotes) {
            var deferred = $q.defer();

            if (brands && typeof brands !== 'undefined') {
              deferred.resolve(brands);
            } else {
              Wmoapi.getRsuRemoteBrands(requestId)
                .then(function(success) {
                  if (supportsInternalRemotes) {

                      var defaultName = 'iDatastart';
                      if ($rootScope.globalUser) {
                          defaultName += $rootScope.globalUser.profile ? ($rootScope.globalUser.profile.filterUsaOn ? ' (US)' : '' ) : '';
                      }
                      var iDataStartBrandArray = [{
                          id: 999999,
                          name: defaultName,
                          isIDataStart: true
                      }];

                    brands = iDataStartBrandArray.concat(success.data);
                  } else {
                    brands = success.data;
                  }
                  deferred.resolve(brands);
                }, function(error) {
                  deferred.reject(error);
                });
            }

            return deferred.promise;
          }

          function getRemotes(requestId, brandId) {
            var deferred = $q.defer();

            if (remotes && remotes[brandId]) {
              deferred.resolve(remotes[brandId]);
            } else {
              Wmoapi.getRsuRemotes(requestId, brandId).
                then(
                  function(success) {
                    remotes[brandId] = success.data;
                    deferred.resolve(remotes[brandId]);
                }, function(error) {
                  deferred.reject(error);
                }
              );
            }
            return deferred.promise;
          }

          function getSelectedRsuBrand() {
            return brand;
          }

          function setSelectedRsu(data) {
            rsu = data;
          }

          function getSelectedRsu() {
            return rsu;
          }

          function setSelectedRsuBrand(rsuBrand) {
            brand = rsuBrand;
          }

          return RSUDataService;
      }
  })();
