/**
 * Created by rafikgharsalli on 2017-10-13.
 */
//here we declare Constants for 'other' in makes,years,trims api response
(function () {
    'use strict';
    angular
        .module('core')
        .constant('constants', {
            'INTERNAL_USER_ROLE_ID' : '4',
            'RSU_BLANK_SERIAL': 'FFFFFFFFFF',
            'OTHER_VEHICLE_MAKE_ID': 81,
            'OTHER_VEHICLE_MODEL_ID': 1357,
            'OTHER_VEHICLE_YEAR': 9999,
            'USB': 'usb',
            'HUB': 'hub',
            'WEBLINK_UPDATER': 'weblink-updater',
            'DEMO_MODE': 'demo-mode',
            'ACTIVEX': 'activex',
            'DETECT_USB_ERROR' : 2,
            'CONNECT_ERROR': 3,
            'FACEBOOK_APP_ID': facebook_app_id // jshint ignore:line
        });


})();
