/**
 * Created by rafikgharsalli on 2016-04-08.
 */


'use strict';

angular.module('core').directive('imagesGallery', ['dataService',
    function(dataService){
        function link ($scope) {
            $scope.data = dataService;
        }
        return {
            scope:{
                images:'='
            },
            link: link,
            restrict: 'AE',
            templateUrl:'modules/core/views/directives-partial/images.gallery.html',
            controller: ['$scope', function($scope) {


            }]

        };
    }
]);