/**
 * Created by rafikgharsalli on 2017-09-27.
 */
(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsRsuBrandsModalController', PartialsRsuBrandsModalController);

    PartialsRsuBrandsModalController.$inject = ['$scope', '$rootScope'];

    function PartialsRsuBrandsModalController($scope, $rootScope) {
        var vm = this;

        $scope.redirectToVehicle = function() {
            $rootScope.globalSkipStep('vehicle.makes');

        };
    }

})();
