'use strict';

angular.module('core').controller('PartialsConfigurationSubmenuController', ['$scope',
	function($scope) {
        $scope.loadPartial = function(item){

            if($scope.manageSubMenu(item)) {
                $scope.$parent.loadConfigurationPartial(item, true);
            }
        };
        $scope.manageSubMenu = function (item){
            var selected = $scope.$parent.selectedVehicle;
             var isActive = (item.toLowerCase() === 'makes' && selected.makeId !=='' ||
                 item.toLowerCase() === 'years' && selected.year !== '' ||
                 item.toLowerCase() === 'models' && selected.modelId !== ''||
                 item.toLowerCase() === 'trims' && selected.vehicleId !== '');
            return isActive;
        };

	}
]);
