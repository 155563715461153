'use strict';

var app = angular.module('core');

angular.module('core').factory('messageBox', ['ngDialog',
    function(ngDialog){

      var messageBox = {};

        /**
         * Show message box
         * @param title string translation key
         * @param description string translation key
         */
        messageBox.show = function(title,description) {

            ngDialog.openConfirm({ template: 'modules/core/views/partials/messageBox/default.messageBox.client.view.html', className:'ngdialog-theme-plain', data:{title:title ,sousTitle:description}}).then(
                function(value) {
                    //TODO: save the modification
                    //ngDialog.open({template: '<div class="ngdialog-message"> Your modification has been saved!</div>',
                    //    plain: 'true'
                    //});
                },
                function(value) {
                    //TODO: Cancel or do nothing
                }
            );

        };

        return messageBox ;
    }
]);