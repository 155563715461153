/**
 * Created by rafikgharsalli on 2016-01-06.
 */

'use strict';

angular.module('core').factory('Wiringapi', ['$http', 'AppService', 
    function($http, AppService){

        var wiringapi = {};

        var headersNoCache = { headers: { 'Cache-Control':'no-cache, no-store, must-revalidate', 'Pragma':'no-cache', 'Expires':'0' } };


        wiringapi.getStatus = function(){

            return $http.get(AppService.getBaseUrl() + 'wiring/getStatus', headersNoCache);
        };

        /**
         * Get vehicle wire images
         * @param wireId int
         * @returns {HttpPromise}
         */
        wiringapi.imageVehicleBypass = function(wireId){

            return $http.get(AppService.getBaseUrl() + 'wiring/image/'+wireId, headersNoCache);
        };

        /**
         * Get vehicle wire images
         * @param vehicleId int
         * @returns {HttpPromise}
         */
        wiringapi.getImagesVehicleBypass = function(vehicleId){

            return $http.get(AppService.getBaseUrl() + 'wiring/image/vehicleBypass/'+vehicleId, headersNoCache);
        };

        /**
         * Get vehicle wire image tags
         * @param vehicleId int
         * @param imageId int
         * @param isRegularSize bool
         * @returns {HttpPromise}
         */
        wiringapi.tagVehicleBypass = function(vehicleId, imageId, isRegularSize){

            if(isRegularSize === undefined) {
                isRegularSize = true;
            }

            return $http.get(AppService.getBaseUrl() + 'wiring/tag/vehicleBypass/'+vehicleId+'/image/'+imageId+'/?width='+(isRegularSize?'800':'600'), headersNoCache);
        };

        wiringapi.validateImage = function(isRegularSize, fileName){

            if(isRegularSize === undefined) {
                isRegularSize = 1;
            }

            return $http.get(AppService.getBaseUrl() + 'wiring/validateImage/'+(isRegularSize?1:0)+'/'+fileName, headersNoCache);
        };

        /**
         * Get vehicle wires
         * @param vehicleId int
         * @returns {HttpPromise}
         */
        wiringapi.vehicleBypass = function(vehicleId){

            return $http.get(AppService.getBaseUrl() + 'wiring/vehicle/vehicleBypass/'+vehicleId, headersNoCache);
        };

        /**
         * Get vehicle images tags
         * @param vehicleId int
         * @returns {HttpPromise}
         */
        wiringapi.getImagesTagsVehicleBypass = function(vehicleId){

            return $http.get(AppService.getBaseUrl() + 'wiring/image-tags/vehicleBypass/'+vehicleId, headersNoCache);
        };


        return wiringapi;
    }

]);
