'use strict';

angular.module('core').controller('PartialsTelematicsTelematicsController',
    ['$scope', 'Wmoapi', 'messageBox', '$rootScope', 'alertBar', '$filter', 'AppService','DeviceService','deviceIdentifier',
        function ($scope, Wmoapi, messageBox, $rootScope, alertBar, $filter, AppService, DeviceService, deviceIdentifier) {

            var defaultImagePhone = 'img/telematics/phone/unlocked/snapshot/phone.png';
            $scope.switchAlternate = 'off';
            $scope.selectedTelematic = {id: $scope.$parent.userSelection.telematicId || 0, imagePhone: $scope.$parent.userSelection.telematicImagePhone || defaultImagePhone};
            var skipControls = $scope.$parent.skipControls;
            $scope.backSkipStep = skipControls ? 'vehicle.trims' : 'internal-remotes';

            var mainDevice = DeviceService.getFlashableDevice();
            var isDetectedDeviceIsClassic = deviceIdentifier.isDetectedDeviceIsClassic(mainDevice.productPartOptions);

            $scope.managePageNavigation = function () {

                var fId = $scope.getSelectedFirmwareId();
                $scope.hidePageNaviNextBtn = $scope.$parent.userSelection.telematicId === 0 || $scope.$parent.userSelection.telematicId === '';
                $scope.demo_mode = AppService.isDemoMode();
                $scope.show_wiring = $scope.$parent.showWiringSection;
            };

            $scope.initTelematics = function () {
                var selectedRemote = $scope.$parent.userSelection.selectedRemote.id ? $scope.$parent.userSelection.selectedRemote.id : 0;
                $scope.hasRemoteSelected = isDetectedDeviceIsClassic &&  parseInt(selectedRemote, 10) !== 0;
                $scope.hasOnlyOnePort = isDetectedDeviceIsClassic && parseInt(mainDevice.hardwareNumOfPorts, 10) === 1;
                $scope.managePageNavigation();
                $scope.getTelematics();
            };

            $scope.lockTelematicsList = function () {
                $scope.$parent.isTelematicsOn = false;
                $scope.$parent.userSelection.telematicId = '';
                $scope.selectedTelematic.id = 0;
                $scope.selectedTelematic.imagePhone = defaultImagePhone;
                alertBar.hide();
            };

            $scope.unlockTelematicsList = function () {

                $scope.$parent.isTelematicsOn = true;

                var telematics = $scope.$parent.responseData.telematics;
                if (telematics.length === 1) {
                    $scope.selectedTelematic.id = telematics[0].id;
                    $scope.selectedTelematic.imagePhone = telematics[0].images.phone;
                }
            };

            $scope.getTelematics = function () {

                var parentRequestId = $scope.$parent.request.id;
                var title = 'FIRMWARE_CONFIGURE_MODULE';
                var description = 'FIRMWARE_MUST_CONFIGURE';

                if (parentRequestId === '' || parentRequestId === undefined) {
                    messageBox.show(title, description);
                    $scope.$parent.loadDevicePartial();
                } else {

                    var fId = $scope.getSelectedFirmwareId();

                    if ($scope.$parent.userSelection.telematicId !== '') {
                        loadSelectedTelematics();
                    } else {
                        loadTelematics(parentRequestId);
                    }
                }

            };

            function loadSelectedTelematics() {
                if ($scope.$parent.responseData.telematics.length === 0) {
                    $scope.loadNextPartial();
                }

                $scope.selectedTelematic.id = $scope.$parent.userSelection.telematicId;
                $scope.selectedTelematic.imagePhone = $scope.$parent.userSelection.telematicImagePhone;
                $scope.switchAlternate = $scope.$parent.userSelection.telematicId === 0 ? 'off' : 'on';
                $scope.$parent.isTelematicsOn = $scope.$parent.userSelection.telematicId !== 0;

            }

            function loadTelematics(parentRequestId) {

                $rootScope.isGlobalLoading = true;
                $scope.switchAlternate = 'off';
                $scope.$parent.userSelection.telematicId = 0;
                $scope.selectedTelematic.id = '';
                $scope.selectedTelematic.imagePhone = defaultImagePhone;

                Wmoapi
                    .getTelematics(parentRequestId)
                    .success(function (response) {

                        var telematicsList = response;
                        $scope.$parent.responseData.telematics = telematicsList;
                        $scope.selectedTelematic.id = $scope.$parent.userSelection.telematicId;
                        $scope.selectedTelematic.imagePhone = $scope.$parent.userSelection.telematicImagePhone;

                        if (telematicsList.length === 0) {
                            handleNoTelematics(parentRequestId);
                        } else {
                            $rootScope.isGlobalLoading = false;
                        }
                    })
                    .error(function (error) {
                        alertBar.show('error', 'TELEMATICS_ERROR_GET_TELEMATICS');
                    });

            }

            function handleNoTelematics(parentRequestId) {

                $scope.$parent.userSelection.telematicId = 0;
                $scope.selectedTelematic.id = 0;
                $scope.selectedTelematic.imagePhone = defaultImagePhone;

                Wmoapi
                    .setTelematic(parentRequestId, 0)
                    .success(function (response) {

                        if(isDetectedDeviceIsClassic) {
                            getFirmwareFamilyNoProtocol();
                        } else {
                            $scope.loadNextPartial();
                        }

                    })
                    .error(function (error) {
                        alertBar.show('error', 'TELEMATICS_ERROR_NO_TELEMATIC_FOUND');
                    });
            }

            $scope.$watch('selectedTelematic.id', function (newValue) {

                if (newValue) {
                    var telematics = $scope.$parent.responseData.telematics;
                    var result = $filter('filter')(telematics, {id: newValue}, true);
                    if (result.length) {
                        $scope.selectedTelematic.imagePhone = result[0].images.phone;
                    }
                }

            }.bind(this));

            $scope.loadNextPartial = function () {
                $scope.$parent.loadFirmwarePartial(false);
            };

            $scope.saveSelectedTelematics = function () {

                $rootScope.isGlobalLoading = true;
                var parentRequestId = $scope.$parent.request.id;

                if ($scope.$parent.userSelection.telematicId !== $scope.selectedTelematic.id) {
                    $scope.$parent.userSelection.selectedFirmware = {};
                }

                $scope.$parent.userSelection.telematicId = $scope.selectedTelematic.id;
                var selectedTelematicId = $scope.$parent.userSelection.telematicId;

                if ($scope.$parent.userSelection.telematicId === '') {
                    $scope.$parent.userSelection.telematicId = 0;
                    $scope.selectedTelematic.id = 0;
                    $scope.selectedTelematic.imagePhone = defaultImagePhone;
                    $scope.$parent.userSelection.telematicName = '';
                    $scope.$parent.userSelection.telematicImagePhone = defaultImagePhone;
                    selectedTelematicId = 0;
                } else {
                    $scope.$parent.userSelection.telematicName = $scope.selectedTelematic.name;
                    $scope.$parent.userSelection.telematicImagePhone = $scope.selectedTelematic.imagePhone;
                }

                if ($scope.$parent.isTelematicsOn === true && selectedTelematicId === 0) {
                    alertBar.show('error', 'TELEMATICS_ERROR_SELECT_TELEMATICS');
                } if (isDetectedDeviceIsClassic && selectedTelematicId === 0) {
                    alertBar.show('error', 'TELEMATICS_ERROR_SELECT_TELEMATICS_CLASSIC');
                } else {
                    alertBar.hide();

                      saveSelectedTelematicName(selectedTelematicId);

                      Wmoapi
                          .setTelematic(parentRequestId, selectedTelematicId)
                          .success(function (response) {

                              if(isDetectedDeviceIsClassic) {
                                  getFirmwareFamilyWithProtocol();
                              } else {
                                  $scope.loadNextPartial();
                              }

                          })
                          .error(function (error) {
                              alertBar.show('error', 'TELEMATICS_ERROR_SET_TELEMATIC');
                          });
                  }
            };

            function getFirmwareFamilyWithProtocol() {

                Wmoapi
                    .getFirmwareFamilies($scope.$parent.request.id)
                    .success(function (response) {
                        $scope.$parent.responseData.vehicleFirmwareFamilies = response;
                        if (response.length > 0) {
                            var firmwareFamily = response[0];
                            setFirmwareFamily(firmwareFamily);
                        } else {
                            alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                        }
                    })
                    .error(function (error) {
                        console.error('error in getFirmwareFamilyWithProtocol:', error);
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_VEHICLE_FIRMWARE');
                    })
                    .finally(function () {
                        $rootScope.isGlobalLoading = false;
                    });

                $scope.$parent.responseData.vehicleFirmwareFamilies = null;
            }

            function getFirmwareFamilyNoProtocol() {
                Wmoapi
                    .getFirmwareFamiliesNoProtocol($scope.$parent.request.id)
                    .success(function (response) {
                        $scope.$parent.responseData.vehicleFirmwareFamilies = response;
                        if (response.length > 0) {
                            var firmwareFamily = response[0];
                            setFirmwareFamily(firmwareFamily);
                        } else {
                            alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                        }
                    })
                    .error(function (error) {
                        console.error('error in getFirmwareFamilyNoProtocol:', error);
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_GET_VEHICLE_FIRMWARE');
                    })
                    .finally(function () {
                        $rootScope.isGlobalLoading = false;
                    });

                $scope.$parent.responseData.vehicleFirmwareFamilies = null;
            }

            function setFirmwareFamily(firmwareFamily) {

                $scope.$parent.selectedVehicle.firmwareFamilyId = firmwareFamily.id;
                $scope.$parent.selectedVehicle.firmwareFamilyName = firmwareFamily.name;

                Wmoapi
                    .setFirmwareFamily($scope.$parent.request.id, firmwareFamily.id)
                    .success(function () {
                        $rootScope.isGlobalLoading = false;
                        $scope.loadNextPartial();
                    })
                    .error(function (error) {
                        $rootScope.isGlobalLoading = false;
                        console.error('error in setFirmwareFamily:', error);
                        alertBar.show('error', 'CONFIGURATION_TRIMS_ERROR_SET_FIRMWARE_FAMILY');
                    });
            }

            function saveSelectedTelematicName(selectedTelematicId) {
                var name = '';
                var imagePhone = defaultImagePhone;
                var result = $filter('filter')($scope.$parent.responseData.telematics, {id: selectedTelematicId}, true);
                if (result.length) {
                    name = result[0].name;
                    imagePhone = result[0].images.phone;
                }
                $scope.$parent.userSelection.telematicName = name;
                $scope.$parent.userSelection.telematicImagePhone = imagePhone;
            }

            $scope.$on('PartialsTelematics_OnSaveTelematicsFlashMode', function () {

                $scope.saveSelectedTelematics();

            });

            $scope.getSelectedFirmwareId = function () {

                var selectedFId = $scope.$parent.selectedVehicle.firmwareId;
                return (selectedFId) ? parseInt(selectedFId) : 0;

            };


            $scope.skipToGuides = function () {
                AppService.setFirmwareSkipped(true);
                $scope.$parent.loadGuidesPartial();
                $scope.$parent.sidebar.currentIndex = 1;
                $scope.menuArrow.showInVehicle = false;
            };
        }
    ]);
