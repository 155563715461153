  'use strict';

  angular.module('core').directive('agreements', ['showAgreementsModal',
    function (showAgreementsModal) {
      return {
        restrict: 'A',

        link: function ($scope, element) {
          element.on('click', function (e) {
            e.preventDefault();
            showAgreementsModal();
          });
        }
      };
    }
  ]).directive('terms', function() {
    return {
      restrict: 'E',
      link: function(scope, elm) {
        elm.on('click', function($event) {
          $event.preventDefault();
          if($event.target.href) {
            window.WD_open_webbrowser($event.target.href);
          }
          return false;
        });
      }
    };
  });
