'use strict';
angular.module('core').controller('PartialsFlashKeyreadingFlashController', ['keyreading', '$scope','$rootScope', '$q', '$interval', '$window', 'Wmoapi', 'Request', 'DeviceService',  'deviceDetect', 'wu', 'dataService', 'deviceIdentifier', 'AppService', '_',
    function (keyreading, $scope, $rootScope, $q, $interval, $window, Wmoapi, Request, DeviceService,  deviceDetect, wu, dataService, deviceIdentifier, AppService, _) {


        $scope.started = [];
        $scope.waitTime = 0;
        $scope.waitInterval = undefined;
        $scope.flashDone = false;
        $scope.keyreadingPercentage = 0;
        $scope.rfidPercentage = 0;
        $scope.accRfidFlashStarted = false;
        $scope.showAccRfidError = false;
        $scope.flashError = false;
        $scope.flashErrorMessage = '';
        $scope.keyreadingError = false;
        $scope.keyreadingErrorMessage = '';
        $scope.klonError = false;
        $scope.klonErrorMessage = '';
        $scope.klonErrorMessageParams = '';
        $scope.accRfidFlashDone = false;
        $scope.keyreadingDone = false;
        $scope.rfidRequestId = undefined;
        $scope.rfidDownload = undefined;
        $scope.showWaitTime = false;
        $scope.showRedetectInError = false;
        $scope.steps = [];
        $scope.selectedNavButton = 0;
        $scope.accRfidSkipped = false;
        $scope.accRfidDeviceName = 'ACC-RFID';
        $scope.numberOfDots = new Array(($scope.$parent.klonSteps.length % 5) + 1);


        $scope.progressBarCompleteStyle = {
            'margin-left': '100%'
        };

        $scope.progressBarPercentageCompleteStyle = {
            'left': 'calc(100% - 8px)'
        };

        $scope.progressBarCompleteGraphStyle = {
            'width': '100%'
        };

        $rootScope.$on('keyreadingError', function (event, message) {
            showKeyreadingError(message);
        });

        $rootScope.$on('flashError', function (event, message) {
            showFlashError(message);
        });

        $rootScope.$on('klonError', function (event, message, messageParams) {
            showKlonError(message, messageParams);
        });

        //Watcher to start flashing without user input when previous step completed
        $scope.$parent.$watch('mostRecentCompletedStep.stepNumber', function(value){
            var flashStepNumber;
            if (_.some($scope.$parent.klonSteps, ['canonicalName', 'flash-main-module'])) {
                flashStepNumber = parseInt(_.find($scope.$parent.klonSteps, ['canonicalName', 'flash-main-module']).stepOrder, 10);
                if(value === flashStepNumber - 1) {
                    $scope.startMainModuleFlash(flashStepNumber);
                    $scope.navProgress(flashStepNumber);
                }
            }
            if (_.some($scope.$parent.klonSteps, ['canonicalName', 'flash-main-module-step1'])) {
                flashStepNumber = parseInt(_.find($scope.$parent.klonSteps, ['canonicalName', 'flash-main-module-step1']).stepOrder, 10);
                if(value === flashStepNumber - 1) {
                    $scope.startMainModuleFlash(flashStepNumber);
                    $scope.navProgress(flashStepNumber);
                }
            }
            if (_.some($scope.$parent.klonSteps, ['canonicalName', 'flash-main-module-step2'])) {
                flashStepNumber = parseInt(_.find($scope.$parent.klonSteps, ['canonicalName', 'flash-main-module-step2']).stepOrder, 10);
                if(value === flashStepNumber - 1) {
                    $scope.doKlonFlash(flashStepNumber);
                    $scope.navProgress(flashStepNumber);
                }
            }
        });

        $scope.clickNavButton = function(buttonIndex, keepCurrent) {

            var keyreadingElement = document.getElementsByClassName('keyreading-content-element')[0].getBoundingClientRect().width;
            var scrollLength = keepCurrent ? keyreadingElement * buttonIndex - keyreadingElement : keyreadingElement * buttonIndex;

            document.getElementsByClassName('keyreading-content')[0].scrollTo(scrollLength, 0);
            $scope.selectedNavButton = buttonIndex;
        };

        $scope.navProgress = function (stepNumber) {
            if(stepNumber % 5 === 0){
                $scope.clickNavButton(stepNumber, false);
            }
        };

        function showFlashError(message){
            $scope.showRedetectInError = false;
            if (!message) {
                message = 'KEYREADING_STEP2_ERROR_MESSAGE';
            } else if (!angular.isString(message)) {
                $scope.showRedetectInError = true;
                message = message.error || message;
            }
            $scope.flashError = true;
            $scope.flashErrorMessage = message;
            if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                $scope.$apply();
            }
        }

        function showKeyreadingError(message){
            $scope.showRedetectInError = false;
            if (!message) {
                message = 'KEYREADING_STEP2_ERROR_MESSAGE';
            } else if (!angular.isString(message)) {
                $scope.showRedetectInError = true;
                message = message.error || 'KEYREADING_NETWORK_ERROR_MESSAGE';
            }
            $scope.keyreadingError = true;
            $scope.keyreadingErrorMessage = message;
            if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                $scope.$apply();
            }
        }

        function showKlonError(message, messageParams){
            $scope.klonError = true;
            $scope.klonErrorMessage = message;
            var error_msg = _.isEmpty(messageParams) ? '' : ' [ ' + (messageParams.error || messageParams) + ' ]';
            $scope.klonErrorMessageParams = {ERROR_MSG: error_msg};

            if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                $scope.$apply();
            }
        }

        $rootScope.$on('keyreadingProgress', function (event, progress) {
            $scope.keyreadingProgressBarGraphStyle = {
                'width': Math.floor(progress) + '%'
            };

            $scope.keyreadingPercentageStyle = {
                'left': 'calc(' + Math.floor(progress) + '% - 12px)'
            };
            $scope.keyreadingPercentage =  Math.floor(progress);


        });

        $rootScope.$on('flashProgress', function (event, progress) {
            $scope.percentage = progress;
            $scope.progressBarPercentageStyle = {
                'margin-left': $scope.percentage + '%'
            };

            $scope.progressBarPercentageKeyreadingStyle = {
                'left': 'calc(' + $scope.percentage + '% - 12px)'
            };

            $scope.progressBarGraphStyle = {
                'width': $scope.percentage + '%'
            };


        });

        $rootScope.$on('klonProgress', function (event, progress) {
            $scope.klonPercentage = progress;
            $scope.klonProgressBarPercentageStyle = {
                'margin-left': $scope.klonPercentage + '%'
            };

            $scope.klonProgressBarPercentageKeyreadingStyle = {
                'left': 'calc(' + $scope.klonPercentage + '% - 8px)'
            };

            $scope.klonProgressBarGraphStyle = {
                'width': $scope.klonPercentage + '%'
            };


        });

        $rootScope.$on('keyreadingWaitTime', function (event, waitTime) {
            $scope.waitTime = waitTime;
            $scope.showWaitTime = waitTime >= 15;
            if ($scope.waitInterval) {
                $interval.cancel($scope.waitInterval);
            }
            if ($scope.waitTime > 0) {
                $scope.waitInterval = $interval(function () {
                    $scope.waitTime = $scope.waitTime - 1;
                    if ($scope.waitTime === 0) {
                        $scope.showWaitTime = false;
                        $interval.cancel($scope.waitInterval);
                    }
                }, 1000);
            }
        });

        $scope.refreshPage = function () {
            $window.location.reload(true);
        };

        $scope.startAccRfidFlash = function () {
            var accRfidStepNumber =   parseInt(_.find($scope.$parent.klonSteps, ['canonicalName', 'flash-acc-rfid']).stepOrder, 10);

            $scope.started[accRfidStepNumber] = true;
            $scope.started[accRfidStepNumber -1] = true;
            $scope.$parent.mostRecentCompletedStep.stepNumber = accRfidStepNumber -1;
            $scope.accRfidFlashStarted = true;
            $scope.showAccRfidError = false;

            deviceDetect.detect().then(
                function (success) {
                    DeviceService.setDevices(success);
                    var device = DeviceService.getFlashableDevice();

                    if(deviceIdentifier.isDetectedDeviceVehicleDependantOnMainDevice(device.productPartOptions)) {
                        $scope.accRfidDeviceName = device.productName;
                        flashAccRfid();
                    }else {
                        $scope.showAccRfidError = true;
                        $scope.started[accRfidStepNumber] = false;
                        $scope.started[accRfidStepNumber -1] = true;
                        $scope.accRfidFlashStarted = false;
                    }
                }, function (error) {
                    $scope.showAccRfidError = true;
                    $scope.started[accRfidStepNumber] = false;
                    $scope.started[accRfidStepNumber -1] = true;
                    $scope.accRfidFlashStarted = false;
                }
            );
        };

        function flashAccRfid() {
            var parentRequestId = $scope.$parent.request.parentRequestId || $scope.$parent.request.id;
            var device = DeviceService.getFlashableDevice();
            $scope.showAccRfidError = false;
            Wmoapi
                .createRequestFlashByFw(device.bootloader.version, device.serial, parentRequestId)
                .success(function (response) {
                    var requestId = response.requestId;
                    $scope.rfidRequestId = requestId;
                    DeviceService.setDeviceToFlash(device);
                    //so here we download
                    //flashBADevice(requestId);
                    Wmoapi
                        .download(requestId)
                        .success(function (response) {
                            var codeDownload = response;
                            $scope.rfidDownload = response;
                            $scope.antennaCreateFlashProcess(codeDownload, confirmConfigRfid, rfidError);
                        })
                        .error(function (error) {
                            $scope.showAccRfidError = true;
                            $scope.accRfidFlashStarted = false;
                        });
                })
                .error(function (error) {
                    $scope.showAccRfidError = true;
                    $scope.accRfidFlashStarted = false;
                });
        }

        $scope.antennaCreateFlashProcess = function (antennaDownload, onComplete, onError) {
            $rootScope.isGlobalLoading = false;
            $scope.showAntennaStep = false;
            $scope.$parent.apply();
            flashDevice(antennaDownload, onComplete, onError, $scope.rfidFlashProgress);
        };

        function confirmConfigRfid () {
            var configRequestId = $scope.rfidRequestId;
            var downloadId = $scope.rfidDownload.downloadId;


            Wmoapi
                .confirm(configRequestId, downloadId)
                .success(function () {
                    $rootScope.isGlobalLoading = false;
                    Request.clearAllRequestIds();
                    $scope.accRfidFlashDone = true;
                    $scope.$parent.mostRecentCompletedStep.stepNumber = parseInt(_.find($scope.$parent.klonSteps, ['canonicalName', 'flash-acc-rfid']).stepOrder, 10);

                })
                .error(function (error) {
                    console.log(error);
                });
        }

        function rfidError() {
            $scope.showAccRfidError = true;
        }

        $scope.startKeyReading = function () {
            var keyReadingStepNumber = parseInt(_.find($scope.$parent.klonSteps, ['canonicalName', 'key-reading']).stepOrder, 10);
            var hubSerial = AppService.getHubSerial();
            var parentRequestId = $scope.$parent.request.klonRequestId || $scope.$parent.request.id;
            $scope.keyreadingError = false;
            $scope.started[keyReadingStepNumber] = true;
            if (hubSerial) {
                keyreading.readKeyEncrypted(parentRequestId, hubSerial)
                    .then(keyreading.stopSecureSession)
                    .then(function(){
                        $scope.keyreadingDone = true;
                        $scope.$parent.mostRecentCompletedStep.stepNumber = keyReadingStepNumber;
                    }, function (error){
                        showKeyreadingError(error);
                        throw error;
                    }).catch(keyreading.stopSecureSession);
            } else {
                showKeyreadingError('HUB_SERIAL_NOT_DETECTED');
            }
        };

        $scope.klonFlashError = function (e) {
            console.log(e);
            showFlashError('FLASH_KLON_ERROR_FLASHING');
        };

        $scope.startMainModuleFlash = function (stepNumber) {
            $scope.started[parseInt(stepNumber, 10)] = true;
            $scope.flashError = false;
            flash();
        };

        $scope.confirmVehicleReadings = function (stepNumber) {
            $scope.started[parseInt(stepNumber, 10)] = true;
            $scope.$parent.mostRecentCompletedStep.stepNumber = parseInt(stepNumber, 10);
        };
        $scope.skipAccRfid = function (stepNumber) {
            $scope.started[parseInt(stepNumber, 10)] = true;
            $scope.started[parseInt(stepNumber, 10) + 1] = true;
            $scope.$parent.mostRecentCompletedStep.stepNumber = parseInt(stepNumber + 1, 10);
            $scope.accRfidSkipped = true;
            $scope.showAccRfidError = false;
        };
        $scope.startKlonReadings = function (stepNumber) {
            $scope.started[parseInt(stepNumber, 10)] = true;
            $rootScope.$broadcast('klonProgress', 0);
            $scope.klonError = false;
            deviceDetect.detect().then(
                function (success) {
                    DeviceService.setDevices(success);
                    $scope.$parent.getSuspendedRequest();

                }, function (error) {
                    $scope.started[parseInt(stepNumber, 10)] = false;
                    showKlonError(error.errorMsg || 'DETECT_NO_DEVICE');
                }
            );

        };

        $scope.doKlonFlash = function (stepNumber) {
            $scope.started[parseInt(stepNumber, 10)] = true;
            $scope.flashError = false;
            klonDownload();
        };

        function klonDownload () {
            $scope.percentage = 0;
            var klonRequestId = $scope.$parent.request.klonRequestId;


            Wmoapi
                .download(klonRequestId)
                .success(function (response) {
                    $scope.$parent.responseData.klonDownload = response;
                    flashDevice(response, $scope.$parent.klonFlashComplete, $scope.klonFlashError, $scope.$parent.klonFlashProgress);

                })
                .error(function (error) {
                    console.error('error in klonDownload:',error);
                    showFlashError('FLASH_KLON_ERROR_DOWNLOAD');
                });
        }

        function flash () {
            $rootScope.isGlobalLoading = false;
            $scope.$parent.showHasFlexPackageNoBillNotificationBar = false;
            if($scope.warningModal) {
                $scope.warningModal.close();
                $scope.closeDeviceNotificationBar();
            }
            $scope.progress.message = 'FLASH_PROGRESS_TITLE_ONE_STEP';
            DeviceService.setDeviceToFlash(DeviceService.getMainDevice());
            $scope.disableFlashButton = true;
            $scope.$parent.flashParameters.isNewModule = true;
            download();
        }

        function download () {
            var parentRequestId = $scope.$parent.request.id;
            Wmoapi
                .download(parentRequestId)
                .success(function (response) {
                    //download required code, configModule, configRemotes
                    //then start flash process
                    if (response.status === 'success') {
                        $scope.flashing = true;
                        $rootScope.flashInProgress = true;
                        $scope.percentage = 0;
                        $scope.$parent.responseData.firmwareDownload = response;
                        downloadConfigModule();
                    } else {
                        $rootScope.isGlobalLoading = false;
                        $scope.disableFlashButton = false;
                        showFlashError(angular.isString(response.messages[0]) ? response.messages[0] : 'FLASH_ERROR_DOWNLOAD');
                    }
                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    $scope.disableFlashButton = false;
                    showFlashError('FLASH_ERROR_DOWNLOAD');
                });

        }

        function downloadConfigModule() {

            var configModuleRequestId = $scope.$parent.request.configModuleRequestId;

            Wmoapi
                .download(configModuleRequestId)
                .success(function (response) {
                    $scope.$parent.responseData.configModuleDownload = response;
                    $scope.flashStart();
                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    $scope.disableFlashButton = false;
                    showFlashError('FLASH_ERROR_DOWNLOAD');
                });

        }
        $scope.flashStart = function () {
            $scope.percentage = 0;
            flashDevice($scope.$parent.responseData.firmwareDownload, $scope.$parent.flashComplete, $scope.$parent.flashError, $scope.$parent.flashProgress);
        };

        $scope.flashRestart = function () {
            deviceDetect.detect().then(
                function () {
                    if($scope.warningModal) {
                        $scope.warningModal.close();
                        $scope.closeDeviceNotificationBar();
                    }
                    $scope.disableFlashButton = true;
                    $scope.flashing = true;
                    $scope.percentage = 0;
                    flashDevice($scope.$parent.responseData.firmwareDownload, $scope.$parent.flashComplete, $scope.$parent.flashError, $scope.$parent.flashProgress);
                }, function (error) {
                    showFlashError('DETECT_NO_DEVICE');
                    $scope.disableFlashButton = false;
                    $rootScope.isGlobalLoading = false;
                }
            );

        };

        function flashDevice(firmwareDownload, onSuccess, onError, onProgress) {
            var device = DeviceService.getFlashableDevice();
            var isRsuDevice = device && DeviceService.isRsuDevice(device);
            wu.flashDevice(device, firmwareDownload, isRsuDevice)
                .then(function (success) {
                    onSuccess(success);
                }, function (error) {
                    onError(error);
                }, function (update) {
                    onProgress(update);
                }).finally(function () {
                $scope.disableFlashButton = false;
            });
        }
        $scope.rfidFlashProgress = function (perc) {
            $rootScope.isGlobalLoading = false;
            $scope.rfidPercentage = Math.floor(perc);

            $scope.progressBarPercentageRfidStyle = {
                'left': 'calc(' + $scope.rfidPercentage + '% - 8px)'
            };

            $scope.rfidProgressBarGraphStyle = {
                'width': $scope.rfidPercentage + '%'
            };
        };



    }
]);
