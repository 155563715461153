'use strict';

angular.module('core').factory('configuration', [
    function() {

        var submenuItems = [
            'Makes',
            'Years',
            'Models',
            'Trims'];
        return {
            getSubmenuItems: function(){
                return submenuItems;
            },
            getSubmenuMake: function(){
                return submenuItems[0];
            },
            getSubmenuYear: function(){
                return submenuItems[1];
            },
            getSubmenuModel: function(){
                return submenuItems[2];
            },
            getSubmenuTrim: function(){
                return submenuItems[3];
            }
        };

    }
]);
