/**
 * Created by rafikgharsalli on 2016-04-12.
 */
'use strict';
angular.module('core').directive('imagesGalleryInfo', ['dataService',
    function(dataService){
        function link ($scope) {
            $scope.data = dataService;
        }
        return {
            link: link,
            restrict: 'AE',
            templateUrl:'modules/core/views/directives-partial/images.gallery.info.html'
        };
    }
]);