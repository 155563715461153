
'use strict';

angular.module('core').factory('showAgreementsModal', ['ngDialog', 'Wmoapi', 'AppService', '$rootScope', '$state', 
  function(ngDialog, Wmoapi, AppService, $rootScope, $state) {
    return function(mustAgree) {
      ngDialog.open({
        template: 'modules/core/views/directives-partial/agreementsModal.html',
        className: 'ngdialog-theme-plain legal-dialog',
        closeByDocument: !mustAgree,
        controller: ['$scope', function ($scope) {
          var isInWeblinkDesktop = AppService.isPramanthaObjectDetected();
          $scope.mustAgree = mustAgree;
          $scope.message = null;

          Wmoapi.getAgreements()
              .then(function(success) {
                  var response = success.data;
                  if (response && response.agreements) {
                      $scope.message = response.agreements.map(function (doc) {
                          if(!!window.TextEncoder) {
                              return new TextDecoder().decode(Uint8Array.from(atob(doc.content), function (c){return c.charCodeAt(0);}));
                          } else {
                              return atob(doc.content);
                          }
                      }).join('');
                  }
              }, function(error) {
                  console.log('error in getAgreements:', error);
              });

          $scope.close = function () {
            $scope.closeThisDialog();
          };

          $scope.agree = function () {
            Wmoapi.acceptAgreement();
            $scope.closeThisDialog();
          };

          $scope.back = function () {
            if(!isInWeblinkDesktop) {
              Wmoapi.logout().then(function () {
                  $rootScope.globalUser = null;
                  $state.go('core.login');

              });
            } else {
              window.WD_go_home();
            }
            $scope.closeThisDialog();
          };
        }]
      });
    };
  }
]);
