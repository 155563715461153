/**
 * Created by rafikgharsalli on 2016-01-26.
 */
'use strict';

angular.module('core').factory('dataService', ['Wiringapi','$rootScope', '$q',
    function(Wiringapi,$rootScope, $q) {

        var dataService = {};
        var wiringData = {};
        var vehicleId;

        /*
        only make api call if it's a different bypass
        */
        dataService.getWiresByVehicleBypassId = function(vehicleBypassId) {
            var deferred = $q.defer();
            if (wiringData.vehicle_bypass_id !== parseInt(vehicleBypassId)) {
                getWires(vehicleBypassId);
            } else {
                deferred.resolve(wiringData);
            }

            function getWires() {
                Wiringapi
                    .vehicleBypass(vehicleBypassId)
                    .then(function (response) {
                        wiringData = response.data;
                        deferred.resolve(response.data);
                    }, function(error) {
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        };

        dataService.validateImage = function (vehicleId,isResized,fileName,imageId,wireId,connectorId,componentId,activeTab) {

                Wiringapi
                    .validateImage(isResized,fileName)
                    .success(function (response) {
                        dataService.activeTab = activeTab;
                        dataService.applyTags(vehicleId,imageId,isResized,wireId,connectorId,componentId,response);
                    })
                    .error(function (error) {
                        dataService.ErrorMessage = false;
                        dataService.showContent = false;
                        dataService.image_url = '';
                        $rootScope.isGlobalLoading = false;
                });
            };

        dataService.applyTags = function (vehicleId,imageId,isResized,wireId,connectorId,componentId,image_url) {
            Wiringapi
                .tagVehicleBypass(vehicleId,imageId,isResized)
                    .success(function (response) {
                        dataService.showContent = true;
                        dataService.ErrorMessage = true;
                        dataService.imageTags = response;
                        dataService.image_url = image_url;
                        dataService.checkTagType (response.tags);
                        dataService.validateConnectorName (response.tags,wireId,connectorId,componentId);

                    })
                    .error(function (error) {
                        dataService.ErrorMessage = false;
                        dataService.showContent = false;
                    }).finally(function () {
                    $rootScope.isGlobalLoading = false;
            });
        };

        dataService.checkTagType = function (response) {
            dataService.wireFound = false;
            dataService.connectorFound = false;
            dataService.componentFound = false;

            if (response) {
                angular.forEach(response, function (value, key) {
                    if (value.tag_type === 'WIRE') {
                        dataService.wireFound = true;
                    }
                    if (value.tag_type === 'CONNECTOR') {
                        dataService.connectorFound = true;
                    }
                    if (value.tag_type === 'COMPONENT') {
                        dataService.componentFound = true;
                    }
                });
            }

        };

        dataService.validateConnectorName = function (response,wireId,connectorId,componentId) {

            var inputComponent = null;
            dataService.componentName = '';
            dataService.connectorName = '';
            var found = false;

            if (response) {
                angular.forEach(response, function (value, key) {
                    if (!found) {
                        if (dataService.wireFound && value.wire_id === wireId) {
                            inputComponent = response[key].component;
                            dataService.connectorName = value;
                            dataService.componentName = (inputComponent.toUpperCase().substr(0, 7) === 'UNNAMED') ? 'N/A' : inputComponent;
                            found = true;

                        } else if (dataService.connectorFound && !dataService.wireFound && value.connector_id === connectorId) {

                            inputComponent = response[key].component;
                            dataService.connectorName = value;
                            dataService.componentName = (inputComponent.toUpperCase().substr(0, 7) === 'UNNAMED') ? 'N/A' : inputComponent;
                            found = true;

                        } else if (dataService.componentFound && !dataService.connectorFound && !dataService.wireFound && value.component_id === componentId) {

                            inputComponent = response[key].component;
                            dataService.componentName = (inputComponent.toUpperCase().substr(0, 7) === 'UNNAMED') ? 'N/A' : inputComponent;
                            found = true;

                        }
                    }
                });
            }

        };

    return dataService;
    }
]);
