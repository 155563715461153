'use strict';
/**
 * Created by rafikgharsalli on 2015-07-14.
 */
angular.module('core').directive('moreLess', [
    function(){

       // function link($scope, $elem, attrs) {

          //  var icon = $scope.icon;
          //  var iconMore = 'fa-plus';
          //  var iconLess = 'fa-minus';

            // switch(icon){
            //     case 'arrow':
            //         iconMore = 'fa-chevron-down';
            //         iconLess = 'fa-chevron-up';
            //         break;
            //     default:
            //         iconMore = 'fa-plus';
            //         iconLess = 'fa-minus';
            //         break;
            // }

           // $scope.link_iconMore = iconMore;
           // $scope.link_iconLess = iconLess;

       // }

    return {
       // link: link,
        restrict:'AE',
        scope:{
            hmtext : '@',
            hmlimit : '@',
            hmfulltext:'@',
            hmMoreText:'@',
            hmLessText:'@',
           // hmMoreClass:'=',
           // hmLessClass:'@',
           // icon:'@'
        },
        templateUrl:'modules/core/views/partials/features/directives/moreLessDescription.client.view.html',
        transclude: true,
        controller: ['$scope', function($scope) {
            $scope.toggleValue=function(){
                //Inverts hmfulltext flag to either TRUE/FALSE on each click
                $scope.hmfulltext = !$scope.hmfulltext;
            };
        }]
    };

    }
]);
