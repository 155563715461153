'use strict';

angular.module('core').controller('PartialsConfigurationYearsController', ['$scope', 'Wmoapi', 'configuration','$rootScope', 'RSUData','constants', 'alertBar',
	function($scope, Wmoapi, configurationFactory, $rootScope, RSUData, constants, alertBar) {

        $scope.initVehicleYears = function(){
            $scope.otherYearId = constants.OTHER_VEHICLE_YEAR;
            $scope.managePageNavigation();
            $scope.manageContent();
        };

        $scope.managePageNavigation = function() {
            var selectedYear = $scope.$parent.selectedVehicle.year;
            var yr = (selectedYear) ? parseInt(selectedYear) : 0;
            $scope.hidePageNaviNextBtn = (yr === 0);
        };

        $scope.manageContent = function() {

            var selectedYear = $scope.$parent.selectedVehicle.year;
            var yr = (selectedYear) ? parseInt(selectedYear) : 0;

            if (yr === 0) {
                $scope.getVehicleYears();
            } else {
                $scope.manageSubmenu();
            }
        };

        $scope.getVehicleYears = function (){

            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getVehicleYears($scope.$parent.request.id,
                    $scope.$parent.selectedVehicle.makeId,
                    $scope.$parent.configurationParameters.showAllMakes)
                .success(function(response){
                    $scope.$parent.responseData.vehicleYears = response;
                    $scope.manageSubmenu();
                })
                .error(function(error){
                    alertBar.show( 'error', 'CONFIGURATION_YEARS_ERROR_GET_YEARS' );
                })
                .finally(function(){
                    $rootScope.isGlobalLoading = false;
                });

            $scope.$parent.responseData.vehicleYears = null;
        };

        $scope.manageSubmenu = function(){
            $scope.$parent.submenu.selectedItem = configurationFactory.getSubmenuYear();
        };

        $scope.loadVehicleModelsPage = function (yr, yrName, isAnalogic) {

            var nextStep =  isAnalogic ? 'Trims' : 'Models';


            var tmp = $scope.$parent.selectedVehicle.year;
            $scope.$parent.prevSelectedVehicle.year = tmp === '' ? yr : tmp;

            if(tmp !== yr) {
                clearPrevVehicleSelections();
								RSUData.clearRSUBrands();
            }

            $scope.$parent.selectedVehicle.year = yr;
            $scope.$parent.selectedVehicle.yearName = yrName;


            if (isAnalogic) {
                $scope.$parent.selectedVehicle.modelId = 0;
            }

            $scope.$parent.loadConfigurationPartial(nextStep, true);

        };


        function clearPrevVehicleSelections() {
            var v = $scope.selectedVehicle;
            v.modelId = '';
            v.modelName = '';
            v.modelImage = '';
            v.vehicleId = '';
            v.vehicleName = '';
            v.firmwareFamilyId = '';
            v.firmwareFamilyName = '';
            v.firmwareId = '';
        	v.firmwareName = '';

			$scope.$parent.userSelection.telematicId = '';
			$scope.$parent.userSelection.telematicName = '';
			$scope.$parent.userSelection.telematicImagePhone = '';
			$scope.$parent.userSelection.selectedFirmware = {};

            var p = $scope.prevSelectedVehicle;
            p.modelId = '';
            p.vehicleId = '';
            p.firmwareFamilyId = '';
            p.firmwareId = '';
        }
	}
]);
