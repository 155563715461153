(function() {
    'use strict';

    angular
        .module('core')
        .directive('pageNavigation', ['$rootScope',function($rootScope) {
            return {
                restrict: 'E',
                scope: {
                    hideBackBtn: '@hidebackbtn',
                    hidenextbtn: '=',
                    backSkipStepName: '@backskipstep',
                    nextSkipStepName: '@nextskipstep'
                },
                templateUrl: 'modules/core/views/directives-partial/page.navigation.html',
                link: function(scope) {
                    scope.skipstep = function (skipTo) {
                        $rootScope.globalSkipStep(skipTo);
                    };

                }
            };

        }]);

})();
