/**
 * Created by Malcolm Laing on 2017-07-28.
 */
'use strict';


angular.module('core').controller('PartialsInternalRemotesController', ['$scope', 'Wmoapi', 'messageBox', 'alertBar', '$rootScope', 'RSUData', 'ngDialog',
    'DeviceService','deviceIdentifier','AppService',
    function($scope, Wmoapi, messageBox, alertBar, $rootScope, RSUData, ngDialog, DeviceService, deviceIdentifier, AppService) {

    var vm = this;
    var requestId = $scope.request.id;
    vm.saveBtnTitle = 'CONTROLS_SAVE_REMOTES_BUTTON_GO_TO';

    // functions
    vm.addRemote = addReservedRemote;
    vm.openDialog = openDialog;
    vm.removeSelectedRemote = removeSelectedRemote;
    vm.submit = submitForm;
    vm.maxLimit = 4;

    vm.isGlobalLoading = $rootScope.isGlobalLoading;

    $scope.$watch(function() {
      return $rootScope.isGlobalLoading;
    }, function() {
      vm.isGlobalLoading = $rootScope.isGlobalLoading;
    }, true);

    $scope.$on('PartialsControls_OnSaveRemotesFlashMode', function () {
      submitForm();
    });


    // view functions

    function addReservedRemote(item) {

        alertBar.hide();

        var esn = (item.esn) ? item.esn.toUpperCase() : '';
        var index = $scope.selectedRemotes.indexOf(item);

        $scope.selectedRemotes[index].invalid = false;

        if (esn !== '') {

            $rootScope.isGlobalLoading = true;
            $scope.selectedRemotes[index].invalid = true;

            if (!isEsnValid(esn) && !isSNValid(esn)) {
                alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_INVALID');
            } else if (isEsnAlreadyAdded(esn)) {
                alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_ALREADY_ENTERED');
            } else if (isAtMaxLimit()) {
                alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_MAX_LIMIT', {'MAX_LIMIT': $scope.maxLimit});
            } else {
                Wmoapi
                    .getRemoteInfo(esn)
                    .success(function (response) {
                        if (response.length === 0 || response === null) {
                            alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_INVALID');
                        } else {
                            $scope.responseData.deviceControls.push(response);
                            $scope.selectedRemotes[index] = response;
                        }
                        $rootScope.isGlobalLoading = false;
                    })
                    .error(function (error) {
                        alertBar.show('error', 'CONTROLS_SELECTION_ERRMSG_ESN_INVALID');
                    });
            }
        } else {
            $scope.selectedRemotes[index] = {};
        }
    }

    function removeSelectedRemote(remote) {

      alertBar.hide();

      var index = $scope.responseData.deviceControls.indexOf(remote);
      $scope.responseData.deviceControls.splice(index, 1);
      index = $scope.selectedRemotes.indexOf(remote);
      $scope.selectedRemotes[index] = {};
    }

    function submitForm() {
      // check for empty esn
      var remotes = $scope.selectedRemotes;
      var isEmpty = areAllEsnEmpty(remotes);
      $scope.responseData.telematics = [];
      $scope.userSelection.telematicId = '';
      RSUData.setSelectedRsu(null);

      if (isEmpty) {
        $rootScope.isGlobalLoading = true;

        Wmoapi.setRsuRemote(requestId, 0, 0).then(
          function() {
              $scope.userSelection.selectedRemote.id = 0;
              $rootScope.isGlobalLoading = false;
              $scope.loadTelematicsPartial()()(false);
          }, function(error) {
            $rootScope.isGlobalLoading = false;
            console.error('error in setRsuRemote', error);
            alertBar.show('error', 'Error setting remote');
          }
        );

      } else {
        // show errror
        alertBar.show('error', 'CONTROLS_ERROR_ESNBOX_NOTEMPTY');
      }
    }

    // helper functions

    function isEsnValid(param) {
      var regExp = new RegExp(/^[0-9A-Fa-f]{6}$/i);
      return regExp.test(param);
    }

    function isSNValid(param) {
      var regExp = new RegExp(/^T[0-9]{9}$/i);
      return regExp.test(param);
    }

    function isEsnAlreadyAdded(esn) {
        var myArray = $scope.responseData.deviceControls;
        var newEsn = esn.toUpperCase();
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].esn.toUpperCase() === newEsn) {
                return true;
            }
        }
        return false;
    }

    function isAtMaxLimit() {
      return $scope.responseData.deviceControls.length >= $scope.maxLimit;
    }

    function openDialog() {
        ngDialog.open({
            template: '<remotes-help />',
            className: 'ngdialog-theme-plain remotes',
            plain: true
        });
      }

    function transformSelectedRemotes(selectedRemotes) {
      var remotes = [{}, {}, {}, {}];

      angular.forEach(selectedRemotes, function(value, key) {
        remotes[key] = value;
      });

      return remotes;
    }

    function areAllEsnEmpty(remotes) {
      var isEmpty = true;

      angular.forEach(remotes, function (value, key) {
          if (!value.id) {
              if (value.esn && value.esn.trim() !== '') {
                  isEmpty = false;
              }
          }
      });

      return isEmpty;
    }
  }
]);
