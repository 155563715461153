'use strict';

angular.module('core').factory('klonIdentifier', ['Wmoapi',
    function (Wmoapi) {

        var klon = {};

        klon.getSuspendedRequest = function(serial, onComplete, onError){

            var suspendedRequest = {}, hasSuspendedRequest = false, result;

            Wmoapi
                .getSuspendedRequest(serial)
                .success(function (response) {

                    suspendedRequest = response;
                    hasSuspendedRequest = (suspendedRequest && suspendedRequest.action);

                    onComplete();
                })
                .catch(function(error){
                    onError(error);
                });

            result = {
                get suspendedRequest() {
                    return suspendedRequest;
                },
                get hasSuspendedRequest() {
                    return hasSuspendedRequest;
                }
            };

            return result;
        };

        klon.getMainPage = function(serial, onComplete, onError){

            var mainPage = [], result, hasMainPage = false;

            Wmoapi
                .getMainPage(serial)
                .success(function (response) {

                    mainPage = [response.page];
                    hasMainPage = ( response.page && mainPage.length > 0);

                    onComplete();
                })
                .catch(function(error){
                    onError(error);
                });

            result = {
                get mainPage() {
                    return mainPage;
                },
                get hasMainPage() {
                    return mainPage;
                }
            };

            return result;
        };

        klon.getRequiredPages = function(serial, mainPageContent, onComplete, onError){

            var requiredPages = [], hasRequiredPages = false, result;

            Wmoapi
                .getRequiredPages(serial, mainPageContent)
                .success(function (response) {

                    requiredPages = response;
                    hasRequiredPages = ( requiredPages && requiredPages.length > 0);

                    onComplete();
                })
                .catch(function(error){
                    onError(error);
                });

            result = {
                get requiredPages() {
                    return requiredPages;
                },
                get hasRequiredPages() {
                    return hasRequiredPages;
                }
            };

            return result;
        };

        klon.isDevicePrepared = function(serial, pageReadings, onComplete, onError){

            var waitTimeInSeconds = 0, cloningMethod = '', result;

            Wmoapi
                .isDevicePrepared(serial, pageReadings)
                .success(function (response) {

                    waitTimeInSeconds = response.waitTime;
                    cloningMethod = response.cloningMethod;

                    onComplete();
                })
                .catch(function(error){
                    onError(error);
                });

            result = {
                get waitTimeInSeconds() {
                    return waitTimeInSeconds;
                },
                get cloningMethod() {
                    return cloningMethod;
                }
            };

            return result;
        };

        klon.getLatestRequest = function(bootloader, serial, onComplete, onError){

            var latestRequest = {}, hasLatestRequest = false, result;

            Wmoapi
                .getLatestRequest(bootloader, serial)
                .success(function (response) {

                    latestRequest = response;
                    hasLatestRequest = (latestRequest && latestRequest.action);

                    onComplete();
                })
                .catch(function(error){
                    onError(error);
                });

            result = {
                get latestRequest() {
                    return latestRequest;
                },
                get hasLatestRequest() {
                    return hasLatestRequest;
                }
            };

            return result;
        };

        klon.createRequest = function(bootloader, serial, latestRequestResponse, onComplete, onError) {

            var requestId = '', hasRequestId = false, result;
            var flashRequestId = latestRequestResponse.action.request_id;
            var flashRequestType = latestRequestResponse.action.type;

            Wmoapi
                .createRequestKlon(bootloader, serial, flashRequestId, flashRequestType)
                .success(function (response) {

                    if(response) {
                        requestId = response.requestId;
                        hasRequestId = true;
                    }

                    onComplete();
                })
                .catch(function (error) {
                    onError(error);
                });

            result = {
                get requestId() {
                    return requestId;
                },
                get hasRequestId() {
                    return hasRequestId;
                }
            };

            return result;
        };

        klon.sendReadings = function(klonRequestId, pageReadings, onComplete, onError){

            var success = false, waitTimeInSeconds = 0, ready = false, result;

            Wmoapi
                .sendReadings(klonRequestId, pageReadings)
                .success(function (response) {

                    success = response.success;
                    waitTimeInSeconds = response.waitTime;
                    ready = response.ready;

                    onComplete();
                })
                .catch(function(error){
                    onError(error);
                });

            result = {
                get success() {
                    return success;
                },
                get waitTimeInSeconds() {
                    return waitTimeInSeconds;
                },
                get ready() {
                    return ready;
                }
            };

            return result;
        };

        klon.getStatusUpdate = function(klonRequestId, onComplete, onError){

            var waitTimeInSeconds = 0, ready = false, result;

            Wmoapi
                .getStatusUpdate(klonRequestId)
                .success(function (response) {

                    waitTimeInSeconds = response.waitTime;
                    ready = response.ready;

                    onComplete();
                })
                .catch(function(error){
                    onError(error);
                });

            result = {
                get waitTimeInSeconds() {
                    return waitTimeInSeconds;
                },
                get ready() {
                    return ready;
                }
            };

            return result;
        };


        return klon;
    }
]);
