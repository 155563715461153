/**
 * Created by rafikgharsalli on 2015-12-17.
 */
'use strict';
angular.module('core').directive('notificationBar', [
    function(){
        return {
            restrict:'AE',
            scope:{
                img:'@',
                title:'@',
                message:'@',
                button:'@',
                event:'&',
                hmtext : '@'
            },
            templateUrl:'modules/core/views/directives-partial/notification.bar.html',
            transclude: true,
            // controller: ['$scope', function($scope) {
            //     $scope.closeNotificationBar=function(){
            //         $scope.hideNotificationBar = true;
            //     };
            // }]
        };
    }
]);
