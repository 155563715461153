(function() {
    'use strict';

    angular
        .module('core')
        .directive('sidebar', Sidebar);

        function Sidebar() {
            return {
                restrict: 'E',
                scope: {
                    app: '=',
                    disableHeader: '=',
                    loadInternalRemotesPartial: '&',
                    loadConfigurationPartial: '&',
                    loadOemRemoteStartPartial: '&',
                    loadTelematicsPartial: '&',
                    loadInstallOptionsPartial: '&',
                    loadControlsPartial: '&',
                    loadFeaturePartial: '&',
                    loadFirmwarePartial: '&',
                    loadGuidesPartial: '&',
                    showWiringSection: '=',
                    sidebar: '=',
                    skipControls: '=',
                    toggle: '=',
                    usedAsAddOn: '=',
                    standaloneFlags:'='

        },
                templateUrl: 'modules/core/views/directives-partial/sidebar.html',
                controller: 'PartialsSidebarController',
                controllerAs: 'ctrl'
            };
        }
})();
