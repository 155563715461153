/**
 * Created by rafikgharsalli on 2015-07-21.
 */
'use strict';

angular.module('core').controller('PartialsGuidesGuidesController', ['$scope', 'Wmoapi','messageBox', '$rootScope', 'Wiringapi', 'dataService', 'AppService','pdfService',
    'alertBar', '$q', 'DeviceService', 'sidebarManager', 'guidesService', 'deviceIdentifier',

    function($scope,Wmoapi,messageBox,$rootScope,Wiringapi,dataService,AppService,pdfService, alertBar, $q, DeviceService, sidebarManager, guidesService, deviceIdentifier){

        $scope.pdfOpen = false;
        $scope.togglePdfOptions = togglePdfOptions;
        $scope.pdfOptions = 'all';
        $scope.switchAlternate = 'off';
        $scope.isGuidesMenuUnlocked = sidebarManager.getGuidesWiresSidebarStatus();
        $scope.showWiringErrorMsg = true;
        $scope.hasRsuGuides = false;
        $scope.guideCount = 0;

        var mainDevice = DeviceService.getFlashableDevice();
        var rsuDevice = DeviceService.getRsuDevice();
        var canModifyFeatures = deviceIdentifier.isConfigureModuleAllowed(mainDevice.allowedServices);
        var isDeviceAccessory = deviceIdentifier.isDeviceAccessory(mainDevice.deviceFunctionId);
        var isDeviceRfid = deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId);

        $scope.initGuides = function() {

            var device = DeviceService.getFlashableDevice();
            var skipWiring = $scope.$parent.skipToWiring;
            var skipGuides = $scope.$parent.skipToGuides;

            $scope.skipWiring = skipWiring;
            $scope.skipGuides = skipGuides;

            var vehicle_id = (skipWiring || skipGuides) ? getLastVehicleId() : $scope.$parent.selectedVehicle.vehicleId;
            var product_id = device.productID;
            $rootScope.isGlobalLoading = true;

            if($scope.$parent.showWiringSection){
                angular.element(document.querySelector('#body')).removeClass('standard');
                angular.element(document.querySelector('#body')).addClass('body-model');
            }

            $scope.getDeviceGuides();
            $scope.managePageNavigation();

            var menuItems = [
                {
                    name: 'All',
                    disabled: false
                },
                {
                    name: 'Guides',
                    disabled: false
                },
                {
                    name: 'Wiring',
                    disabled: false
                },
                {
                    name: 'Videos',
                    disabled: false
                }
            ];
            loadSubmenu(menuItems);
            if ($scope.showWiringSection || skipWiring) {
                $scope.initWires();
            }
            if (skipGuides) {
                $scope.goToPartial('Guides');
            }
            $scope.initVideos(vehicle_id,product_id);

        };

        $scope.initVideos = function(vehicle_id,product_id){

            var deferred = $q.defer();

            if (vehicle_id === $scope.responseData.videosGuides.vehicleId && product_id === $scope.responseData.videosGuides.productId) {
                $scope.videos = $scope.responseData.videosGuides.videos;
                $rootScope.isGlobalLoading = false;
                if ($scope.videos.length === 0) {
                    $scope.$parent.submenu.items[3].disabled = true;
                }
                deferred.resolve();
            } else {
                $scope.getDeviceVideos(vehicle_id, product_id);
            }

        };

        $scope.getDeviceVideos = function(vehicle_id,product_id){

            Wmoapi.
                getVideos(vehicle_id,product_id)
                .success(function(response){
                    $scope.videos = response;
                    $scope.responseData.videosGuides.videos = response;
                })
                .error(function(error){
                    console.error('error in getDeviceVideos:', error);
                    $scope.videos = [];
                }).finally(function(){
                    $scope.responseData.videosGuides.vehicleId = vehicle_id;
                    $scope.responseData.videosGuides.productId = product_id;
                    if ($scope.videos.length === 0) {
                        $scope.$parent.submenu.items[3].disabled = true;
                    }
            });

        };

        $scope.managePageNavigation = function() {
            var skipWiring = $scope.$parent.skipToWiring;
            var skipGuides = $scope.$parent.skipToGuides;

            $scope.hidePageNaviBackBtn = false;
            $scope.hidePageNaviNextBtn = skipGuides;

            $scope.backSkipStep = (skipWiring || skipGuides) ? 'device' : (!canModifyFeatures && (isDeviceAccessory || isDeviceRfid)) ? 'firmware' : 'features';

            $scope.demo_mode = AppService.isDemoMode();
            $scope.firmware_skipped = AppService.isFirmwareSkipped();

            if($scope.demo_mode && $scope.firmware_skipped){
                $scope.hidePageNaviNextBtn = true;
                $scope.backSkipStep = 'vehicle.makes';
            }
        };

        $scope.getDeviceGuides = function(){
            var title = 'FIRMWARE_CONFIGURE_MODULE';
            var description = 'FIRMWARE_MUST_CONFIGURE';
            var parentRequestId = $scope.$parent.request.id;
            var skipWiring = $scope.$parent.skipToWiring;
            var skipGuides = $scope.$parent.skipToGuides;
            var flashableDevice = DeviceService.getFlashableDevice();
            var productId = flashableDevice.productID;

            if( !skipWiring && !skipGuides && parentRequestId === '' || (!!skipWiring || !!skipGuides) && parentRequestId === undefined ){
                messageBox.show(title,description);
                $scope.$parent.loadDevicePartial();
            }else {
                if($scope.$parent.showWiringSection) {
                    $scope.getGuidesContent();
                }
                var ownerLink = '';

                if(guidesService.ownerGuidesHash[productId]){
                    ownerLink = guidesService.ownerGuidesHash[productId];
                }

                if (skipWiring || skipGuides) {
                    Wmoapi.getGuides(flashableDevice.serial).then(function (response) {
                        parseDeviceGuides(response.data.guideDownloadUrl, ownerLink, response.data.productGuideDownloadUrl, flashableDevice.productName);
                    }
                    );
                } else {
                    parseDeviceGuides($scope.$parent.responseData.setFirmware.installGuideUrl, ownerLink, flashableDevice.productGuideDownloadUrl, flashableDevice.productName);
                }
            }
        };

        function parseDeviceGuides (installLink, ownerLink, productLink, productName) {
            var guides = [], guideObj;

            if (rsuDevice) {
                $scope.hasRsuGuides = deviceIdentifier.hasRsuGuides(rsuDevice);
            }

            if(installLink && installLink !== '') {
                guideObj = guidesService.extractInstallGuideFromUrl(installLink);
                guides.push(
                    {
                        'id': '1',
                        'name': '',
                        'type': 'GUIDES_TYPE_INSTALLGUIDE',
                        'filename': guideObj.filename,
                        'version': guideObj.version,
                        'link': guideObj.url,
                        'isNewVersion': 0,
                        'language': guideObj.language
                    }
                );
            }
            if(ownerLink){
                guideObj = guidesService.extractOwnerGuideFromUrl(ownerLink);
                guides.push({
                    'id': '2',
                    'name': '',
                    'type': 'GUIDES_TYPE_OWNERMANUAL',
                    'filename': guideObj.filename,
                    'version': '',
                    'link': guideObj.url,
                    'isNewVersion': 0,
                    'language': ''
                });
            }
            if(productLink){
                guides.push({
                    'id': '3',
                    'name': '',
                    'type': 'GUIDES_TYPE_PRODUCTGUIDE',
                    'filename': productName,
                    'version': '',
                    'link': productLink,
                    'isNewVersion': 0,
                    'language': ''
                });
            }
            $scope.$parent.responseData.deviceGuides = guides;
            $scope.guideCount = guides.length;

            if ($scope.hasRsuGuides) {
                $scope.guideCount++;
            }
        }

        $scope.$on('PartialsGuides_OnClickNextToFlash', function () {
            $scope.goToFlash();
        });

        $scope.goToPartial = function(item) {
            $scope.$parent.submenu.selectedItem = item;
        };

        $scope.goToFlash = function(){
            var parentRequestId = $scope.$parent.request.id;
            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getFirmwarePosts(parentRequestId)
                .success(function(response){
                    $scope.responseData.firmwarePosts = response;
                })
                .error(function(error){
                    console.error('error in goToFlash:', error);
                    $scope.responseData.firmwarePosts = [];
                }).finally(function(){
                    $rootScope.isGlobalLoading = false;
                    $scope.$parent.skipstep('flash');
                });
        };

        $scope.getGuidesContent = function () {

            $scope.showGuidesContent = true;
        };

        $scope.getWiringContent = function () {

            $scope.showGuidesContent = false;
        };


        $scope.initWires = function(){
            $rootScope.isGlobalLoading = true;
            var skipWiring = $scope.$parent.skipToWiring;
            var skipGuides = $scope.$parent.skipToGuides;
            var vehicleBypassId = (skipWiring || skipGuides) ? getLastVehicleId() : $scope.$parent.selectedVehicle.vehicleId;

            if (skipWiring) $scope.selectedVehicle = copyValues($scope.$parent.lastVehicleFlashed, $scope.$parent.selectedVehicle, $scope.$parent.latestRequest);

            dataService.vehicleId = vehicleBypassId;
            var title = 'GUIDES_CONFIGURE_MODULE';
            var description = 'GUIDES_MUST_CONFIGURE';

            if( vehicleBypassId === '' || vehicleBypassId === undefined ){
                messageBox.show(title,description);
                $scope.$parent.loadDevicePartial();
            }else{
                dataService
                    .getWiresByVehicleBypassId(vehicleBypassId)
                    .then(function (response) {
                        $scope.hideWiringTable = false;
                        $scope.wireCount = response.wire_count;
                        $scope.imageCount = response.image_count;
                        $scope.showWiringErrorMsg = false;
                        $scope.wires = response.wires;
                        $scope.$parent.responseData.wiringApi.wireCount = response.wire_count;
                        $scope.$parent.responseData.wiringApi.imageCount = response.image_count;
                        $scope.$parent.responseData.wiringApi.wires = response.wires;
                    }, function (error) {
                        console.error('error in getWiresByVehicleBypassId:', error);
                        $scope.wires = [];
                        $scope.$parent.responseData.wiringApi.wires = [];
                        $scope.showWiringErrorMsg = true;
                        $scope.$parent.submenu.items[2].disabled = true;
                    })
                    .finally(function () {
                        $scope.$parent.responseData.wiringApi.vehicleId = vehicleBypassId;
                        $rootScope.isGlobalLoading = false;
                        $scope.showGuidesContent = false;
                        if(skipWiring) $scope.goToPartial('Wiring');
                    });
            }

        };

        $scope.showWiresImageSection = function ($index, wire) {

            $scope.data = dataService;
            dataService.image_url = '';
            dataService.showContent = false;
            dataService.ErrorMessage = true;
            dataService.isGalleryMode = false;

            if ($scope.activePosition !== $index) {
                var wireId = wire.wire_id;
                var connectorId = wire.connector_id;
                var componentId = wire.component_id;
                Wiringapi
                    .imageVehicleBypass(wireId)
                    .success(function (response) {
                        $rootScope.isGlobalLoading = true;
                        dataService.wireImage = '';
                        dataService.imageTags = '';
                        dataService.image_url = '';
                        dataService.image_thumbnail_url = $scope.responseData.wiringApiStatus.image_thumbnail_url;
                        dataService.isResized =  getWidth() >= 1500;
                        $scope.wireImages = response;
                        $scope.$parent.responseData.wiringApi.wireImages = response;
                        $scope.activePosition = $index;
                        $scope.showFirstWireImage(response, dataService.isResized, wireId, connectorId, componentId);
                    })
                    .error(function (error) {
                        console.error('error in showWiresImageSection:', error);
                        //TODO handle error
                    });

            } else {
                $scope.activePosition = -1;
                $rootScope.isGlobalLoading = false;
            }
        };
        $scope.showFirstWireImage = function(wires,isResized,wireId,connectorId,componentId){

            if(wires.length > 0) {
                var skipWiring = $scope.$parent.skipToWiring;
                var skipGuides = $scope.$parent.skipToGuides;
                var wire = wires[0];
                var imageId = wire.image_id;
                var fileName = wire.file_name;
                var vehicleId = (skipWiring || skipGuides) ? getLastVehicleId() : $scope.$parent.selectedVehicle.vehicleId;
                var activeTab = 0;
                dataService.wireImage = wire;
                dataService.imageTags = '';
                dataService.validateImage(vehicleId,isResized,fileName,imageId,wireId,connectorId,componentId,activeTab);
            }
        };

        $scope.showAllImages = function(){
            $scope.data = dataService;
            var skipWiring = $scope.$parent.skipToWiring;
            var skipGuides = $scope.$parent.skipToGuides;
            var vehicleId = (skipWiring || skipGuides) ? getLastVehicleId() : $scope.$parent.selectedVehicle.vehicleId;
            var vehicleIdForGallery = $scope.$parent.responseData.wiringApi.vehicleIdForGallery;
            $scope.hideWiringTable = true;
            dataService.showContent = false;
            dataService.ErrorMessage = true;

            dataService.imageTags = '';
            if( vehicleId === vehicleIdForGallery ) {
                $scope.showErrorMsg = false;
                dataService.isGalleryMode = true;
                dataService.image_thumbnail_url = $scope.responseData.wiringApiStatus.image_thumbnail_url;
                dataService.isResized =  getWidth() >= 1500;
                $scope.images = $scope.$parent.responseData.wiringApi.images;
                $scope.showFirstWireImage($scope.images,dataService.isResized,0,0);
            } else {
                $scope.getImagesByVehicleId(vehicleId);
            }

            $scope.pdfOpen = false;
        };


        $scope.getImagesByVehicleId = function(vehicleId){
            var skipWiring = $scope.$parent.skipToWiring;
            var skipGuides = $scope.$parent.skipToGuides;

            if (skipWiring || skipGuides) vehicleId = getLastVehicleId();

            Wiringapi
                .getImagesVehicleBypass(vehicleId)
                .success(function (response) {
                    dataService.isGalleryMode = true;
                    $rootScope.isGlobalLoading = false;
                    dataService.image_thumbnail_url = $scope.responseData.wiringApiStatus.image_thumbnail_url;
                    dataService.isResized = getWidth() >= 1500;
                    $scope.images = response;
                    $scope.$parent.responseData.wiringApi.images = response;
                    $scope.$parent.responseData.wiringApi.vehicleIdForGallery = vehicleId;
                    $scope.showFirstWireImage($scope.images,dataService.isResized,0,0);
                })
                .error(function () {
                    $scope.images = '';
                    $scope.showErrorMsg = true;
                    $rootScope.isGlobalLoading = false;
                });
        };

        $scope.quitGalleryMode = function (){
            $scope.activePosition = -1;
            $scope.hideWiringTable = false;
            dataService.isGalleryMode = false;
            $scope.pdfOpen = false;
        };


        function copyValues(vehicleObject, toCopy, latestRequest) {
            toCopy.makeName = vehicleObject.vehicleMake;
            toCopy.makeId = vehicleObject.vehicleMakeId;
            toCopy.year = vehicleObject.vehicleYear;
            toCopy.yearName = vehicleObject.vehicleYear;
            toCopy.modelId = vehicleObject.vehicleModelId;
            toCopy.modelName = vehicleObject.vehicleModel;
            toCopy.modelImage = vehicleObject.vehicleModel;
            toCopy.vehicleId = vehicleObject.vehicleId;
            toCopy.vehicleName = vehicleObject.vehicleProperties;
            toCopy.modelImage = latestRequest.image;

            return toCopy;
        }

        function generatePdf (onlyWires, onlyImages) {
            $rootScope.isGlobalLoading = true;
            alertBar.hide();
            var skipWiring = $scope.$parent.skipToWiring;
            var skipGuides = $scope.$parent.skipToGuides;

            var pageCount = 0;
            var skipSave = false;
            var skipWires = false;
            var jsonData = $scope.$parent.responseData.wiringApi.wires;
            var wireCount = $scope.$parent.responseData.wiringApi.wireCount;
            var imageCount = $scope.$parent.responseData.wiringApi.imageCount;
            var selectedVehicle = skipWiring || skipGuides ? copyValues($scope.$parent.lastVehicleFlashed, $scope.$parent.selectedVehicle, $scope.$parent.latestRequest) : $scope.$parent.selectedVehicle;
            var vehicleId = skipWiring || skipGuides ? getLastVehicleId() : selectedVehicle.vehicleId;

            Wiringapi
                .getImagesTagsVehicleBypass(vehicleId)
                .success(function (response) {
                    $scope.$parent.responseData.wiringApi.imagesTags = response;
                    try{
                        pdfService.init();
                        if(onlyWires && !onlyImages) {
                            skipSave = true;
                            pageCount =  Math.ceil(jsonData.length / 11);
                            pdfService.printWires(jsonData, selectedVehicle, wireCount, imageCount,skipSave,pageCount);
                        }else if (!onlyWires && onlyImages && response.length > 0){
                            skipWires = true;
                            pageCount = getPageCount(response) ;
                            pdfService.printWireImages(response, selectedVehicle, pageCount, skipWires);
                        } else {
                            pageCount = Math.ceil(jsonData.length / 11) + getPageCount(response) ;
                            pdfService.printWires(jsonData, selectedVehicle, wireCount, imageCount,skipSave,pageCount);
                            pdfService.printWireImages(response, selectedVehicle, pageCount, skipWires);
                        }
                        $rootScope.isGlobalLoading = false;

                    } catch(e) {
                        $rootScope.isGlobalLoading = false;
                        alertBar.show( 'error','GUIDES_WIRING_PDF_ERROR_GENERATING');
                    }
                })
                .error(function () {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show( 'error','GUIDES_WIRING_PDF_ERROR_IMAGES_TAGS_REQUEST');
                });


        }


        function togglePdfOptions() {
            $scope.pdfOpen = !$scope.pdfOpen;
        }

        function loadSubmenu(submenuItems) {
            $scope.$parent.submenu.items = [];
            for (var i = 0; i < submenuItems.length; i++) {
                $scope.$parent.submenu.items.push(submenuItems[i]);
            }
        }


        $scope.printPdfDocument = function (pdfOption){

            switch (pdfOption) {

                case 'all' :
                    generatePdf(false,false);
                    break;

                case 'wires' :
                    generatePdf(true,false);
                    break;
                case 'images' :
                    generatePdf(false,true);
                    break;
                default :
                    generatePdf(false,false);
                    break;
            }

        };

        function getPageCount (images) {

            var pageCount =+  Math.ceil(images.length / 2);
            for (var i=0 ; i < images.length; i++) {

                if (images[i].tags.tags.length > 7 ) {
                    pageCount ++;
                }

            }

            return pageCount ;
        }

        function getWidth () {
            return window.innerWidth;
        }

        function getLastVehicleId() {
            var noFirmwareInstalled = deviceIdentifier.hasFirmwareUpdate(mainDevice).hasBlankFirmware;
            var lastVehicle = ($scope.$parent.lastVehicleFlashed && !noFirmwareInstalled) ? $scope.$parent.lastVehicleFlashed : null;
            return lastVehicle ? (lastVehicle.vehicleId ? lastVehicle.vehicleId : 0) : 0;
        }

        $scope.clickRsuGuide = function () {
            guidesService.getRsuGuide($scope.$parent.request.id, mainDevice, rsuDevice);
        };
    }
]);
